import { ISurveyDataType } from 'store/type'

/**
 * Try to convert a passed survey response value to a string array, and return
 * the string array.
 *
 * @param val - A specific survey response value.
 *
 * @returns A string array of the converted survey response value.
 *
 * An empty string array will be returned if the survey response value cannot
 * be converted to a string array.
 */
export const surveyResponseValueToArray = (val: ISurveyDataType): string[] => {
  let result: string[] = []
  if (!val) return result

  if (Array.isArray(val)) {
    result = val.map((v) => v.toString())
  } else if (typeof val === 'string') {
    result = [val]
    if (val.includes('[', 0)) {
      try {
        const jsonObj = JSON.parse(val)
        if (Array.isArray(jsonObj)) {
          result = jsonObj.map((v) => v.toString())
        }
      } catch (e) {
        result = [val]
      }
    }
  }

  return result
}
