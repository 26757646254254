export class ServiceWorkerRegistrationError extends Error {
  constructor(message: string) {
    super(message)
    this.name = this.constructor.name
  }
}

export enum ServiceWorkerUnregisterStatus {
  SUCCESS = 'success',
  FAILED = 'failed',
  NO_REGISTRATION = 'no-registration'
}
