import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconFlagLightning: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="82"
    height="80"
    viewBox="0 0 82 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 14.5732C13.913 13.5436 16 10.7655 16 7.5C16 3.35786 12.6421 0 8.5 0C4.35786 0 1 3.35786 1 7.5C1 10.7655 3.08702 13.5436 6 14.5732V79H11V14.5732Z"
      fill="#5D779E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2008 18.6828C15.9933 15.5672 22.0533 10 33.8163 10C42.3025 10 46.3624 12.2253 49.9821 14.2094C52.8938 15.8054 55.5207 17.2453 59.9378 17.2453C65.1402 17.2453 68.6043 15.2478 71.8948 13.3505C74.3884 11.9127 76.7822 10.5323 79.7573 10.1224C80.743 9.98654 81.5556 10.811 81.5556 11.8113V48.9434C81.5556 49.9438 80.743 50.7413 79.7573 50.8771C76.7822 51.287 74.3884 52.6674 71.8948 54.1052C68.6043 56.0025 65.1402 58 59.9378 58C55.5207 58 52.8938 56.5601 49.9821 54.9641C46.3624 52.98 42.3025 50.7547 33.8163 50.7547C30.278 50.7547 23.1813 51.7609 17.8555 55.3545C16.4312 56.3155 14 55.3512 14 53.6265V19.4842C14 19.203 14.0608 18.9262 14.2008 18.6828ZM42.1955 18.5068L38.0192 35.9221C37.9184 36.3428 38.2268 36.7494 38.6466 36.7494H46.0442C46.4456 36.7494 46.7499 37.1232 46.6808 37.5313L44.703 49.2156C44.5801 49.9415 45.5202 50.3067 45.8916 49.6775L57.9046 29.3263C58.167 28.8818 57.857 28.3122 57.3527 28.3122H49.5313C49.0836 28.3122 48.7715 27.8539 48.9231 27.4192L51.8971 18.893C52.0487 18.4583 51.7366 18 51.289 18H42.8229C42.5257 18 42.2669 18.2091 42.1955 18.5068Z"
      fill="#5D779E"
    />
  </svg>
)
