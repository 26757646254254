import React, { useContext, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Dispatch } from 'redux'
import { matchCondition } from 'shared/utils/matchCondition/matchCondition'
import { updateSurveyAnswer, updateUserJourney } from 'store/reducer'
import { SurveyState } from 'store/type'
import { UsingStatusScreenPropsModel } from './UsingStatusScreen.model'
import { AudioContext } from 'context/AudioContext'

export const UsingStatusScreenComponent: React.FC<
  UsingStatusScreenPropsModel
> = (props) => {
  const dispatch: Dispatch<any> = useDispatch()
  const navigate = useNavigate()

  const { isMatchCondition, getNextScreenId } = matchCondition()

  const {
    name,
    screenData: { options },
    footerProps,
    states
  } = props
  const { playAudio } = useContext(AudioContext)

  useEffect(() => {
    dispatch(updateUserJourney(-1))
    const value: string[] = []
    if (states) {
      options.forEach((option) => {
        if (option.conditions && option.conditions.length > 0) {
          const result = option.conditions.some((condition) =>
            isMatchCondition(condition, states[condition.dependingScreen])
          )
          if (result) {
            value.push(option.value || option.label)
          }
        }
      })
    }
    dispatch(
      updateSurveyAnswer({
        [name]: value.length == 0 ? null : value
      })
    )

    const screenId = getNextScreenId(
      value,
      footerProps.conditions,
      footerProps.nextScreenId
    )

    const nextScreenId = screenId || footerProps.nextScreenId
    navigate(`/survey/${nextScreenId}`, { replace: true })
    // Update screen path
    dispatch(updateUserJourney(Number(nextScreenId)))

    playAudio?.(nextScreenId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <></>
}

const mapStateToProps = (state: SurveyState) => ({
  states: state.surveyData
})

export const UsingStatusScreen = connect(mapStateToProps)(
  UsingStatusScreenComponent
)
