import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from './LoadingAssetsScreen.module.scss'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CacheType } from 'service-worker.model'
import { browserSupportsServiceWorker } from 'shared/utils/serviceWorker/utils'
import type { SurveyState } from 'store/type'
import type { LoadingAssetsScreenComponentProps } from './LoadingAssetsScreen.model'
import { CachingProgressBar } from 'components/CachingProgressBar/CachingProgressBar.component'
import { InitialisationScreen } from 'components/InitialisationScreen/InitialisationScreen.component'
import pAll from 'p-all'
import pMap, { pMapSkip } from 'p-map'
import { Modal } from 'components/Modals/Modal/Modal.component'
import { Button } from 'components/Button/Button.component'
import { colors } from 'shared/theme/theme'
import { assertNonNull } from 'shared/types/guards'
import { CACHING_TIMEOUT_MS } from 'shared/constants/Constants.d'

/**
 * The screen component displayed after the user has logged in to the app, showing the
 * progress of caching all media assets required by the app.
 *
 * @param props - The props this component takes.
 *
 * @returns A React component displaying a screen with the progress of caching media
 * assets for the app.
 */
const LoadingAssetsScreenComponent: React.FC<
  LoadingAssetsScreenComponentProps
> = (props) => {
  const { assetUrls } = props

  const navigate = useNavigate()

  const cachingStarted = useRef<boolean>(false)

  const [cachingErrorOccurred, setCachingErrorOccurred] =
    useState<boolean>(false)
  const [cachingTimedOut, setCachingTimedOut] = useState<boolean>(false)
  const [cachedAudioFileCount, setCachedAudioFileCount] = useState<number>(0)
  const [cachedImageFileCount, setCachedImageFileCount] = useState<number>(0)

  const cachingCompleted = useCallback(
    (currentCount: number, mediaType: CacheType): boolean => {
      assertNonNull(assetUrls, 'assetUrls')
      return currentCount / assetUrls[mediaType].length === 1
    },
    [assetUrls]
  )

  /**
   * Cache assets of a specified media type from a provided set of URLs, and increment a
   * count of successfully cached files represented in a React state variable.
   *
   * This function will attempt to fetch each asset from the provided array in
   * `urlsToCache` from the survey data API server to store in the browser's cache,
   * falling back to checking that the asset has already been cached if the fetch fails
   * for any reason. If the asset was not already cached, and the fetch has failed, this
   * function will attempt to retry fetching the asset from the URL at a later time. If
   * the wait time exceeds the timeout length specified, a modal will be displayed on this
   * page.
   *
   * @param cacheName - The name of the cache in the browser's {@link CacheStorage}
   * interface to store the fetched media assets.
   * @param urlsToCache - The URLs of media assets to cache.
   * @param setCachedFileCount - A React `setState` callback function to set a state
   * variable tracking the current number of successfully cached files. This will be
   * called to increment this state variable count for each successfully cached media
   * asset.
   * @param stopSignal - An {@link AbortSignal} used to terminate caching assets when a
   * timeout has been reached.
   */
  const cacheAssets = useCallback(
    async (
      cacheName: CacheType,
      urlsToCache: readonly string[],
      setCachedFileCount: React.Dispatch<React.SetStateAction<number>>,
      stopSignal: AbortSignal
    ) => {
      const cache = await caches.open(cacheName)

      const urlMapperFunction = async (
        url: string
      ): Promise<string | typeof pMapSkip> => {
        let fileSuccessfullyStoredInCache = true
        try {
          await cache.add(url)
        } catch (_) {
          // Error in HTTP response
          // Check if the file is already located in the cache
          fileSuccessfullyStoredInCache = (await cache.match(url)) != null
        }

        if (!fileSuccessfullyStoredInCache) {
          // Fatal error - file not in cache, and cannot be retrieved
          // Since this mapper function is designed to be used in `pMap` to return an array of
          // unsuccessfully cached URLs, return this URL
          return url
        }

        // Otherwise, file is successfully cached
        // Increment count of cached files
        setCachedFileCount((count) => count + 1)

        // If this URL was successfully cached, no need to return it, as we only care about
        // URLs which were unsuccessfully cached
        // Hence, skip this URL when using pMap
        return pMapSkip
      }

      let remainingUrlsToCache = [...urlsToCache]

      while (remainingUrlsToCache.length > 0) {
        const unsuccessfulUrls = await pMap(
          remainingUrlsToCache,
          urlMapperFunction,
          {
            concurrency: 150,
            signal: stopSignal
          }
        )

        if (unsuccessfulUrls.length > 0 && !cachingErrorOccurred) {
          // A caching result was unsuccessful, AND an alert was not previously triggered
          // - set alert
          setCachingErrorOccurred(true)
        }

        // Store results of next URLs to cache
        remainingUrlsToCache = unsuccessfulUrls
      }

      // Whilst this loop is infinite here, the `beginAssetCaching` function, which calls
      // this function, will stop the execution of this function if a timeout occurs
    },
    [cachingErrorOccurred]
  )

  /**
   * Begin caching audio and image assets in the app.
   */
  const beginAssetCaching = useCallback(async () => {
    assertNonNull(assetUrls, 'assetUrls')

    // Create an `AbortController` with a timeout to stop if things take too long
    const timeoutController = new AbortController()
    const timeout = setTimeout(
      () => timeoutController.abort(),
      CACHING_TIMEOUT_MS
    )

    const cacheCallbacks = [
      async () =>
        cacheAssets(
          CacheType.AUDIO,
          assetUrls.audio,
          setCachedAudioFileCount,
          timeoutController.signal
        ),
      async () =>
        cacheAssets(
          CacheType.IMAGES,
          assetUrls.images,
          setCachedImageFileCount,
          timeoutController.signal
        )
    ]

    // Await everything, with the given timeout to stop if things get hairy
    try {
      await pAll(cacheCallbacks, {
        signal: timeoutController.signal
      })
    } catch (_) {
      // Timeout has occurred, and assets couldn't be cached in time
      // There is an unlikely edge case where the user's computer is slow to match
      // assets in the cache if the user is offline, and causes a timeout, however, in
      // practice the default timeout (10 minutes) should be plenty of time to match
      // assets in the cache on a variety of devices, even if offline
      setCachingTimedOut(true)
    }

    // Just in case caching didn't timeout, clear the timeout function to prevent memory
    // leaks
    clearTimeout(timeout)
  }, [assetUrls, cacheAssets])

  const restartAssetCaching = () => {
    setCachingTimedOut(false)
    setCachedAudioFileCount(0)
    setCachedImageFileCount(0)
    beginAssetCaching()
  }

  useEffect(() => {
    if (assetUrls == null) {
      // Has not been set yet by Redux - wait until it is set
      // Helps to avoid race condition
      return
    }

    if (cachingStarted.current) {
      // Caching has already started - no need to initiate it again
      return
    }

    if (!browserSupportsServiceWorker()) {
      // If the browser doesn't support service workers, this screen doesn't need to be
      // displayed, as assets cannot be cached
      navigate('/ra-dashboard/select-project')
      return
    }

    // Otherwise, the browser supports service workers, and we want to display this
    // component

    // Upon load/mount of this component, we want to begin the caching process (locally)
    beginAssetCaching()

    // Say that we are going to start caching
    cachingStarted.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetUrls])

  useEffect(() => {
    if (!cachingStarted.current) {
      // Not interested, as caching has not yet started
      return
    }

    if (
      !(
        cachingCompleted(cachedAudioFileCount, CacheType.AUDIO) &&
        cachingCompleted(cachedImageFileCount, CacheType.IMAGES)
      )
    ) {
      // Not interested, caching not complete
      return
    }

    // Otherwise, caching complete

    // Navigate to select project screen
    navigate('/ra-dashboard/select-project')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cachedAudioFileCount, cachedImageFileCount, cachingCompleted])

  return (
    <InitialisationScreen
      mainWrapper={{
        title: 'Loading assets'
      }}
      card={{ title: 'Welcome!' }}
      {...(cachingErrorOccurred && {
        alert: {
          text:
            'Your Internet connection appears to be unstable. If you have issues ' +
            'while caching assets, please make sure to reconnect to a stable Internet ' +
            'connection.',
          additionalStylingClasses: styles['loading-assets-screen-alert']
        }
      })}
    >
      <Modal
        className={styles['loading-assets-screen-modal']}
        open={cachingTimedOut}
        buttons={
          <Button variation="primary" onClick={restartAssetCaching}>
            Try Again
          </Button>
        }
        // Not a fan of inline styles, but doing this was the easiest to change the
        // background colour of the modal without it leaking outside the border of the
        // modal
        style={{
          backgroundColor: colors.clamShell
        }}
      >
        <h2 className={styles['loading-assets-screen-modal-heading']}>Error</h2>
        <p>Drug App has exceeded the maximum loading time threshold.</p>
        <p>Please check your Internet connection and try again later.</p>
      </Modal>

      <h2>Loading assets...</h2>

      <CachingProgressBar
        fileType="audio"
        currentAmount={cachedAudioFileCount}
        maxAmount={assetUrls?.audio.length ?? 0}
      />
      <CachingProgressBar
        fileType="image"
        currentAmount={cachedImageFileCount}
        maxAmount={assetUrls?.images.length ?? 0}
      />
    </InitialisationScreen>
  )
}

const mapStateToProps = (
  state: SurveyState
): LoadingAssetsScreenComponentProps => {
  let assetUrls
  if (state.isDemoMode) {
    assetUrls = state.demoAssetUrls
  } else {
    assetUrls = state.assetUrls
  }

  return {
    assetUrls: assetUrls ?? null
  }
}

/**
 * The screen component displayed after the user has logged in to the app, showing the
 * progress of caching all media assets required by the app.
 *
 * @returns A React component displaying a screen with the progress of caching media
 * assets for the app.
 */
export const LoadingAssetsScreen = connect(mapStateToProps)(
  LoadingAssetsScreenComponent
)
