import React, { useEffect, useState } from 'react'
import { DrinkingCircleSliderPropsModel } from './DrinkingCircleSlider.model'
import MuiSlider, { SliderThumb } from '@mui/material/Slider'
import styles from './DrinkingCircleSlider.module.scss'
import { styled } from '@mui/material/styles'
import { Icon } from 'components/Icons/Icon.component'
import { colors } from 'shared/theme/theme'
import classnames from 'classnames'
import { IconType } from 'components/Icons/Icon.model'
import { SliderCustomLabel } from 'components/Slider/Slider.model'
import { imageFallback } from 'hardcoded/drinkingcircleslider'
import { isNumber } from '@mui/x-data-grid/internals'
import { useColourPicker } from 'context/ColourContext'

const labels: SliderCustomLabel[] = new Array(10).fill(0).map((_c, index) => {
  const value = index + 1
  if (index === 0) {
    return {
      label: 'Just me',
      value
    }
  } else if (index === 1) {
    return {
      label: '1 other person',
      value
    }
  } else {
    return {
      label: `${index}${index === 9 ? '+' : ''} other people`,
      value
    }
  }
})

const CustomSlider = ({ customColor = colors.black, ...props }) => (
  <MuiSlider {...props} />
)

const IconSlider = styled(CustomSlider)(({ customColor = colors.black }) => ({
  color: customColor,
  height: 10,
  '& .MuiSlider-valueLabel': {
    backgroundColor: customColor
  }
}))

/**
 * Generate slider thumb component
 * @param thumbProps Html attributes for slider thumb
 * @returns {JSX.Element} Slider thumb component
 */
const SliderThumbComponent =
  /*(color = colors.black) =>*/
  (thumbProps: React.HTMLAttributes<HTMLElement>) => {
    const { children, ...other } = thumbProps
    return (
      /*<div className={styles.sliderThumb}>*/
      <SliderThumb {...other}>
        {children}
        <Icon
          className={`${styles.sliderThumbIcon} ${styles.iconHoverEffect}`}
          icon={IconType.Hand}
          size={47}
          stroke={colors.white}
          //fill={color}
        />
      </SliderThumb>
      /*</div>*/
    )
  }

function FadeImage({
  images,
  labels,
  currentValue
}: {
  images: string[]
  labels: SliderCustomLabel[]
  currentValue: number
}) {
  const [opacity, setOpacity] = useState(0.25)

  useEffect(() => {
    setOpacity(0.25)
    const timeoutId = setTimeout(() => setOpacity(1), 200)

    return () => clearTimeout(timeoutId)
  }, [currentValue, images])

  return (
    <img
      className={styles.image}
      style={{ opacity }}
      src={images[currentValue > 0 ? currentValue - 1 : 0]}
      role="presentation"
      alt={`${labels[currentValue > 0 ? currentValue - 1 : 0].label}-picture`}
    />
  )
}

export const DrinkingCircleSlider: React.FC<DrinkingCircleSliderPropsModel> = (
  props
) => {
  const {
    className,
    title,
    subtitle,
    images = imageFallback,
    min = 1,
    max = 10,
    defaultValue,
    setValue,
    ...componentProps
  } = props

  const { secondaryColour } = useColourPicker()

  const [currentValue, setCurrentValue] = useState<number>(
    isNumber(defaultValue) ? defaultValue : min
  )

  /**
   * Increment the value of the slider
   * @param _event
   * @param value
   */
  const handleValueChange = (
    _event: Event | React.SyntheticEvent<Element, Event>,
    value: number | number[]
  ) => {
    setValue(value as number)
    setCurrentValue(value as number)
  }

  return (
    <div
      className={classnames(styles.drinkingcircleslider, className)}
      {...componentProps}
    >
      {title && (
        <h1
          className="screen-titles--title"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}
      {subtitle && (
        <p
          className="screen-titles--subtitle"
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      )}
      <div className={styles.sliderAlign}>
        <div className={styles['drinkingcircleslider-images']}>
          <FadeImage
            images={images}
            labels={labels}
            currentValue={currentValue}
          />
        </div>

        <div
          className={classnames(styles['drinkingcircleslider-innerWrapper'])}
        >
          <IconSlider
            customColor={secondaryColour}
            sx={{
              '& .MuiSlider-thumb': {
                'svg': {
                  'path': {
                    fill: secondaryColour
                  }
                }
              }
            }}
            className={classnames(styles.slider)}
            //components={{ Thumb: SliderThumbComponent(color) }}
            components={{ Thumb: SliderThumbComponent }}
            valueLabelFormat={
              labels[currentValue > 0 ? currentValue - 1 : 0].label
            }
            valueLabelDisplay="on"
            defaultValue={isNumber(defaultValue) ? defaultValue : 0}
            min={min}
            max={max}
            value={currentValue}
            onChange={handleValueChange}
          />
        </div>
      </div>
    </div>
  )
}
