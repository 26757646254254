import type {
  GrogBrandModel,
  GrogContainerModel,
  GrogProductModel,
  GrogSubTypeModel,
  GrogTypeCategoryModel
} from 'api/client.model'
import type { DeepReadonly } from 'shared/types/general'
import { typeCategoryHasSubTypes } from 'shared/types/guards'
import { grogTypeHasBrands } from '../grogConsumptionState/utils'

/**
 * Find an alcohol type/category from the Grog Shop list of types/categories based on a
 * provided ID, if it is defined when provided.
 *
 * @param typesCategories - THe list of types/categories defined in the Grog Shop.
 * @param id - An ID mapping to the `id` prop of the {@link GrogTypeCategoryModel} type,
 * if defined.
 *
 * @returns - If `id` is defined, an alcohol type/category from the Grog Shop which
 * matches its provided value. Otherwise, if none was found, or if `id` is `undefined`,
 * return `undefined`.
 */
export const findTypeCategoryById = (
  typesCategories: DeepReadonly<(GrogTypeCategoryModel | GrogSubTypeModel)[]>,
  id: number | undefined
):
  | DeepReadonly<GrogTypeCategoryModel>
  | DeepReadonly<GrogSubTypeModel>
  | undefined => {
  if (id == null) {
    // Fail fast
    return undefined
  }

  return typesCategories.find((tc) => tc.id === id)
}

/**
 * Find an alcohol subtype from a parent alcohol type/category based on a provided ID,
 * if it is defined when provided.
 *
 * Note that the provided type/category may match the {@link GrogSubTypeModel}
 * interface, in which case, no subtype will be found.
 *
 * @param typeCategory - An alcohol type/category which is the parent to search for a
 * subtype.
 * @param id - An ID mapping to the `id` prop of the {@link GrogSubTypeModel} type, if
 * defined.
 *
 * @returns If `id` is defined, and `typeCategory` matches the
 * {@link GrogTypeCategoryModel} interface, an alcohol subtype from `typeCategory` which
 * matches the provided value of `id`. Otherwise, if none was found, `typeCategory`
 * matches the {@link GrogSubTypeModel} interface, or if `id` is `undefined`, return
 * `undefined`.
 */
export const findSubTypeById = (
  typeCategory:
    | DeepReadonly<GrogTypeCategoryModel>
    | DeepReadonly<GrogSubTypeModel>
    | undefined,
  id: number | undefined
): DeepReadonly<GrogSubTypeModel> | undefined => {
  if (!typeCategoryHasSubTypes(typeCategory) || id == null) {
    // Fail fast
    return undefined
  }

  return typeCategory.subTypes!.find((st) => st.id === id)
}

/**
 * Find an alcohol brand from a parent alcohol type/category OR subtype based on a
 * provided name, if it is defined when provided.
 *
 * If the provided type/category matches the {@link GrogSubTypeModel} interface, the
 * brand will be sought from this, otherwise, the brand will be sought from any brands
 * defined in the provided `subType`.
 *
 * @param typeCategory - An alcohol type/category. If this type/category matches the
 * {@link GrogSubTypeModel} interface, the brand will be searched for in this model.
 * @param subType - An alcohol subtype of `typeCategory`. If `typeCategory` matches the
 * {@link GrogTypeCategoryModel} interface, the brand will be searched for in this
 * model.
 * @param name - An name mapping to the `name` prop of the {@link GrogBrandModel} type,
 * if defined.
 *
 * @returns If `name` is defined, and `typeCategory` or `subType` are defined, the
 * brand will be searched for from one of the parameters based on the rules defined
 * above. Otherwise, if none was found, or if `name` is `undefined`, return `undefined`.
 */
export const findBrandByName = (
  typeCategory:
    | DeepReadonly<GrogTypeCategoryModel>
    | DeepReadonly<GrogSubTypeModel>
    | undefined,
  subType: DeepReadonly<GrogSubTypeModel> | undefined,
  name: string | undefined
): DeepReadonly<GrogBrandModel> | undefined => {
  if (name == null) {
    // Fail fast
    return undefined
  }

  let brandsArray
  if (typeCategoryHasSubTypes(typeCategory)) {
    // Brands array MAY be found from subtype
    brandsArray = subType?.brands
  } else {
    // Brands array MAY be found from top-level type category (as it matches the
    // `GrogSubTypeModel` interface)
    brandsArray = typeCategory?.brands
  }

  return brandsArray?.find((b) => b.name === name)
}

/**
 * Find an alcohol product from a {@link GrogBrandModel} instance from the Grog Shop
 * based on a provided name, if it is defined when provided.
 *
 * @param brand - An alcohol brand which is the parent to search for a product.
 * @param name - An name mapping to the `name` prop of the {@link GrogBrandModel} type,
 * if defined.
 *
 * @returns - If `name` is defined, an alcohol product from the Grog Shop which matches
 * its provided value. Otherwise, if none was found, or if `name` or `brand` is
 * `undefined`, return `undefined`.
 */
export const findProductByName = (
  brand: DeepReadonly<GrogBrandModel> | undefined,
  name: string | undefined
): DeepReadonly<GrogProductModel> | undefined => {
  if (name == null) {
    // Fail fast
    return undefined
  }

  return brand?.products.find((p) => p.name === name)
}

/**
 * Find an alcohol container from a parent alcohol type/category OR subtype based on a
 * provided name, if it is defined when provided.
 *
 * If the provided type/category matches the {@link GrogSubTypeModel} interface, the
 * container will be sought from this, otherwise, the container will be sought from any
 * containers defined in the provided `subType`.
 *
 * @param typeCategory - An alcohol type/category. If this type/category matches the
 * {@link GrogSubTypeModel} interface, the container will be searched for in this model.
 * @param subType - An alcohol subtype of `typeCategory`. If `typeCategory` matches the
 * {@link GrogTypeCategoryModel} interface, the container will be searched for in this
 * model.
 * @param name - An name mapping to the `name` prop of the {@link GrogContainerModel}
 * type, if defined.
 *
 * @returns If `name` is defined, and `typeCategory` or `subType` are defined, the
 * container will be searched for from one of the parameters based on the rules defined
 * above. Otherwise, if none was found, or if `name` is `undefined`, return `undefined`.
 */
export const findContainerByName = (
  typeCategory:
    | DeepReadonly<GrogTypeCategoryModel>
    | DeepReadonly<GrogSubTypeModel>
    | undefined,
  subType: DeepReadonly<GrogSubTypeModel> | undefined,
  name: string | undefined
): DeepReadonly<GrogContainerModel> | undefined => {
  if (name == null) {
    // Fail fast
    return undefined
  }

  let containersArray
  if (typeCategoryHasSubTypes(typeCategory)) {
    // Brands array MAY be found from subtype (if set)
    containersArray = subType?.containers
  } else {
    // Containers array MAY be found from top-level type category (as it matches the
    // `GrogSubTypeModel` interface)
    containersArray = typeCategory?.containers
  }

  return containersArray?.find(
    (c) => c.name === name || c.localDisplayName === name
  )
}

/**
 * Find all subtypes of alcohol from a given list of alcohol types/categories.
 *
 * @param typesCategories - A list of alcohol types/categories where all subtypes of
 * alcohol will be extracted from.
 *
 * @returns An array of all alcohol subtypes extracted from the provided list of alcohol
 * types/categories.
 */
export const findAllSubTypes = (
  typesCategories: DeepReadonly<(GrogTypeCategoryModel | GrogSubTypeModel)[]>
): DeepReadonly<GrogSubTypeModel[]> => {
  const subTypes = []

  for (const typeCat of typesCategories) {
    if (!typeCategoryHasSubTypes(typeCat)) {
      // The object is itself a subtype
      subTypes.push(typeCat)
      continue
    }

    // Otherwise, add all subtypes
    subTypes.push(...typeCat.subTypes!)
  }

  return subTypes
}

/**
 * Find all brands of alcohol from a given list of alcohol subtypes.
 *
 * @param typesCategories - A list of alcohol subtypes where all brands of alcohol will be
 * extracted from.
 *
 * @returns An array of all alcohol brands extracted from the provided list of alcohol
 * subtypes.
 */
export const findAllBrands = (
  subTypes: DeepReadonly<GrogSubTypeModel[]>
): DeepReadonly<GrogBrandModel[]> => {
  const brands = []

  for (const subType of subTypes) {
    if (!grogTypeHasBrands(subType)) {
      // Ignore
      continue
    }

    // Add all brands
    brands.push(...subType.brands!)
  }

  return brands
}
