import React from 'react'
import styles from './InitialisationScreen.module.scss'
import classnames from 'classnames'
import { Card } from 'components/Card/Card.component'
import { InitialisationScreenProps } from './InitialisationScreen.model'
import { Alert } from 'components/Alert/Alert.component'
import { colors } from 'shared/theme/theme'

/**
 * A base component to render the general structure of screens used in the initialisation
 * of the app, such as the login screen and the project selection screens.
 *
 * @param props The props taken by this component.
 *
 * @returns A component which renders, with any children passed as required, a general
 * stucture of the screen used in the initialisation of the app.
 */
export const InitialisationScreen: React.FC<InitialisationScreenProps> = (
  props
) => {
  const {
    mainWrapper,
    contentDivClasses,
    card,
    children,
    alert,
    belowCardChildren
  } = props

  return (
    <main
      className={classnames(
        styles['initialisation-screen'],
        mainWrapper.additionalStylingClasses
      )}
    >
      <h1 className={styles['initialisation-screen-main-header']}>
        {mainWrapper.title}
      </h1>
      <div className={classnames(contentDivClasses)}>
        <Card
          className={classnames(card.additionalStylingClasses)}
          title={card.title}
          buttonDisabled={card.button?.disabledCondition}
          buttonText={card.button?.text}
          extraAction={card.extraAction}
          onButtonClick={card.button?.onClick}
        >
          {alert && (
            <Alert
              className={classnames(
                styles['initialisation-screen-alert'],
                alert.additionalStylingClasses
              )}
              fullWidth={true}
              text={alert.text}
              backgroundColor={colors.crimson}
            />
          )}
          {children}
        </Card>
        {belowCardChildren}
      </div>
    </main>
  )
}
