import React, { memo, ReactElement } from 'react'
import { ProductPropsModel } from './Product.model'
import classnames from 'classnames'
import styles from './Product.module.scss'

export const Product = memo(function Product({
  isPack,
  clipPercentage,
  displayPercentage = 100,
  image,
  style
}: ProductPropsModel): ReactElement {
  // Calculate the clipping path based on the display percentage
  const clipPathDisplayPercentage = 100 - displayPercentage

  return (
    <div className={classnames(styles['product-wrapper'])}>
      <img
        className={classnames(styles['product-img'])}
        // Styles to clip the image (based on proportion of container)
        style={{
          clipPath: `inset(${isPack ? 0 : clipPercentage}% ${
            isPack ? clipPercentage : 0
          }% 0% 0%)`,
          ...style
        }}
        src={image}
        role="presentation"
        alt=""
      />
      <img
        className={classnames(styles['product-background'])}
        width="100%"
        // Styles to clip the background (based on proportion of container)
        style={{
          clipPath: `inset(${isPack ? 0 : clipPathDisplayPercentage}% ${
            isPack ? clipPathDisplayPercentage : 0
          }% 0% 0%)`,
          ...style
        }}
        src={image}
        role="presentation"
        alt=""
      />
    </div>
  )
})
