import React, { useState, useEffect, useMemo, useCallback } from 'react'
import 'shared/styles/app.scss'
import { useLocation, Route, Routes, matchPath } from 'react-router-dom'
import { ScreenComponent, ScreenType } from 'screens/survey/type.d'
import { connect } from 'react-redux'
import { HeaderPropsModel } from 'components/Header/Header.model'
import {
  GrogShopDataModel,
  GrogSubTypeModel,
  GrogTypeCategoryModel,
  SpecialEventDataModel,
  SurveyDataScreenModel
} from 'api/client.model'
import { FooterPropsModel } from 'components/Footer/Footer.model'
import { SurveyState } from 'store/type.d'
import { AudioContext, AudioContextProps } from 'context/AudioContext'
import { ColourContext } from 'context/ColourContext'
import {
  loadGrogAudio,
  loadGrogDiaryAudio,
  loadScreenAudio
} from 'shared/utils/loadScreenAudio/loadScreenAudio'
import {
  calculateSpecialEvent,
  parseSpecialEvent
} from 'shared/utils/calculateSpecialEvent/calculateSpecialEvent'
import { DEFAULT_COLOUR_INFO } from 'shared/constants/Constants.d'
import 'shared/styles/app.scss'

const InitialSurveySetup = (
  props: SurveyState & {
    demoMode?: boolean
    specialEvents: SpecialEventDataModel[]
    typesCategories: Array<GrogTypeCategoryModel | GrogSubTypeModel>
  }
) => {
  const { survey, demoMode, specialEvents, typesCategories = [] } = props

  // Set current dynamic event from list of events
  const specialEvent = useMemo(
    () => calculateSpecialEvent(specialEvents),
    [specialEvents]
  )

  const location = useLocation()

  const startScreenData = survey?.screens[0]

  const [screenData, setScreenData] = useState<
    SurveyDataScreenModel | undefined
  >(startScreenData)
  const [progress, setProgress] = useState<number>(0)
  const [voice, setVoice] = useState<string>(
    survey ? survey.voiceOver[0].value : 'enMale'
  )
  const [audio, setAudio] = useState<AudioContextProps>({ files: {} })
  const [activeAudio, setActiveAudio] = useState<HTMLAudioElement | undefined>()
  const [voiceMute, setVoiceMute] = useState<boolean>(false)
  const [hasAudio, setHasAudio] = useState<boolean>(false)
  const [screenAudioKey, setScreenAudioKey] = useState<string | undefined>(
    undefined
  )

  const findScreen = (id: string): SurveyDataScreenModel | undefined => {
    return survey?.screens?.find((item) => item.id === id)
  }

  const filterVoiceOver = () => {
    if (!screenData || !screenData.voices || !survey) {
      return []
    }

    const validVoices = Object.keys(screenData.voices)
    return survey.voiceOver.filter((voiceItem) =>
      validVoices.includes(voiceItem.value)
    )
  }

  const handleVoiceChange = (value: string) => {
    setVoice(value)
  }

  const handleMuteChange = (mute: boolean) => {
    setVoiceMute(mute)
  }

  const Component: React.FC<any> = screenData
    ? ScreenComponent[Object.keys(screenData.data)[0] as ScreenType]
    : ('div' as any)

  const generateHeaderProps = (): HeaderPropsModel => {
    return {
      colours: screenData?.section ?? DEFAULT_COLOUR_INFO,
      nextScreenId: screenData?.nextScreen?.nextScreenId,
      progress,
      voice,
      mute: voiceMute,
      helpText: screenData?.helpText,
      voiceOver: filterVoiceOver(),
      playAudio,
      setVoice: handleVoiceChange,
      setMute: handleMuteChange
    }
  }

  const stopAudio = useCallback(() => {
    // Stop existing audio from playing
    if (activeAudio) {
      activeAudio.pause()
    }
  }, [activeAudio])

  const playAudio = useCallback(
    (key?: string, newVoice: string | undefined = undefined) => {
      newVoice = newVoice || voice
      stopAudio()

      if (key) {
        const file = audio.files[key]?.[newVoice]
        if (file) {
          const audioElement = new Audio(file)
          audioElement.currentTime = 0

          // Cross-origin = anonymous to help with Workbox caching from service worker
          audioElement.crossOrigin = 'anonymous'

          // Load the audio clip from the cache
          audioElement.load()

          // Play the audio clip
          audioElement
            .play()
            .then(() => setActiveAudio(audioElement))
            .catch((e) => console.warn(`Failed to play audio`, e))
        }
      }
    },
    [audio.files, stopAudio, voice]
  )

  const generateFooterProps = (
    data?: SurveyDataScreenModel,
    firstscreen?: boolean
  ): FooterPropsModel => {
    return {
      id: data?.id,
      colours: screenData?.section ?? DEFAULT_COLOUR_INFO,
      surveyEnd: screenData?.surveyEnd,
      demoMode,
      firstscreen,
      screenName: screenData?.surveyField,
      followUpQuestionName: screenData?.data.followUpQuestion.surveyField,
      nextScreenId: screenData?.nextScreen?.nextScreenId,
      conditions: screenData?.nextScreen?.isConditional
        ? screenData?.nextScreen?.conditions
        : undefined
    }
  }

  const calculateProgress = (sectionId: string) => {
    let totalSections = survey?.screens.map((screen) => screen.section.id)
    totalSections = Array.from(new Set(totalSections))
    const sectionIndex = totalSections.findIndex((each) => each === sectionId)
    return ((sectionIndex + 1) / totalSections.length) * 100
  }

  /** Update Screen Data based on Route */
  useEffect(() => {
    const path = matchPath(
      `survey/${demoMode ? 'demo/' : ''}:id`,
      location.pathname
    )

    const screensData = survey?.screens

    if (screensData) {
      if (path?.params.id) {
        const screen = findScreen(path.params.id)

        if (screen) {
          const parsedScreen = parseSpecialEvent(screen, specialEvent)

          setProgress(calculateProgress(parsedScreen.section.id))
          setScreenData(parsedScreen)
        } else {
          alert(`Unknown screen: ${path.params.id}`)
        }
      } else {
        const parsedScreen = startScreenData
          ? parseSpecialEvent(startScreenData, specialEvent)
          : startScreenData
        setScreenData(parsedScreen)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  // Load all audio screens
  useEffect(() => {
    // Load audio for all screens
    if (survey?.screens) {
      Promise.all(
        survey.screens.map((screen) => {
          loadScreenAudio(audio, screen, specialEvent?.id)
          if (screen?.data?.grogDiary && screen?.id) {
            loadGrogDiaryAudio(audio, screen?.id, screen)
            loadGrogAudio(audio, screen?.id, typesCategories)
          }
        })
      ).then(() => setAudio(audio))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [survey])

  useEffect(() => {
    if (activeAudio) activeAudio.muted = voiceMute
  }, [activeAudio, voiceMute])

  useEffect(() => {
    if (activeAudio) {
      activeAudio.onended = () => {
        setActiveAudio(undefined)
      }

      activeAudio.onpause = () => {
        setActiveAudio(undefined)
      }
    }
  }, [activeAudio])

  useEffect(() => {
    if (
      (screenData?.id && audio.files[screenData.id]?.[voice]) ||
      (screenAudioKey && audio.files[screenAudioKey]?.[voice])
    ) {
      setHasAudio(true)
    } else {
      setHasAudio(false)
    }
  }, [screenData?.id, audio.files, voice, screenAudioKey])

  // Modify the global CSS variables for primary and secondary colours
  useEffect(() => {
    const screenColours = screenData?.section ?? DEFAULT_COLOUR_INFO

    document.documentElement.style.setProperty(
      '--theme-primary-colour',
      screenColours.primaryColour
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenData?.section.id])

  const colourContextMemoisedValue = useMemo(
    // Return the section data - by structural typing, this satsifies the `ColourInfo`
    // type
    // The `useMemo` hook will ensure that the section data object passed to
    // `ColourContext.Provider` does not unnecessarily change until the section ID changes
    // If, for some reason, the section data is `undefined`, return fallback colours
    // sourced from the app
    () => screenData?.section ?? DEFAULT_COLOUR_INFO,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [screenData?.section.id]
  )

  const ctxValue = {
    files: audio.files,
    playAudio,
    stopAudio,
    hasAudio,
    activeAudio,
    screenAudioKey,
    setScreenAudioKey
  }

  return (
    <ColourContext.Provider value={colourContextMemoisedValue}>
      <AudioContext.Provider value={ctxValue}>
        <Routes>
          <Route
            index
            element={
              <Component
                key={startScreenData?.id}
                currentScreenId={startScreenData?.id}
                colours={screenData?.section ?? DEFAULT_COLOUR_INFO}
                name={screenData?.surveyField}
                screenData={
                  startScreenData?.data[Object.keys(startScreenData.data)[0]]
                }
                followUpQuestion={startScreenData?.data.followUpQuestion}
                headerProps={generateHeaderProps()}
                footerProps={generateFooterProps(startScreenData, true)}
                shortcodes={screenData?.shortcodes}
                specialEvent={specialEvent}
              />
            }
          />
          <Route
            path="/:id"
            element={
              <Component
                key={screenData?.id}
                currentScreenId={screenData?.id}
                colours={screenData?.section ?? DEFAULT_COLOUR_INFO}
                name={screenData?.surveyField}
                screenData={screenData?.data[Object.keys(screenData.data)[0]]}
                followUpQuestion={screenData?.data.followUpQuestion}
                headerProps={generateHeaderProps()}
                footerProps={generateFooterProps(screenData)}
                shortcodes={screenData?.shortcodes}
                specialEvent={specialEvent}
              />
            }
          />
        </Routes>
      </AudioContext.Provider>
    </ColourContext.Provider>
  )
}

const mapStateToProps = (state: SurveyState) => ({
  survey: state.survey,
  specialEvents:
    state.projects?.find(
      (project) => project.id === state.surveyMetaData?.projectId
    )?.specialEvents ?? [],
  typesCategories:
    (
      (state.user?.id === 'demo'
        ? state?.demoGrogShops
        : state?.grogShops ?? []
      )?.find(
        (shop) => shop.Id === state.surveyMetaData?.shopId
      ) as GrogShopDataModel
    )?.typesCategories ?? []
})

export const SurveyScreen = connect(mapStateToProps)(InitialSurveySetup)
