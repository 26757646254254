import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconFlagCross: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="82"
    height="80"
    viewBox="0 0 82 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 14.5732C13.9141 13.5437 16 10.7656 16 7.5C16 3.35791 12.6426 0 8.5 0C4.35742 0 1 3.35791 1 7.5C1 10.7656 3.08594 13.5437 6 14.5732V79H11V14.5732Z"
      fill="#F17358"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2008 18.6828C15.9933 15.5672 22.0533 10 33.8163 10C42.3025 10 46.3624 12.2253 49.9821 14.2094C52.8938 15.8054 55.5207 17.2453 59.9378 17.2453C65.1402 17.2453 68.6043 15.2478 71.8948 13.3505C74.3884 11.9127 76.7822 10.5323 79.7573 10.1224C80.743 9.98654 81.5556 10.811 81.5556 11.8113V48.9434C81.5556 49.9438 80.743 50.7413 79.7573 50.8771C76.7822 51.287 74.3884 52.6674 71.8948 54.1052C68.6043 56.0025 65.1402 58 59.9378 58C55.5207 58 52.8938 56.5601 49.9821 54.9641C46.3624 52.98 42.3025 50.7547 33.8163 50.7547C30.278 50.7547 23.1813 51.7609 17.8555 55.3545C16.4312 56.3155 14 55.3512 14 53.6265V19.4842C14 19.203 14.0608 18.9262 14.2008 18.6828ZM38.5942 23.744C39.9686 22.3697 42.1968 22.3697 43.5711 23.744L48.0109 28.1838L52.4506 23.7441C53.825 22.3698 56.0532 22.3698 57.4276 23.7441C58.8019 25.1185 58.8019 27.3467 57.4276 28.721L52.9878 33.1608L57.597 37.7699C58.9713 39.1442 58.9713 41.3725 57.597 42.7468C56.2226 44.1211 53.9944 44.1211 52.6201 42.7468L48.0109 38.1377L43.4017 42.7469C42.0274 44.1212 39.7991 44.1212 38.4248 42.7469C37.0505 41.3725 37.0505 39.1443 38.4248 37.77L43.034 33.1608L38.5942 28.721C37.2199 27.3466 37.2199 25.1184 38.5942 23.744Z"
      fill="#F17358"
    />
  </svg>
)
