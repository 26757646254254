import { Screen } from 'components/Screen/Screen.component'
import React, { useContext, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { updateSurveyAnswer } from 'store/reducer'
import { ISurveyData, SurveyState } from 'store/type'
import { PostCodeScreenPropsModel } from './PostCodeScreen.model'
import styles from './PostCodeScreen.module.scss'
import { PostCode } from 'components/PostCode/PostCode.component'
import { Pin } from 'components/Pin/Pin.component'
import { Modal } from 'components/Modals/Modal/Modal.component'
import { AutoCompleteLocation } from 'components/AutoCompleteLocation/AutoCompleteLocation.component'
import { matchCondition } from 'shared/utils/matchCondition/matchCondition'
import { GeoLocation } from 'api/client.model'
import classnames from 'classnames'
import { shortcodesRender } from 'shared/utils/shortcodesRender/shortcodesRender'
import { AudioContext } from 'context/AudioContext'
import { useColourPicker } from 'context/ColourContext'

export const PostCodeScreenComponent: React.FC<PostCodeScreenPropsModel> = (
  props
) => {
  const {
    currentScreenId,
    name,
    headerProps,
    screenData: { title, subtitle, dontKnowVoices },
    footerProps,
    geoLocations,
    shortcodes
  } = props

  const { primaryColour } = useColourPicker()

  const { playAudio } = useContext(AudioContext)
  const dispatch: Dispatch<any> = useDispatch()
  const { getNextScreenId } = matchCondition()

  const [userLocation, setUserLocation] = useState<GeoLocation | null>(null)
  const [pinValue, setPinValue] = useState<string[]>(['', '', '', ''])
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [communityLocation, setCommunityLocation] =
    useState<GeoLocation | null>(null)
  const [isValidLocation, setIsValidLocation] = useState<boolean>(false)

  const handleSetValue = (location: GeoLocation) => {
    dispatch(
      updateSurveyAnswer({
        [name]: location
      } as ISurveyData)
    )
  }

  const handleFooterProps = () => {
    const updatedFooterProps = { ...footerProps }
    if (!isValidLocation && !communityLocation) {
      updatedFooterProps.invalid = true
    } else {
      updatedFooterProps.invalid = false
    }

    const value = communityLocation || userLocation
    // Next Screen Condition Logic
    updatedFooterProps.nextScreenId = getNextScreenId(
      value,
      footerProps.conditions,
      footerProps.nextScreenId
    )

    updatedFooterProps.toggleNextButton = !!communityLocation && !modalOpen

    return updatedFooterProps
  }

  const onPinChange = (value: string[]) => {
    setPinValue(value)

    if (value.findIndex((pin) => pin === '') === -1) {
      checkPostcodeValidity(value)
    } else {
      setUserLocation(null)
      setIsValidLocation(false)
      setCommunityLocation(null)
    }
  }

  const onPinClick = (value: string) => {
    if (userLocation) {
      setUserLocation(null)
    }

    if (communityLocation) {
      setCommunityLocation(null)
    }

    const addPin = [...pinValue]
    const addIndex = addPin.findIndex((pin) => pin === '')
    if (addIndex !== -1) {
      addPin[addIndex] = value
      setPinValue(addPin)
    }

    if (addPin.findIndex((pin) => pin === '') === -1) {
      checkPostcodeValidity(addPin)
    }
  }

  const onPinDelete = () => {
    setUserLocation(null)
    setIsValidLocation(false)
    setCommunityLocation(null)
    const newPin = [...pinValue]
    let index = newPin.findIndex((pin) => pin === '')
    if (index === -1) {
      index = 4
    }

    if (index > 0) {
      newPin[index - 1] = ''
      setPinValue(newPin)
    }
  }

  const onModalOpen = () => {
    if (dontKnowVoices && playAudio && !modalOpen) {
      playAudio(`${currentScreenId}-dontknow`)
    }
    setModalOpen(true)
  }

  const checkPostcodeValidity = (pin: string[]) => {
    const validLocation = geoLocations?.find(
      (item) => item.postCode === pin.join('')
    )
    if (validLocation) {
      setUserLocation(validLocation)
      setIsValidLocation(true)
    } else {
      setIsValidLocation(false)
    }
  }

  const handleModalClose = (cancel?: boolean) => {
    setModalOpen(false)
    if (cancel) {
      setPinValue(['', '', '', ''])
      setCommunityLocation(null)
      setUserLocation(null)
    }
  }

  useEffect(() => {
    const postCodeElement: HTMLElement = document.querySelector('#pin-1')!
    postCodeElement.focus()
  }, [])

  useEffect(() => {
    if (communityLocation) {
      setPinValue(communityLocation.postCode.split(''))
      setUserLocation(communityLocation)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityLocation])

  useEffect(() => {
    const location = communityLocation || userLocation
    if (location) {
      handleSetValue(location)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLocation, communityLocation])

  // Clear Redux
  useEffect(() => {
    const reset: ISurveyData = {
      [name]: undefined
    }

    dispatch(updateSurveyAnswer(reset))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name])

  return (
    <Screen
      currentScreenId={currentScreenId}
      headerProps={headerProps}
      footerProps={handleFooterProps()}
    >
      {title && (
        <h1
          className={classnames(
            'screen-titles--title',
            styles['postcodescreen-title']
          )}
          dangerouslySetInnerHTML={{
            __html: shortcodesRender(shortcodes, title) || title
          }}
        />
      )}
      {subtitle && (
        <p
          className={classnames(
            'screen-titles--subtitle',
            styles['postcodescreen-subtitle']
          )}
          dangerouslySetInnerHTML={{
            __html: shortcodesRender(shortcodes, subtitle) || subtitle
          }}
        />
      )}
      <div className={styles['postcodescreen-pin']}>
        <Pin pinValue={pinValue} setValueArray={onPinChange} />
      </div>
      <div className={styles['postcodescreen-locality']}>
        {userLocation ? (
          `${userLocation.locality} / ${userLocation.state}`
        ) : (
          <span style={{ visibility: 'hidden' }}>unknown</span>
        )}
      </div>
      <PostCode
        onPinClick={onPinClick}
        handleModalOpen={onModalOpen}
        handleDelete={onPinDelete}
      />

      <Modal
        fullWidth
        open={modalOpen}
        className={styles['postcodescreen-modal']}
        customClose={handleModalClose}
        invalid={!communityLocation}
        style={{ backgroundColor: primaryColour }}
      >
        {geoLocations && (
          <div className={styles['postcodescreen-inputWrapper']}>
            <AutoCompleteLocation
              dataList={geoLocations}
              value={communityLocation}
              setValue={setCommunityLocation}
            />
          </div>
        )}
      </Modal>
    </Screen>
  )
}

const mapStateToProps = (state: SurveyState) => ({
  geoLocations:
    state.user?.id === 'demo' ? state.demoGeoLocations : state.geoLocations
})

export const PostCodeScreen = connect(mapStateToProps)(PostCodeScreenComponent)
