import React, { createContext } from 'react'

export interface AudioContextProps {
  files: { [page: string]: { [voice: string]: string } }
  playAudio?: (key?: string, newVoice?: string) => void
  stopAudio?: () => void
  hasAudio?: boolean
  activeAudio?: HTMLAudioElement
  screenAudioKey?: string
  setScreenAudioKey?: React.Dispatch<React.SetStateAction<string | undefined>>
}

const initial: AudioContextProps = {
  files: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  playAudio: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  stopAudio: () => {},
  hasAudio: false,
  activeAudio: undefined,
  screenAudioKey: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setScreenAudioKey: () => {}
}

export const AudioContext = createContext<AudioContextProps>(initial)
