import React, { useCallback, useEffect } from 'react'
import styles from './Carousel.module.scss'
import { Icon } from 'components/Icons/Icon.component'
import { IconType } from 'components/Icons/Icon.model'
import { IconButton } from '@mui/material'
import useEmblaCarousel from 'embla-carousel-react'
import type { EmblaCarouselType } from 'embla-carousel'
import { usePrevNextButtons } from './hooks'
import type { CarouselProps } from './Carousel.model'
import * as iterUtils from 'shared/utils/iterUtils/iterUtils'

/**
 * A carousel component for the application, using Embla Carousel (and based off some
 * designs from Embla Carousel's "drag free" example:
 * https://codesandbox.io/p/sandbox/7596p3).
 *
 * The component takes children which will be used to represent each slide of the
 * carousel.
 *
 * @param props - The props passed to this component, as well as the child elements passed
 * which will represent the slids of the carousel.
 *
 * @returns A renderred carousel component.
 */
export const Carousel: React.FC<CarouselProps> = (props) => {
  const {
    children,
    slidesToShow = 4,
    draggable: propDraggable,
    showButtons: propShowButtons
  } = props

  const numChildren = React.Children.count(children)

  // Showing buttons and draggable features are determined by either:
  // - the user providing an explicit Boolean value for the `showButtons` or `draggable`
  //   props respectively
  // - otherwise, if there are enough slides to display in a single viewport, disable
  //   buttons and draggable feature
  const draggable = propDraggable ?? numChildren > slidesToShow
  const showButtons = propShowButtons ?? numChildren > slidesToShow

  const [emblaRef, emblaApi] = useEmblaCarousel({
    dragFree: draggable,
    watchDrag: draggable,
    inViewThreshold: 0.5,
    align: 'start'
  })

  const {
    prevButtonDisabled,
    nextButtonDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi)

  /**
   * Set the opacity of slides in view for the carousel. Slides outside the viewport are
   * set to an opacity of 0.3.
   *
   * @param emblaApi - The Embla Carousel API object.
   */
  const setVisibleSlidesOpacity = useCallback((emblaApi: EmblaCarouselType) => {
    for (const [slideIndex, slide] of iterUtils.enumerateArray(
      emblaApi.slideNodes()
    )) {
      if (emblaApi.slidesInView().includes(slideIndex)) {
        slide.style.opacity = '1'
      } else {
        slide.style.opacity = '0.3'
      }
    }
  }, [])

  useEffect(() => {
    emblaApi?.on('slidesInView', setVisibleSlidesOpacity)
  }, [emblaApi, setVisibleSlidesOpacity])

  return (
    <div className={styles.carousel}>
      {showButtons && (
        <IconButton
          className={styles.carousel__buttons}
          disabled={prevButtonDisabled}
          disableRipple={true}
          onClick={onPrevButtonClick}
        >
          <Icon icon={IconType.ArrowLeft} size={36} border={true} />
        </IconButton>
      )}

      <div className={styles.carousel__viewport} ref={emblaRef}>
        <div className={styles.carousel__container}>
          {React.Children.map(children, (child, index) => (
            <div
              className={styles.carousel__slide}
              key={`carousel-child-${index}`}
              style={{
                flex: `0 0 ${100 / slidesToShow}%`
              }}
            >
              {child}
            </div>
          ))}
        </div>
      </div>

      {showButtons && (
        <IconButton
          className={styles.carousel__buttons}
          disabled={nextButtonDisabled}
          disableRipple={true}
          onClick={onNextButtonClick}
        >
          <Icon icon={IconType.ArrowRight} size={36} border={true} />
        </IconButton>
      )}
    </div>
  )
}
