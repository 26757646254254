import React from 'react'
import { IconPropsModel } from '../../Icon.model'

export const IconArrowUpWhite: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="18"
    height="10"
    viewBox="0 0 18 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.64466 -5.72205e-06C7.9377 -5.72205e-06 7.22089 0.220134 6.62132 0.671154C6.1703 0.968254 4.86913 1.89804 3.87312 3.14372C2.60686 4.74736 0.455553 6.73222 0.433181 6.7528C-0.111805 7.25393 -0.147601 8.10408 0.354431 8.64907C0.857357 9.19405 1.7066 9.22895 2.25069 8.72782C2.34555 8.64101 4.58277 6.57651 5.97521 4.81358C6.49783 4.15942 7.32112 3.4283 8.17126 2.86094C8.43794 2.66585 8.80574 2.63095 9.04825 2.84393C9.38652 3.10882 9.69436 3.33523 10.0031 3.56074C10.549 3.96165 11.1145 4.37598 11.3991 4.66145C12.3182 5.49101 14.882 7.96447 14.9071 7.98953C15.4404 8.50409 16.2915 8.48888 16.8051 7.95642C17.3197 7.42217 17.3054 6.57293 16.772 6.05837C16.6655 5.95456 14.1482 3.52584 13.2471 2.71507C12.8685 2.33295 12.2466 1.87835 11.5897 1.39601C11.3034 1.18482 11.0152 0.975414 10.7566 0.773174C10.174 0.262194 9.41516 -5.72205e-06 8.64466 -5.72205e-06Z"
      fill={props.fill || 'white'}
    />
  </svg>
)
