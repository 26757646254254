import React from 'react'
import classnames from 'classnames'
import { ButtonPropsModel } from './Button.model'
import styles from './Button.module.scss'
import { Icon } from 'components/Icons/Icon.component'
import IconButton from '@mui/material/IconButton'
import { BorderType } from 'components/Border/Border.model'
import { colors } from 'shared/theme/theme'
import { Border } from 'components/Border/Border.component'

export const Button: React.FC<ButtonPropsModel> = (props) => {
  const {
    className,
    variation,
    icon,
    iconPosition = 'left',
    iconProps,
    width,
    disabled,
    title,
    children,
    onClick,
    'aria-label': ariaLabel,
    contentBefore,
    contentAfter,
    noBorder,
    dropShadow,
    borderType,
    borderColor,
    style,
    ...componentProps
  } = props

  const classes = classnames(
    styles.button,
    styles['button-width'],
    styles['button-variation'],
    className,
    {
      [styles['button-icon']]: !!icon,
      [styles[`button-icon-${iconPosition}`]]: !!icon,
      [styles[`button-variation-${variation}`]]: !icon,
      [styles[`button-width-${width}`]]: !!width
    }
  )

  return (
    <div {...componentProps} className={classes}>
      {!noBorder && (
        <Border
          type={
            borderType ||
            (variation === 'primary' ? BorderType.Button2 : BorderType.Button1)
          }
          aria-label={ariaLabel}
          fill={variation === 'primary' ? colors.black : style?.backgroundColor}
          stroke={borderColor}
          dropShadow={dropShadow}
          disabled={disabled}
        />
      )}
      {contentBefore}
      <IconButton
        disabled={disabled}
        disableRipple
        disableFocusRipple
        aria-label={ariaLabel}
        className={classnames('u-border-style-sketch', {
          [styles['button--no-border']]: noBorder
        })}
        onClick={onClick}
        sx={{ ...style, backgroundColor: 'transparent' }}
      >
        {icon && (iconPosition === 'top' || iconPosition === 'left') && (
          <div className={styles['button-icon']}>
            <Icon icon={icon} {...iconProps} />
          </div>
        )}
        {children}
        {icon && (iconPosition === 'bottom' || iconPosition === 'right') && (
          <div className={styles['button-icon']}>
            <Icon icon={icon} {...iconProps} />
          </div>
        )}
      </IconButton>
      {contentAfter}
    </div>
  )
}
