import React, { useState } from 'react'
import Modal from '@mui/material/Modal'
import { Box, Grid } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import HomeIcon from '@mui/icons-material/Home'
import FlagIcon from '@mui/icons-material/Flag'
import store from 'store/store'
import { FlagCross, FlagLightning, FlagQuestion } from './icons/flags'
import InnerModal, { ExitDashboardConfirm } from './racomponents/InnerModal'

import type { GrogDiaryData, GrogDiaryDataDetail } from 'store/type.d'
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%',
  boxShadow: 24,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center'
}
type SideBarProps = {
  show: boolean
  setOpen: (value: React.SetStateAction<boolean>) => void
  setShow: (value: React.SetStateAction<boolean>) => void
  handleToDasboard: () => void
}
const GROG_DIARY = 'grog-diary'
function SideBar({ show, setOpen, setShow, handleToDasboard }: SideBarProps) {
  const storedData = store.getState()
  const storeData = storedData?.surveyData

  const grogDiary: { [key: string]: GrogDiaryData } | undefined = storeData
    ? typeof storeData[GROG_DIARY] === 'string'
      ? JSON.parse(storeData[GROG_DIARY])
      : storeData[GROG_DIARY]
    : undefined

  const latestSession = grogDiary
    ? grogDiary[Object.keys(grogDiary)[0]]
    : undefined
  return (
    <div
      style={{
        display: show ? 'flex' : 'none',
        flexDirection: 'column',
        height: '100%',
        width: '20%',
        background: '#392D4B',
        justifyContent: 'space-between'
      }}
    >
      <button
        onClick={() => setOpen(false)}
        style={{
          padding: '20px 40px',
          background: 'none',
          border: 'none'
        }}
      >
        <CloseIcon fontSize="large" style={{ color: 'white' }} />
      </button>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 5
        }}
      >
        <button
          onClick={() => {
            console.log('loggin')
            // console.log('grogDiary', grogDiary)
            // console.log('latestSession', latestSession)
            console.log('storedData', storedData)
            console.log('startTime', storedData?.surveyMetaData?.startTime)
            const surveyStartTime = storedData?.surveyMetaData?.startTime
          }}
        >
          log
        </button>
        <button
          onClick={() => setShow((s) => !s)}
          style={{
            padding: '20px 40px',
            border: 'none',
            color: 'white',
            background: 'none',

            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 5,
            justifyContent: 'center'
          }}
        >
          <FlagIcon fontSize="large" style={{ color: 'white' }} />
          <span>Flag Survey</span>
        </button>
        <button
          onClick={handleToDasboard}
          style={{
            padding: '20px 40px',
            color: 'white',
            background: 'none',
            border: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 5,
            justifyContent: 'center'
          }}
        >
          <HomeIcon fontSize="large" style={{ color: 'white' }} />
          <span>Dashboard</span>
        </button>
      </div>
    </div>
  )
}

const formatDate = (date: Date | undefined): string => {
  if (!date) return ''

  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  }

  return date.toLocaleString('en-AU', options)
}

export default function RASurveyControls() {
  const [open, setOpen] = useState(false)
  const [modalState, setModalState] = useState('')
  const [show, setShow] = useState(false)
  const [exitDashboard, setExitDashboard] = useState(false)
  const toggleModal = () => {
    setOpen((isOpen) => !isOpen)
    if (open) {
      setExitDashboard(false)
    }
  }
  const modalClose = () => {
    setModalState('')
    setExitDashboard(false)
  }

  const handleRightClick = (event: React.MouseEvent) => {
    event.preventDefault()
    setOpen(true)
    setModalState('')
    setShow(true)
    setExitDashboard(false)
  }

  const handleTouchStart = (event: React.TouchEvent) => {
    if (event.touches.length === 3) {
      setOpen(true)
      setModalState('')
      setShow(true)
      setExitDashboard(false)
    }
  }

  const handleToDasboard = () => {
    setShow(false)
    setExitDashboard(true)
  }

  const globalState = store.getState()

  // todo: integrate with survey state
  const gender = globalState.surveyData?.['participant-gender'] ?? 'N/A' //'Male'
  const age = globalState.surveyData?.['age'] ?? 'N/A'

  const date = formatDate(globalState.surveyMetaData?.startTime)
  const surveyStatus = globalState.surveyMetaData?.status ?? 'in progress'
  const surveyId = globalState?.surveyMetaData?.slk ?? 'undefined'

  return (
    <>
      <div
        style={{
          minHeight: '100%',
          width: '100%'
        }}
        onContextMenu={handleRightClick}
        onTouchStart={handleTouchStart}
      />
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              height: '100%',
              width: '100%'
            }}
          >
            <SideBar
              setOpen={setOpen}
              setShow={setShow}
              show={show}
              handleToDasboard={handleToDasboard}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: show ? '80%' : '100%',
                placeContent: 'center'
              }}
            >
              {/* backdrop */}

              {!show && !exitDashboard && (
                <div
                  style={{
                    width: '50%',
                    height: '50%',
                    background: 'white',
                    padding: 'auto',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      background: '#028B83',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <button
                      onClick={() => {
                        toggleModal()
                        modalClose()
                        setShow(false)
                      }}
                      style={{
                        padding: '20px',
                        background: 'none',
                        border: 'none'
                      }}
                    >
                      <CloseIcon fontSize="large" style={{ color: 'white' }} />
                    </button>
                    <span style={{ color: 'white' }}>
                      {surveyStatus} Survey: {surveyId}
                    </span>
                  </div>
                  <div
                    style={{
                      padding: '20px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-evenly',
                      height: '100%'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        padding: '20px'
                      }}
                    >
                      <div style={{ width: '20%', marginRight: '10%' }}>
                        <div>Gender:</div>
                        <div>Age:</div>
                        <div>Date:</div>
                        <div>Status:</div>
                      </div>
                      <div style={{ width: '70%' }}>
                        <div>{gender}</div>
                        <div>{age}</div>
                        <div>{date}</div>
                        <div>{surveyStatus}</div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%'
                      }}
                    >
                      <span>Flag this survey:</span>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                          justifyContent: 'center',
                          marginTop: 10,
                          gap: 10
                        }}
                      >
                        <FlagButton
                          onClick={() => setModalState('delete')}
                          FlagIcon={FlagCross}
                          text="Deletion"
                        />
                        <FlagButton
                          onClick={() => setModalState('incomplete')}
                          FlagIcon={FlagQuestion}
                          text="Incomplete"
                        />
                        <FlagButton
                          onClick={() => setModalState('technical')}
                          FlagIcon={FlagLightning}
                          text="Technical"
                        />
                        <InnerModal
                          modalState={modalState}
                          modalClose={modalClose}
                          modalCloseParent={() => setOpen(false)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {exitDashboard && !show && (
                <ExitDashboardConfirm
                  modalState={modalState}
                  modalClose={modalClose}
                  modalCloseParent={() => {
                    setOpen(false)
                    setExitDashboard(false)
                  }}
                />
              )}
            </div>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

type FlagButtonTypes = {
  onClick: () => void
  FlagIcon: () => JSX.Element
  text: string
}

function FlagButton({ onClick, FlagIcon, text }: FlagButtonTypes) {
  return (
    <button
      style={{
        background: '#d3d3d3',
        height: '150px',
        width: '150px',
        border: 'none',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      onClick={onClick}
    >
      <FlagIcon />
      <span>{text}</span>
    </button>
  )
}
