import React from 'react'
import styles from './SupportCard.module.scss'
import { Icon } from 'components/Icons/Icon.component'
import { IconType } from 'components/Icons/Icon.model'
import { Contacts } from 'api/client.model'
import { colors } from 'shared/theme/theme'
import { Border } from 'components/Border/Border.component'
import { BorderType } from 'components/Border/Border.model'

export const SupportCard: React.FC<Contacts> = (props) => {
  const { name, image, email, phone } = props

  return (
    <div className={styles['supportCard-container']}>
      <Border type={BorderType.Card1} fill={colors.capePalliser} dropShadow />

      <div className={styles['supportCard-card']}>
        <div className={styles['supportCard-image']}>
          <Border
            type={BorderType.Circle2}
            fill={image ? 'none' : colors.white}
            image={image}
          />
        </div>
        <div>
          <div className={styles['supportCard-name']}>{name}</div>
          {phone && (
            <div className={styles['supportCard-info']}>
              <a href={`tel:${phone}`}>
                <Icon icon={IconType.Phone} />
                {phone}
              </a>
            </div>
          )}
          {email && (
            <div className={styles['supportCard-info']}>
              <a href={`mailto:${email}`}>
                <Icon icon={IconType.Email} />
                {email}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
