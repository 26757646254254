import { SLKResponseModel } from 'api/client.model'
import { SurveyResponse } from 'store/type'

export const filterSlkByProjectId = (
  slkList?: SLKResponseModel,
  projectId?: string
) => {
  if (!slkList) {
    return []
  }
  const entry = slkList.find((item) => item.projectId === projectId)
  return entry?.slkList
}

export const getContinuingSlks = (
  surveyResponses: SurveyResponse[],
  projectId?: string
) => {
  const slks = []

  for (const surveyResponse of surveyResponses) {
    if (
      surveyResponse.metadata?.flaggedComingBack &&
      surveyResponse.metadata?.projectId === projectId
    ) {
      slks.push(surveyResponse.metadata.slk!)
    }
  }

  return slks
}
