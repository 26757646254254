import React, { useEffect, useState } from 'react'
import { Screen } from 'components/Screen/Screen.component'
import { Slider } from 'components/Slider/Slider.component'
import { Dispatch } from 'redux'
import { useDispatch } from 'react-redux'
import { matchCondition } from 'shared/utils/matchCondition/matchCondition'
import { shortcodesRender } from 'shared/utils/shortcodesRender/shortcodesRender'
import { updateSurveyAnswer } from 'store/reducer'
import { ISurveyData } from 'store/type'
import { ModalScreen } from '../ModalScreen/ModalScreen'
import { SliderScreenPropsModel } from './SliderScreen.model'

export const SliderScreen: React.FC<SliderScreenPropsModel> = (props) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { getNextScreenId, showFollowUpQuestion } = matchCondition()
  const {
    currentScreenId,
    name,
    headerProps,
    screenData: {
      range,
      title,
      subtitle,
      image,
      min = 0,
      max = 100,
      defaultValue,
      labelPrefix,
      labelSuffix,
      minLabel,
      maxLabel,
      isOptional,
      customLabels,
      optionalButtons
    },
    footerProps,
    followUpQuestion,
    shortcodes
  } = props

  const [sliderValue, setSliderValue] = useState<number[] | string>([])
  const [showModal, setShowModal] = useState<boolean>(false)
  const [isFollowUpValueSet, setIsFollowUpValueSet] = useState<boolean>(false)

  const handleSetValue = (value: number | number[] | string) => {
    dispatch(
      updateSurveyAnswer({
        [name]: value
      } as ISurveyData)
    )

    setSliderValue(typeof value === 'number' ? [value] : value)

    // Remove follow up data if main screen data changed and doesn't match any conditions
    if (
      followUpQuestion &&
      followUpQuestion.isActive &&
      !showFollowUpQuestion(value, followUpQuestion) &&
      followUpQuestion.surveyField
    ) {
      dispatch(
        updateSurveyAnswer({
          [followUpQuestion.surveyField]: undefined
        } as ISurveyData)
      )

      followUpQuestion?.additionalQuestions?.forEach((question) => {
        const { surveyField } = question
        if (surveyField) {
          dispatch(
            updateSurveyAnswer({
              [surveyField]: undefined
            } as ISurveyData)
          )
        }
      })

      setIsFollowUpValueSet(false)
    }
  }

  const handleFooterProps = () => {
    const updatedFooterProps = { ...footerProps }
    if (sliderValue.length === 0) {
      updatedFooterProps.invalid = true
    } else {
      updatedFooterProps.invalid = false
    }

    const formatSliderValue = range ? sliderValue : sliderValue[0]
    // Next Screen Condition Logic
    if (
      !isFollowUpValueSet &&
      followUpQuestion &&
      followUpQuestion.isActive &&
      showFollowUpQuestion(formatSliderValue, followUpQuestion)
    ) {
      updatedFooterProps.customNextClick = () => setShowModal(true)
    } else {
      updatedFooterProps.nextScreenId = getNextScreenId(
        formatSliderValue,
        footerProps.conditions,
        footerProps.nextScreenId
      )
    }

    updatedFooterProps.toggleNextButton = isFollowUpValueSet && !showModal

    return updatedFooterProps
  }

  const handleModalScreenClose = (hasValue: boolean) => {
    setShowModal(false)
    if (hasValue) {
      setIsFollowUpValueSet(true)
    } else {
      setIsFollowUpValueSet(false)
    }
  }

  const handleDefaultValue = () => {
    if (sliderValue.length === 0) {
      if (defaultValue != null && defaultValue !== 0) {
        return range ? [defaultValue, max] : defaultValue
      }

      return undefined
    }

    if (range || typeof sliderValue === 'string') {
      return sliderValue
    }

    return sliderValue[0]
  }

  // Clear Redux
  useEffect(() => {
    // reset value when name changes
    setSliderValue([])
    setIsFollowUpValueSet(false)

    const reset: ISurveyData = {
      [name]: undefined
    }

    if (followUpQuestion && followUpQuestion.surveyField) {
      reset[followUpQuestion.surveyField] = undefined
    }

    dispatch(updateSurveyAnswer(reset))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name])

  return (
    <div className={`drug-app-screen`}>
      <Screen
        currentScreenId={currentScreenId}
        headerProps={headerProps}
        footerProps={handleFooterProps()}
      >
        <Slider
          range={range}
          title={shortcodesRender(shortcodes, title)}
          subtitle={shortcodesRender(shortcodes, subtitle)}
          image={image}
          labelPrefix={labelPrefix}
          labelSuffix={labelSuffix}
          min={min}
          max={max}
          defaultValue={handleDefaultValue()}
          setValue={handleSetValue}
          minLabel={minLabel}
          maxLabel={maxLabel}
          customLabels={customLabels}
          optionalButtons={isOptional ? optionalButtons : undefined}
        />
        {followUpQuestion &&
          !!followUpQuestion.isActive &&
          followUpQuestion.data && (
            <ModalScreen
              screenId={currentScreenId}
              modalData={followUpQuestion}
              open={showModal}
              onModalScreenClose={handleModalScreenClose}
              shortcodes={shortcodes}
              value={sliderValue}
            />
          )}
      </Screen>
    </div>
  )
}
