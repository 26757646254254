import type { GrogSubTypeModel, GrogTypeCategoryModel } from 'api/client.model'
import { NonNullAssertionError } from './errors'
import type { DeepReadonly } from './general'

// NOTE: Non arrow function used to avoid the TypeScript error "Assertions require every
// name in the call target to be declared with an explicit type annotation. `ts(2775)`"
export function assertNonNull<T>(
  value: T,
  valueName?: string
): asserts value is NonNullable<T> {
  if (value == null) {
    throw new NonNullAssertionError(valueName)
  }
}

/**
 * A type guard used to determine whether the provided value is of `string` type or not.
 *
 * @param value - The value to check whether it is a `string` type.
 *
 * @returns `true` if `value` is a `string`, `false` otherwise.
 */
export const isString = (value: any): value is string => {
  return typeof value === 'string' || value instanceof String
}

export const typeCategoryHasSubTypes = (
  typeCategory:
    | DeepReadonly<GrogTypeCategoryModel | GrogSubTypeModel>
    | undefined
): typeCategory is DeepReadonly<GrogTypeCategoryModel> => {
  if (
    typeCategory &&
    (typeCategory as DeepReadonly<GrogTypeCategoryModel>).subTypes
  ) {
    return true
  } else {
    return false
  }
}
