import { configureStore } from '@reduxjs/toolkit'
import reducer from './reducer'
import storage from 'redux-persist-indexeddb-storage'
import { persistReducer } from 'redux-persist'
import { REDUX_STORE_PERSIST_WHITELIST } from 'shared/constants/redux'

const persistConfig = {
  key: 'root',
  storage: storage('drugApp'),
  throttle: 5000,
  // Copy has to be created as `persistReducer` only accepts mutable `string` arrays here
  // for some reason
  whitelist: [...REDUX_STORE_PERSIST_WHITELIST]
}

const persistedReducer = persistReducer(persistConfig, reducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})

export default store
