import type { GrogDiaryData, GrogDiaryDataDetail } from 'store/type'

export enum RiskLevel {
  'high risk' = 'highRiskImage',
  'risky' = 'riskyImage',
  'low risk' = 'lowRiskImage',
  'not drinking' = 'notDrinkingImage'
}

export enum RiskLevelVoices {
  'high risk' = 'highrisk',
  'risky' = 'risky',
  'low risk' = 'lowrisk',
  'not drinking' = 'notdrinking'
}

export enum RiskLevelParagraphs {
  'high risk' = 'highRiskParagraph',
  'risky' = 'riskyParagraph',
  'low risk' = 'lowRiskParagraph',
  'not drinking' = 'notDrinkingParagraph'
}

export enum ScoreCalc {
  'never' = 0,
  'once-in-a-blue-moon' = 1,
  'sometimes' = 2,
  'weekly' = 3,
  'most-days-or-every-day' = 4
}

export interface GrogDiaryConsumptionData {
  container: {
    capacity?: number
    grog?: number
  }
  drinkAmount?: number
  productCapacity?: number
  subContainer?: {
    amount: number
    stepsInEach: number
  }
  alcoholPercentage?: number
}

export interface AlcoholCalcModel {
  calcAlcoholRiskLevel: () => keyof typeof RiskLevel
  calcTotalStandardDrinks: () => number
  calcStandardDrinks: (consumption: GrogDiaryConsumptionData) => number
  getConsumptions: (session?: GrogDiaryData) => GrogDiaryDataDetail[]
  capacityOfDrink: (consumption: GrogDiaryConsumptionData) => number
  calcGramsOfDrink: (consumption: GrogDiaryConsumptionData) => number
}
