import React from 'react'
import 'shared/styles/app.scss'
import { Route, Routes } from 'react-router-dom'
import { ProjectSelectorScreen } from './Initialisation/ProjectSelectorScreen/ProjectSelectorScreen'
import { DashboardScreen } from './DashboardScreen/DashboardScreen'
import { LoginScreen } from './Initialisation/LoginScreen/LoginScreen'
import { SurveysScreen } from './SurveysScreen/SurveysScreen'
import { DeclinedScreen } from './DeclinedScreen/DeclinedScreen'
import { ProfileScreen } from './ProfileScreen/ProfileScreen'
import { ForgotPinScreen } from './ForgotPinScreen/ForgotPinScreen'
import { SupportScreen } from './SupportScreen/SupportScreen'
import { ProgressScreen } from './ProgressScreen/ProgressScreen'
import { LoadingAssetsScreen } from './Initialisation/LoadingAssetsScreen/LoadingAssetsScreen'

export const RADashboardScreen = () => {
  return (
    <Routes>
      <Route index element={<LoginScreen />} />
      <Route path="/loading-assets" element={<LoadingAssetsScreen />} />
      <Route path="/select-project" element={<ProjectSelectorScreen />} />
      <Route path="/dashboard" element={<DashboardScreen />} />
      <Route path="/surveys" element={<SurveysScreen />} />
      <Route path="/progress" element={<ProgressScreen />} />
      <Route path="/support" element={<SupportScreen />} />
      <Route path="/profile" element={<ProfileScreen />} />
      <Route path="/declined" element={<DeclinedScreen />} />
      <Route path="/forgot-pin" element={<ForgotPinScreen />} />
    </Routes>
  )
}
