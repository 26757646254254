import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconIneligible: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.2254 27.9911C24.328 27.0034 27.2303 20.2169 27.2303 20.2169C29.9239 14.7385 28.7111 10.9204 26.5626 7.67009C24.4135 4.41823 19.8184 2.16817 15.0172 1.88942C10.6511 1.65527 7.37483 3.60277 7.37483 3.60277C7.37483 3.60277 1.55747 6.76293 2.50729 15.2044C2.49968 15.2775 2.92142 30.9012 18.2254 27.9911Z"
      fill="red"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8968 2.97894C13.6784 3.00876 7.32823 3.9435 4.36598 8.47112C2.61799 11.1434 2.36231 14.5999 3.60715 18.7436C3.61013 18.7541 3.61311 18.7637 3.61609 18.7734C5.07561 24.1687 11.589 26.2432 11.6546 26.2633C17.3607 28.3654 20.6905 26.5384 23.2002 24.4758C25.927 22.2351 27.619 17.6792 27.4111 13.1374C27.2091 9.13156 25.1197 6.25204 25.0981 6.22297C24.9378 5.99413 21.5507 1.30997 14.022 2.95882C13.9803 2.96776 13.9393 2.97447 13.8968 2.97894ZM16.3969 29.447C14.7958 29.447 12.9882 29.1369 10.9375 28.3803C10.6781 28.302 3.2464 25.9309 1.46115 19.3727C-0.0647082 14.2779 0.416827 10.0067 2.89308 6.67692C6.29737 2.10011 12.28 0.967085 13.5435 0.779987L13.5427 0.774769C22.589 -1.20877 26.7469 4.67326 26.9184 4.92447C26.9959 5.02733 29.4073 8.31608 29.6443 13.0301C29.8874 18.3269 27.9157 23.4956 24.6203 26.2037C22.6539 27.819 20.0651 29.4462 16.3969 29.447Z"
      fill="#222222"
    />
    <path
      d="M15 8 L15 18 M15 21 L15 23"
      stroke="#000000"
      style={{ strokeWidth: 2, strokeLinecap: 'round' }}
      fill="none"
    />
  </svg>
)
