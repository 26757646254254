import React, { FunctionComponent, useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import store from 'store/store'
import { FlagCross, FlagLightning, FlagQuestion } from '../icons/flags'
import { calculateTimeDiff } from 'shared/utils/calculateTimeDiff/calculateTimeDiff'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { useNavigate } from 'react-router-dom'
import {
  addCompletedSurvey,
  updateContinueSurveyResponse,
  updateContinueLocalIdentifier,
  updateUserJourney
} from 'store/reducer'

interface InnerModalProps {
  modalState: string
  modalClose: () => void
  modalCloseParent: () => void
}

const modalStyles = {
  button: {
    padding: '15px 15%',
    border: 'none',
    background: '#808080'
  },
  cancelButton: {
    padding: '15px 15%',
    border: 'none',
    background: '#d3d3d3'
  }
}

function FlagIconCustom({ modalState = '' }) {
  if (modalState === 'delete') return <FlagCross />
  if (modalState === 'technical') return <FlagLightning />
  if (modalState === 'incomplete') return <FlagQuestion />
  return <div />
}

const InnerModal: FunctionComponent<InnerModalProps> = ({
  modalState = '',
  modalClose,
  modalCloseParent
}) => {
  const dispatch: Dispatch<any> = useDispatch()
  const [isReasonStep, setIsReasonStep] = useState(false)
  const [reason, setReason] = useState('')
  const requiresStep = ['delete', 'technical'].includes(modalState)
  const navigate = useNavigate()

  useEffect(() => {
    setIsReasonStep(false)
    setReason('')
  }, [modalState])

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReason(event.target.value)
  }

  const handleConfirm = () => {
    if (isReasonStep) {
      if (modalState === 'delete') {
        handleDeletionFlag(reason, dispatch)
      } else if (modalState === 'technical') {
        handleTechnicalFlag(reason, dispatch)
      }
      modalCloseParent()
      navigate('/ra-dashboard/surveys')
    } else if (requiresStep) {
      setIsReasonStep(true)
    } else {
      handleIncompleteFlag(dispatch)
      modalClose()
      modalCloseParent()
      navigate('/ra-dashboard/surveys')
    }
  }

  const handleClose = () => {
    modalClose()
    setIsReasonStep(false)
  }

  const questionText =
    modalState === 'delete'
      ? 'Reason for leaving the study'
      : 'What was the technical problem?'

  return (
    <Modal
      open={!!modalState}
      onClose={modalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        style={{
          width: '40%',
          height: '40%',
          background: 'white',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          padding: '10'
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'space-evenly'
          }}
        >
          {!isReasonStep && (
            <span>Do you really want to flag this survey?</span>
          )}
          {isReasonStep && <span>{questionText}</span>}
          {!isReasonStep ? (
            <>
              <FlagIconCustom modalState={modalState} />
              {modalState}
            </>
          ) : (
            <textarea
              value={reason}
              onChange={handleChange}
              style={{
                border: 'none',
                background: '#d3d3d3',
                resize: 'none',
                padding: '4px',
                width: '80%',
                minHeight: '60%'
              }}
              placeholder="Enter reason..."
            />
          )}

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-evenly'
            }}
          >
            <button style={modalStyles.button} onClick={handleConfirm}>
              {isReasonStep ? 'Confirm' : 'Yes'}
            </button>
            <button style={modalStyles.cancelButton} onClick={handleClose}>
              {isReasonStep ? 'Cancel' : 'No'}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default InnerModal

function handleDeletionFlag(reason: string, dispatch: Dispatch<any>) {
  console.log('Handle deletion:', reason)
  handleSubmit(false, true, false, dispatch, reason)
}

function handleTechnicalFlag(reason: string, dispatch: Dispatch<any>) {
  handleSubmit(true, false, true, dispatch, reason)
}

function handleIncompleteFlag(dispatch: Dispatch<any>) {
  handleSubmit(true, false, false, dispatch)
}

// type statusType = 'deletion' | 'incomplete' | 'complete'
const updateSubmissionMetadata = (
  flagContinue: boolean,
  flagDelete: boolean,
  technical: boolean,
  reason = ''
) => {
  const globalState = store.getState()
  const status = flagDelete
    ? 'deletion'
    : flagContinue
    ? 'incomplete'
    : 'complete'
  const endTime = new Date()

  const startTime = globalState.surveyMetaData?.startTime || new Date()
  const resumeTime = globalState.surveyMetaData?.resumeTime

  const breakPoint =
    globalState.userJourney?.at(-1)?.toString() ||
    globalState.surveyMetaData?.breakPoint
  const prevDuration =
    globalState.surveyMetaData?.duration != null
      ? globalState.surveyMetaData?.duration
      : 0
  const duration =
    (calculateTimeDiff(resumeTime || startTime, new Date()) as number) +
    prevDuration

  return {
    ...globalState.surveyMetaData,
    flaggedIncomplete: flagContinue || flagDelete,
    flaggedDeleted: flagDelete,
    deletionReason: reason ? reason : undefined,
    breakPoint,
    flaggedComingBack: flagContinue,
    status: technical ? 'technical' : status,
    endTime,
    duration
  }
}

export function ExitDashboardConfirm({
  modalState = '',
  modalClose,
  modalCloseParent
}: InnerModalProps) {
  const dispatch: Dispatch<any> = useDispatch()
  const navigate = useNavigate()
  const handleExitToDashboard = () => {
    handleIncompleteFlag(dispatch)
    modalCloseParent()

    navigate('/ra-dashboard/dashboard')
  }

  return (
    <div
      style={{
        width: '35%',
        height: '35%',
        background: 'white',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '10'
      }}
    >
      <div
        style={{
          textAlign: 'center',
          minHeight: '60%',
          alignContent: 'center'
        }}
      >
        <span style={{ color: 'red' }}>Warning: </span>If you exit to the
        dashboard, then this survey will be paused.
        <br />
        This survey will be flagged as <b>Incomplete</b>
        <br />
        You will follow to RA-Dashboard. Continue?
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-evenly'
        }}
      >
        <button style={modalStyles.button} onClick={handleExitToDashboard}>
          Yes
        </button>
        <button style={modalStyles.cancelButton} onClick={modalCloseParent}>
          Cancel
        </button>
      </div>
    </div>
  )
}
// dummy
const addAnalyticsItem = () => {
  return
}

const handleSubmit = (
  flagContinue: boolean,
  flagDelete: boolean,
  flagTechnical: boolean,
  dispatch: Dispatch<any>,
  reason = ''
) => {
  const globalState = store.getState()
  const continueLocalIdentifier = globalState.continueLocalIdentifier
  // Update screen path to empty on submit
  dispatch(updateUserJourney(undefined))
  addAnalyticsItem()

  const surveyData = globalState.surveyData
  const userJourney = globalState.userJourney
  const grogDiaryUserJourney = globalState.grogDiaryUserJourney
  const grogDiaryCurrentState = globalState.grogDiaryCurrentState
  const analytics = globalState.analytics
  const drinkingSessions = globalState.drinkingSessions

  const metadata = updateSubmissionMetadata(
    flagContinue,
    flagDelete,
    flagTechnical,
    reason
  )

  if (!continueLocalIdentifier) {
    dispatch(
      addCompletedSurvey({
        save: true,
        responses: surveyData,
        metadata,
        userJourney: flagContinue ? userJourney : undefined,
        grogDiaryUserJourney: flagContinue ? grogDiaryUserJourney : undefined,
        grogDiaryCurrentState: flagContinue ? grogDiaryCurrentState : undefined,
        analytics,
        drinkingSessions
      })
    )
  } else {
    dispatch(updateContinueLocalIdentifier(continueLocalIdentifier))
    dispatch(
      updateContinueSurveyResponse({
        responses: surveyData,
        metadata,
        userJourney,
        grogDiaryUserJourney,
        grogDiaryCurrentState,
        analytics,
        drinkingSessions
      })
    )
  }
}
