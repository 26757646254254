import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconChevronDown: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    width="57"
    height="56"
    viewBox="0 0 57 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2739 10.1534C6.19931 14.2905 3.57703 20.8209 4.056 30.4001C4.78317 44.9436 16.3178 50.8222 22.2156 51.9938C27.5838 51.8026 35.4412 50.3865 41.8219 46.8282C48.2121 43.2647 52.9195 37.7059 52.5602 29.0833C52.1851 20.0796 48.3327 13.8644 43.3041 9.86534C38.2199 5.82202 31.8693 4 26.5585 4C20.1828 4 14.3697 5.99491 10.2739 10.1534ZM7.42407 7.34658C12.4387 2.25509 19.3755 0 26.5585 0C32.689 0 39.9384 2.07798 45.7939 6.73466C51.705 11.4356 56.1319 18.7204 56.5568 28.9167C56.9975 39.4941 51.0382 46.2687 43.7701 50.3218C36.5788 54.332 27.913 55.8261 22.1182 55.9991L21.9055 56.0055L21.6962 55.9669C14.8604 54.7077 0.925282 47.8858 0.0609864 30.5999C-0.460051 20.1791 2.38835 12.4595 7.42407 7.34658Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.1355 22.849C43.7711 23.7523 43.5542 24.9999 42.6508 25.6356L29.1508 35.1356C28.4497 35.629 27.512 35.6206 26.8198 35.1148L13.8198 25.6148C12.928 24.9631 12.7333 23.7118 13.3851 22.82C14.0368 21.9281 15.2881 21.7335 16.1799 22.3852L28.0218 31.039L40.3489 22.3644C41.2522 21.7287 42.4998 21.9457 43.1355 22.849Z"
      fill="black"
    />
  </svg>
)
