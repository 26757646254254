import React from 'react'
import { ModalPropsModel } from './Modal.model'
import MuiDialog, { DialogProps } from '@mui/material/Dialog'
import styles from './Modal.module.scss'
import classnames from 'classnames'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import { Button } from 'components/Button/Button.component'
import { Icon } from 'components/Icons/Icon.component'
import { IconType } from 'components/Icons/Icon.model'
import { colors } from 'shared/theme/theme'
import { BorderType } from 'components/Border/Border.model'
import { Border } from 'components/Border/Border.component'

export const Modal: React.FC<ModalPropsModel & DialogProps> = (props) => {
  const {
    buttons,
    className,
    children,
    open,
    title,
    titleColor,
    closeIcon,
    style,
    fullWidth,
    invalid,
    hasActions = true,
    customClose,
    variation,
    ...componentProps
  } = props

  const handleClose = (cancel?: boolean) => () => {
    customClose && customClose(cancel)
  }

  const dialogStyle = {
    '--dialog-content-margin-top': variation === 'info' ? '-40px' : '0px',
    ...style
  }

  return (
    <MuiDialog
      className={classnames(className, styles.modal)}
      keepMounted
      open={open}
      disableEscapeKeyDown
      PaperProps={{
        className: classnames('u-border-style-sketch', styles['modal-paper']),
        sx: {
          overflow: closeIcon ? 'visible' : 'hidden',
          ...dialogStyle
        }
      }}
      maxWidth={false}
      fullWidth={fullWidth}
      {...componentProps}
    >
      <Border
        type={BorderType.Modal}
        withTitle={!!title}
        titleColor={titleColor}
        fill={style?.backgroundColor}
      />

      {!title && closeIcon && (
        <IconButton
          className={styles['modal-closeButton--near']}
          onClick={handleClose(true)}
        >
          <Icon icon={IconType.Close} />
        </IconButton>
      )}

      {title && (
        <DialogTitle className={styles['modal-title']}>
          <span style={{ color: titleColor ? colors.white : colors.black }}>
            {title}
          </span>
          {closeIcon && (
            <IconButton
              className={styles['modal-closeButton']}
              onClick={handleClose(true)}
            >
              <Icon icon={IconType.Close} />
            </IconButton>
          )}
        </DialogTitle>
      )}
      <DialogContent
        sx={{ ...style, backgroundColor: 'transparent', zIndex: 999 }}
      >
        {children}
      </DialogContent>
      <DialogActions
        className={
          hasActions && variation !== 'info'
            ? styles['modal-buttons']
            : styles['modal-no-actions']
        }
      >
        {buttons ? (
          <>{buttons}</>
        ) : (
          <>
            <Button variation="secondary" width="l" onClick={handleClose(true)}>
              Cancel
            </Button>
            <Button
              variation="primary"
              width="l"
              disabled={invalid}
              onClick={handleClose()}
            >
              Confirm
            </Button>
          </>
        )}
      </DialogActions>
    </MuiDialog>
  )
}
