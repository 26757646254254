import {
  ETHANOL_CONST,
  STANDARD_DRINK_CONST
} from 'shared/constants/Constants.d'
import {
  AGE,
  GENDER,
  GROG_12_MONTHS,
  GROG_12_MONTHS_FREQUENCY,
  GROG_DIARY,
  GROG_HARMS_LIST,
  GROG_IS_BOSS,
  GROG_LIFE,
  GROG_SHAKES,
  GROG_SPEND_TIME,
  HOW_OFTEN_SINGLE_DAY,
  PREGNANT_WEEKS,
  RISK_TYPE,
  TYPICAL_NUMBER_DRINKS
} from 'shared/constants/SurveyFields.d'
import store from 'store/store'
import type { GrogDiaryData, GrogDiaryDataDetail } from 'store/type.d'
import {
  AlcoholCalcModel,
  RiskLevel,
  ScoreCalc
} from './alcoholCalculation.model'
import type { GrogDiaryConsumptionData } from './alcoholCalculation.model'
import {
  DrinksData,
  RiskLevelData,
  determineRiskCategory
} from './riskLevelHelper'
import { surveyResponseValueToArray } from 'shared/utils/surveyDataConverter/surveyDataConverter'

/**
 * Convert a single consumption data record to parameters which may be used for various
 * alcohol calculation functions.
 *
 * @param diaryDetail the consumption data record to format as parameters to be used for
 * functions in alcohol calculation functions.
 *
 * @returns An object matching the {@link GrogDiaryConsumptionData} interface, which may
 * be used for various alcohol calculation functions.
 */
export const mapDiaryDetailToCalcParams = (
  diaryDetail: GrogDiaryDataDetail
): GrogDiaryConsumptionData => {
  return {
    container: {
      capacity: diaryDetail.container.capacity,
      grog: diaryDetail.container.grog
    },
    drinkAmount: diaryDetail.drinkAmounts.individualAmount,
    productCapacity: diaryDetail.product?.capacity,
    ...(diaryDetail.subContainer && {
      subContainer: {
        amount: diaryDetail.subContainer.amount,
        stepsInEach: diaryDetail.subContainer.stepsInEach
      }
    }),
    alcoholPercentage: diaryDetail.alcoholPercentage
  }
}

/**
 * Calculate the number of standard drinks in a container, based on the number of
 * sub-containers (servings within the container), and "steps per sub-container" (amount
 * of standard drinks per sub-container).
 *
 * @param subContainers - The amount of sub-containers (servings) contained within a
 * container.
 * @param stepsPerSubContainer - The "steps" (number of standard drinks) per
 * sub-container for the container in question.
 *
 * @returns The number of total standard drinks in a container unit.
 */
export const calcStandardDrinksInContainer = (
  subContainers: number,
  stepsPerSubContainer: number
): number => {
  return subContainers * stepsPerSubContainer
}

/**
 * Calculate the maximum number of containers that can be displayed on the screen for a
 * consumption slider, defaulting to a minimum value if the calculated value is lower.
 *
 * @param subContainers - The number of "sub-containers"/servings in a provided container
 * from the Grog Shop.
 * @param stepsPerSubContainer - The number of "steps"/standard drinks contained within
 * each sub-container in a provided container from the Grog Shop.
 * @param [minContainers=10] - The minimum number of containers that can be displayed on
 * the current screen. If the calculated maximum number of containers is below this value,
 * this function returns this value. Defaults to 10.
 * @param [maxStandardDrinks=200] - The maximum number of standard drinks a user can
 * consume. Defaults to 200.
 *
 * @returns The maximum number of containers that can be displayed on the screen for a
 * consumption slider.
 */
export const calcMaxDisplayContainers = (
  subContainers: number,
  stepsPerSubContainer: number,
  minContainers = 10,
  maxStandardDrinks = 200
): number => {
  const standardDrinksInUnit = calcStandardDrinksInContainer(
    subContainers,
    stepsPerSubContainer
  )
  const calculatedMax = Math.ceil(maxStandardDrinks / standardDrinksInUnit)

  return Math.max(calculatedMax, minContainers)
}

/**
 * Calculate the ratio of alcoholic drink contained within a consumption.
 *
 * For consumptions in non vessel-based containers, this will always be 1, otherwise, it
 * will be the percentage of alcoholic drink in the container (as this can be mixed with
 * other types of drinks, such as fizzy drink/juice).
 *
 * @param consumption - The consumption data to calculate the ratio for.
 *
 * @returns The ratio of alcoholic drink contained within a consumption container.
 */
export const calcAlcoholRatio = (consumption: {
  container: { grog?: number }
}): number => {
  // If the consumption uses a custom (vessel-based) container, the ratio is the container
  // / 100 (as it is a percentage), otherwise, the ratio is 1
  return consumption.container.grog ? consumption.container.grog / 100 : 1
}

/**
 * Determine whether an alcohol product would be a multi-pack (e.g. case), depending on
 * whether a provided number sub-containers is greater than 1.
 *
 * @param numSubContainers - A number of sub-containers for an alcohol product provided by
 * the Grog Shop.
 *
 * @returns `true` if `numSubContainers` is greater than 1, `false` otherwise (e.g.
 * product is a single bottle, which contains multiple standard drinks).
 */
export const productIsPack = (numSubContainers: number): boolean => {
  return numSubContainers > 1
}

/**
 * Calculate the number of serving steps that should be used when collecting information
 * about consumption data for an alcohol product, such as the in the Grog Slider.
 *
 * @param {Object} product - Data about the alcohol product.
 * @param product.subContainer - The number of sub-containers the alcohol product
 * contains.
 * @param product.stepsPerSubContainer - The number of steps per sub-container the alcohol
 * product contains.
 *
 * @returns How many serving steps should be utilised when collecting consumption
 * information about the alcohol product.
 */
export const calcNumStepsPerProductImage = ({
  subContainer,
  stepsPerSubContainer
}: {
  subContainer: number
  stepsPerSubContainer: number
}): number => {
  return productIsPack(subContainer) ? subContainer : stepsPerSubContainer
}

// 'high risk'|'risky'|'low risk'|'not drinking'
export type AlcoholRiskLevel = keyof typeof RiskLevel

export const AlcoholCalc = (): AlcoholCalcModel => {
  const state = store.getState()
  const storeData = state.surveyData
  const demoMode = state.user?.id === 'demo'
  const grogDiary: { [key: string]: GrogDiaryData } | undefined = storeData
    ? typeof storeData[GROG_DIARY] === 'string'
      ? JSON.parse(storeData[GROG_DIARY])
      : storeData[GROG_DIARY]
    : undefined
  const screen = storeData?.screens?.find(
    (item: any) => item?.surveyField === GROG_DIARY
  )
  // const testProjects = state.service?.projects
  // state.surveyMetaData.surveyId
  // const surveyId = state.survey.id // 31220
  // state.survey?.screens[0].

  const latestSession = grogDiary
    ? grogDiary[Object.keys(grogDiary)[0]]
    : undefined

  const formatToRiskLevelData = (): RiskLevelData => {
    const storeData = store.getState().surveyData
    if (!storeData || !grogDiary) return {} as RiskLevelData // no data no fun lmao

    const grog12MonthsSpecialEvent = storeData[GROG_12_MONTHS] || ''
    const totalStandardDrinks = Object.keys(grogDiary).map((key) =>
      calcTotalStandardDrinks(grogDiary[key])
    )
    // console.log('B4 SCREEN')
    // "sectionId": "31268",
    // data.grogDiary.groupStdDrinksThreshold
    // data.grogDiary.otherDayDiary
    // data.grogDiary.groupStdDrinksThreshold

    //console.log('storeData?.screens', storeData?.screens)
    const requiredDiaryOccasions =
      (screen?.data.grogDiary.requireDiary as number) || 2
    const requestedOccasions =
      requiredDiaryOccasions +
      ((screen?.data.grogDiary.otherDayDiary as number) || 2)
    //console.log({ requiredDiaryOccasions, requestedOccasions })
    //console.log('grogDiary', grogDiary)
    //  TODO: GET FROM SURVEY META DATA
    const surveyDate = new Date() // Assume this is the current date
    const gender = storeData[GENDER] || 'male' // Default to 'male' if not provided
    const auditC = {
      grog12Months: Number(ScoreCalc[storeData[GROG_12_MONTHS_FREQUENCY]] || 0),
      typicalNumberDrinks: Number(storeData[TYPICAL_NUMBER_DRINKS]?.value || 0),
      howOftenSingleDay: Number(
        ScoreCalc[storeData[HOW_OFTEN_SINGLE_DAY]?.value ?? 0] || 0
      )
    }
    //const grogGiveProblems = storeData[GROG_HARMS] || ''
    const grogGiveProblems = !!GROG_HARMS_LIST.find(
      (harm) =>
        storeData[harm] &&
        surveyResponseValueToArray(storeData[harm]).includes('grog')
    )
    const pregnantHowManyWeeks = storeData[PREGNANT_WEEKS]
      ? Number(storeData[PREGNANT_WEEKS])
      : undefined
    const age = storeData[AGE] ? Number(storeData[AGE]) : 0
    const grogIsBoss = Number(ScoreCalc[storeData[GROG_IS_BOSS]] || 0)
    const grogShakes = Number(ScoreCalc[storeData[GROG_SHAKES]] || 0)
    const spendTimeDrinking = Number(ScoreCalc[storeData[GROG_SPEND_TIME]] || 0)
    const drinks: DrinksData = {
      drinkingDate: Object.keys(grogDiary).map((key) => new Date(key)),
      totalStandardDrinks,
      surveyDate
    }

    return {
      grog12MonthsSpecialEvent,
      totalStandardDrinks,
      surveyDate,
      gender,
      auditC,
      grogGiveProblems,
      pregnantHowManyWeeks,
      age,
      grogIsBoss,
      grogShakes,
      spendTimeDrinking,
      drinks,
      requestedOccasions
    }
  }

  const calcAlcoholRiskLevel = (): AlcoholRiskLevel => {
    /** Demo Mode */
    if (demoMode && storeData && storeData[RISK_TYPE]) {
      return storeData[RISK_TYPE] as keyof typeof RiskLevel
    }
    /** Ends Demo Mode */

    // Not Drinking conditions
    if (
      !storeData ||
      !grogDiary ||
      !latestSession ||
      latestSession.consumptions.length === 0 ||
      storeData[GROG_LIFE]
    ) {
      return 'not drinking'
    }

    // user is some category of a drinker now
    const riskLevelData = formatToRiskLevelData()
    return determineRiskCategory(riskLevelData)
  }

  const capacityOfDrink = (consumption: GrogDiaryConsumptionData): number => {
    // Default values setup
    const { drinkAmount = 1, productCapacity = 0 } = consumption

    const subContainerAmount = consumption.subContainer?.amount ?? 1
    const container = {
      capacity: consumption.container.capacity ?? 0,
      grog: consumption.container.grog ?? 0
    }

    // Calculation ratio (percentage) of alcohol in drink
    const alcoholRatio = calcAlcoholRatio(consumption)

    let drinkCapacity

    if (container.capacity > 0) {
      // Calculate capacity for containers if applicable
      drinkCapacity = container.capacity * drinkAmount
    } else {
      // Calculate capacity for products if container capacity not used, otherwise, it
      // will default to 0 (due to `productCapacity`'s default value being 0)
      drinkCapacity = productCapacity * (drinkAmount / subContainerAmount)
    }

    // Calculate and return capacity of alcohol in current container
    return drinkCapacity * alcoholRatio
  }

  const calcGramsOfDrink = (consumption: GrogDiaryConsumptionData): number => {
    const capacity = capacityOfDrink(consumption)

    // Grams alcohol is capacity (ml) * ratio of alcohol * ethanol conversion constant (0.789)
    const gramsAlcohol =
      capacity *
      ((consumption.alcoholPercentage != null
        ? consumption.alcoholPercentage
        : 0) /
        100) *
      ETHANOL_CONST
    return gramsAlcohol
  }

  const calcStandardDrinks = (
    consumption: GrogDiaryConsumptionData
  ): number => {
    const gramsAlcohol = calcGramsOfDrink(consumption)

    return gramsAlcohol / STANDARD_DRINK_CONST
  }

  const calcTotalStandardDrinks = (session?: GrogDiaryData): number => {
    let standardDrinks = 0
    const setSession = session || latestSession

    if (
      !grogDiary ||
      !setSession ||
      !setSession.consumptions ||
      setSession.consumptions.length === 0
    )
      return standardDrinks

    setSession.consumptions.forEach((consumption) => {
      const elemCalcData = mapDiaryDetailToCalcParams(consumption)
      const stdDrinks = calcStandardDrinks(elemCalcData)
      standardDrinks += stdDrinks
    })

    return standardDrinks
  }

  const getConsumptions = (session?: GrogDiaryData): GrogDiaryDataDetail[] => {
    const setSession = session || latestSession

    if (
      !grogDiary ||
      !setSession ||
      !setSession.consumptions ||
      setSession.consumptions.length === 0
    )
      return []

    return setSession.consumptions
  }

  return {
    calcAlcoholRiskLevel,
    calcTotalStandardDrinks,
    calcStandardDrinks,
    getConsumptions,
    capacityOfDrink,
    calcGramsOfDrink
  }
}
