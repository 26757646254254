import React from 'react'
import { IconPropsModel } from '../../Icon.model'

export const IconArrowLeft: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="50"
    height="51"
    viewBox="0 0 50 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="path-1-inside-1_3091_3930" fill={props.fill || 'white'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.4638 17.7527C45.4123 6.47673 32.0891 2.35086 32.0891 2.35086C21.2651 -1.63885 14.3267 1.38816 8.63859 6.06277C2.94769 10.7387 -0.381363 19.8157 0.0349426 28.8939C0.449861 37.1457 4.75169 42.9219 4.75169 42.9219C4.75169 42.9219 11.8303 53.2365 27.5097 49.7983C27.6485 49.7983 56.9301 45.9461 48.4638 17.7527Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.4638 17.7527C45.4123 6.47673 32.0891 2.35086 32.0891 2.35086C21.2651 -1.63885 14.3267 1.38816 8.63859 6.06277C2.94769 10.7387 -0.381363 19.8157 0.0349426 28.8939C0.449861 37.1457 4.75169 42.9219 4.75169 42.9219C4.75169 42.9219 11.8303 53.2365 27.5097 49.7983C27.6485 49.7983 56.9301 45.9461 48.4638 17.7527Z"
      fill={props.fill || 'white'}
      stroke={props.stroke || '#222222'}
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
      mask="url(#path-1-inside-1_3091_3930)"
    />
    <path
      d="M27.5109 36.5928C27.5109 36.5928 23.6254 32.6058 22.3765 31.2305C21.5439 30.404 20.4337 28.7536 19.4624 27.5159C18.491 26.417 18.491 24.7653 19.3236 23.6664C20.1562 22.4273 21.4051 20.9159 22.7928 19.8143C25.4294 17.7513 28.6211 14.3131 28.6211 14.3131"
      stroke={props.stroke || '#222222'}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
