import React, { useEffect, useState } from 'react'
import type { GrogSliderPropsModel, GrogSliderValue } from './GrogSlider.model'
import MuiSlider, { SliderThumb } from '@mui/material/Slider'
import styles from './GrogSlider.module.scss'
import { styled } from '@mui/material/styles'
import { Icon } from 'components/Icons/Icon.component'
import { AnimatedIcon } from 'components/AnimatedIcon/AnimatedIcon.component'
import { colors } from 'shared/theme/theme'
import classnames from 'classnames'
import { IconType } from 'components/Icons/Icon.model'
import { Button } from 'components/Button/Button.component'
import { Container } from 'components/GrogConsumptions/Container/Container.component'
import { GrogImage } from 'components/GrogConsumptions/GrogImage/GrogImage.component'
import { OptionalButtons } from 'components/OptionalButtons/OptionalButtons.component'
import { useColourPicker } from 'context/ColourContext'

const CustomSlider = ({ customColor = colors.black, ...props }) => (
  <MuiSlider {...props} />
)

const IconSlider = styled(CustomSlider)(
  ({ customColor = colors.black, orientation }) => ({
    color: customColor,
    width: orientation === 'vertical' ? 10 : undefined,
    height: orientation === 'horizontal' ? 10 : undefined,
    '& .MuiSlider-valueLabel': {
      backgroundColor: customColor
    }
  })
)
/**
 * Generate slider thumb component
 * @param thumbProps Html attributes for slider thumb
 * @returns {JSX.Element} Slider thumb component
 */
const SliderThumbComponent =
  /*(color = colors.black) =>*/
  (thumbProps: React.HTMLAttributes<HTMLElement>) => {
    const { children, ...other } = thumbProps
    return (
      <SliderThumb {...other}>
        {children}
        <Icon
          icon={IconType.Hand}
          size={47}
          stroke={colors.white}
          //fill={color}
        />
      </SliderThumb>
    )
  }

const calcPercent = (val: number, max: number): number =>
  Math.floor((val / max) * 100)

const getAriaDescription = (grogValue = 0, fizzyValue = 0, max = 0): string => {
  let desc = `${calcPercent(grogValue, max)}% container filled with grog`

  if (fizzyValue) {
    desc += `, ${calcPercent(
      fizzyValue - grogValue,
      max
    )}% filled with fizzy drink or juice`
  }
  return desc
}

export const GrogSlider = <TIsVertical extends boolean>(
  props: GrogSliderPropsModel<TIsVertical>
): React.JSX.Element => {
  const {
    className,
    vertical,
    title,
    subtitle,
    drinkName = 'drink',
    image,
    fillMaskImage,
    icon = IconType.Hand,
    min = 0,
    max = 100,
    defaultValue,
    setValue,
    onOptionalButtonSelect,
    subContainer = 1,
    stepsPerSubContainer = 1,
    topBorder = 0,
    bottomBorder = 10,
    drinkColor,
    addFizzyDrink = false,
    customComponent,
    isShare = false,
    shareMax,
    ...componentProps
  } = props

  const { primaryColour, secondaryColour } = useColourPicker()

  const smallScreen = window.outerHeight < 800

  // A state value which tracks if a user has manually touched the slider
  const [userMovedSlider, setUserMovedSlider] = useState<boolean>(false)

  // The "range value" (internally tracked value by slider) is set to the `defaultValue`
  // prop, otherwise defaulting to `min` if it was `undefined`
  const [rangeValue, setRangeValue] = useState<number | readonly number[]>(
    defaultValue ?? min
  )
  const [fizzy, setFizzy] = useState<boolean>(false)

  const drinkValue = fizzy
    ? (rangeValue as number[])[0]
    : Number(rangeValue) || 0
  const fizzyDrinkValue = fizzy ? (rangeValue as number[])[1] : 0

  /**
   * Format prefix or suffix to number
   * @param value Numeric value for slider label
   * @returns {string}
   */
  const handleLabelFormat = (value: number) => {
    return value.toString()
  }

  useEffect(() => {
    let newValue

    if (fizzy) {
      newValue = [
        rangeValue as number,
        (rangeValue as number) + 20 > max ? max : (rangeValue as number) + 20
      ]
    } else if (Array.isArray(rangeValue)) {
      // If `rangeValue` is an array, set `newValue` to the first index
      newValue = rangeValue[0]
    } else {
      // `rangeValue` is a number OR `undefined`, so set `newValue` to this
      newValue = rangeValue
    }

    if (userMovedSlider) {
      setValue(newValue)
    }

    setRangeValue(newValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fizzy])

  // Get "max value" of slider
  let drinkMax
  if (vertical) {
    // Slider is vertical
    drinkMax = max
  } else if (shareMax != null && shareMax !== 0) {
    drinkMax = shareMax
  } else {
    let sliderMaxMultiplier
    if (customComponent) {
      // Due to the nature of vessel-based allowing variable drink sizes, the
      // multipler should take into account the "amount of sub-containers" in the vessel
      // as well as the "steps per sub-container" of the vessel
      sliderMaxMultiplier = subContainer * stepsPerSubContainer
    } else if (subContainer === 1) {
      // If there is only one sub container, the max drinks to select is the maximum
      // number of containers to display * the steps within the container (as the
      // container could contain multiple standard drinks, and hence multiple increments
      // on the slider)
      sliderMaxMultiplier = stepsPerSubContainer
    } else {
      // If there is > 1 sub-container, the max drinks to select is the maximum number of
      // containers to display * the number of sub-containers (as the slider is based on
      // steps, so we select one subcontainer at a time)
      sliderMaxMultiplier = subContainer
    }

    drinkMax = max * sliderMaxMultiplier
  }

  const handleValueChange = (
    _event: Event | React.SyntheticEvent<Element, Event>,
    value: GrogSliderValue<TIsVertical>
  ) => {
    if (!userMovedSlider) {
      // If this is the first time the user has touched the slider, set this state
      // variable to `true`
      setUserMovedSlider(true)
    }

    setValue(value)
    setRangeValue(value)
  }

  const fizzyString = fizzy
    ? 'Remove fizzy drink or juice'
    : 'Add fizzy drink or juice'

  return (
    <div
      className={classnames(styles['grog-slider__wrapper'], className)}
      {...componentProps}
    >
      {title && <h2 dangerouslySetInnerHTML={{ __html: title }} />}
      {subtitle && <p>{subtitle}</p>}
      <div
        className={classnames(styles.sliderAlign, {
          [styles['sliderAlign--inline']]: vertical,
          [styles['sliderAlign--middle']]: !(title || subtitle || image),
          [styles['grog-slider__dynamic--vertical']]: vertical,
          [styles['grog-slider__dynamic--horizontal']]: !vertical
        })}
      >
        {vertical && fillMaskImage ? (
          <Container
            image={image || ''}
            fillMaskImage={fillMaskImage}
            min={min}
            max={max}
            height={smallScreen ? 380 : 440}
            topBorder={topBorder}
            bottomBorder={bottomBorder}
            drinkValue={drinkValue}
            fizzyDrinkValue={fizzyDrinkValue}
            drinkColor={drinkColor}
          />
        ) : (
          <GrogImage
            min={min}
            maxImages={max}
            maxDrinks={drinkMax}
            image={image}
            rangeValue={rangeValue}
            subContainer={subContainer}
            stepsPerSubContainer={stepsPerSubContainer}
            customComponent={customComponent}
            isShare={isShare}
          />
        )}

        <div
          className={classnames(
            styles[
              `grog-slider__inner-wrapper${
                vertical ? '-vertical' : '-horizontal'
              }`
            ]
          )}
        >
          <IconSlider
            customColor={secondaryColour}
            sx={{
              '& .MuiSlider-thumb': {
                'svg': {
                  'path': {
                    fill: secondaryColour
                  }
                }
              }
            }}
            className={classnames(styles.slider, styles['grog-slider--round'])}
            components={{ Thumb: SliderThumbComponent }}
            valueLabelFormat={handleLabelFormat}
            valueLabelDisplay={vertical ? 'off' : 'on'}
            defaultValue={rangeValue ? rangeValue : 0}
            value={rangeValue ? rangeValue : 0}
            orientation={vertical ? 'vertical' : 'horizontal'}
            min={min}
            max={drinkMax}
            aria-label={getAriaDescription(drinkValue, fizzyDrinkValue, max)}
            onChange={handleValueChange}
          />
          {!!vertical && !!addFizzyDrink && (
            <div className={classnames(styles['grog-slider__dynamic-button'])}>
              <Icon
                className={classnames(
                  styles['grog-slider__dynamic-icon'],
                  fizzy && styles['grog-slider__dynamic-icon--fizzy']
                )}
                icon={IconType.Plus}
                size={36}
                bgColor={primaryColour}
              />
              <AnimatedIcon variation="prompt" deltaY={-80}>
                <Button
                  variation="secondary"
                  width="m"
                  onClick={() => setFizzy(!fizzy)}
                >
                  {fizzyString}
                </Button>
              </AnimatedIcon>
            </div>
          )}
        </div>
      </div>

      <OptionalButtons
        className={classnames(styles['grog-slider__optional-section'])}
        optionalButtons={[
          {
            id: 'skip-slider',
            label: `I didn't have any ${drinkName}`,
            value: null
          }
        ]}
        onButtonSelect={onOptionalButtonSelect}
      />
    </div>
  )
}
