import { GeoLocation, ProjectDataModel } from 'api/client.model'
import { GenerateOptions } from './generateOptions.model'

type statOptionsT = { label: string; value: string }[]

export const generateOptions = (): GenerateOptions => {
  const generateProjectOptions = (
    projects: ProjectDataModel[],
    projectId?: string
  ) => {
    let finalProjects = projects
    if (projectId) {
      finalProjects = projects.filter((item) => item.id === projectId)
    }
    return finalProjects.map((item) => ({
      label: item.name || `Project - ${item.id}`,
      value: item.id
    }))
  }

  const generateSiteOptions = (
    projects: ProjectDataModel[],
    projectId: string
  ) => {
    const project = projects.find((item) => item.id === projectId)
    if (project && project.sites && project.sites.length > 0) {
      return project.sites.map((item) => ({
        label: item.name || item.location || `Site - ${item.id}`,
        value: item.id
      }))
    }
    return []
  }

  const generateStateOptions = (geoLocations: GeoLocation[]) => {
    const seenStates = new Set()
    const states: statOptionsT = geoLocations.reduce((acc, item) => {
      if (!seenStates.has(item.state)) {
        seenStates.add(item.state)
        acc.push({ label: item.state, value: item.state })
      }
      return acc
    }, [] as statOptionsT)

    return states
  }

  return {
    generateProjectOptions,
    generateSiteOptions,
    generateStateOptions
  }
}
