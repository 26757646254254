import React from 'react'
import { IconPropsModel } from '../../Icon.model'

export const IconBigSmile: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="87"
    height="87"
    viewBox="0 0 87 87"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.56229 55.95C10.4891 74.1555 31.9998 80.8168 31.9998 80.8168C49.4754 87.2584 60.6778 82.3711 69.8615 74.8238C79.0496 67.2743 84.4245 52.6193 83.7523 37.9621C83.0825 24.6394 76.137 15.3135 76.137 15.3135C76.137 15.3135 64.7084 -1.33988 39.3933 4.21125C39.1693 4.21125 -8.10679 10.4307 5.56229 55.95Z"
      fill={props.fill || '#C57861'}
      stroke="#222222"
      strokeWidth="5.04542"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.8746 52.1419C28.1711 54.1582 30.7093 59.2413 33.6774 60.9629C35.879 63.0687 38.0725 63.6564 42.5886 64.8665C46.8045 64.616 51.7044 63.2068 54.2351 61.1421C58.3058 57.8209 62.1448 52.2677 62.1448 52.2677"
      stroke="#222222"
      strokeWidth="5.04542"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.0738 34.5235C25.5357 36.2303 27.5523 36.8548 27.5523 36.8548C29.1907 37.4587 30.2409 37.0005 31.1019 36.293C31.9633 35.5852 32.4672 34.2113 32.4042 32.8372C32.3413 31.5882 31.6902 30.7139 31.6902 30.7139C31.6902 30.7139 30.6188 29.1526 28.2455 29.673C28.2245 29.673 23.7924 30.2561 25.0738 34.5235Z"
      fill="#222222"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.5312 34.1105C57.0316 35.675 59.2163 36.2475 59.2163 36.2475C60.9911 36.8011 62.1289 36.3811 63.0616 35.7325C63.9948 35.0837 64.5406 33.8243 64.4724 32.5647C64.4043 31.4197 63.6989 30.6183 63.6989 30.6183C63.6989 30.6183 62.5382 29.1871 59.9672 29.6642C59.9444 29.6642 55.1429 30.1987 56.5312 34.1105Z"
      fill="#222222"
    />
  </svg>
)
