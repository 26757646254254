import type {
  GrogBrandModel,
  GrogContainerModel,
  GrogProductModel,
  GrogSubTypeModel,
  GrogTypeCategoryModel
} from 'api/client.model'
import { GrogDiaryStep } from 'screens/survey/GrogDiaryScreen/GrogDiaryScreen.model'
import type { DeepReadonly } from 'shared/types/general'
import type {
  GrogDiaryData,
  GrogDiaryUserJourneyData,
  SurveyGrogDiaryCurrentState
} from 'store/type'

export interface GrogDiary {
  [isoDateString: string]: GrogDiaryData
}

interface GrogConsumptionReactStateValue<T> {
  readonly value: T
  readonly set: React.Dispatch<React.SetStateAction<T>>
}

interface ReadonlyGrogConsumptionStateValue<T> {
  readonly value: DeepReadonly<T>
  readonly set: (value: DeepReadonly<T>) => void
}

type MockReactDeepReadonlySetState<T> = (value: DeepReadonly<T>) => void

export interface GrogConsumptionStateHookProps {
  name: string
  groupStdDrinksThreshold?: number
  typesCategories: DeepReadonly<(GrogTypeCategoryModel | GrogSubTypeModel)[]>
  grogDiaryUserJourney: readonly GrogDiaryUserJourneyData[]
  grogDiaryCurrentState: DeepReadonly<SurveyGrogDiaryCurrentState>

  date: ReadonlyGrogConsumptionStateValue<Date | undefined>
  people: ReadonlyGrogConsumptionStateValue<number | undefined>
  step: GrogConsumptionReactStateValue<GrogDiaryStep> & {
    readonly stepForward: (value: GrogDiaryStep) => void
    readonly stepBackward: () => void
  }

  diary: DeepReadonly<GrogDiary> | undefined
}

export enum GrogConsumptionTransitionStep {
  NEXT,
  BACK
}

export interface CurrentGrogConsumptionState {
  readonly curTypeCat:
    | DeepReadonly<GrogTypeCategoryModel>
    | DeepReadonly<GrogSubTypeModel>
    | undefined
  readonly setCurTypeCat: (
    value:
      | DeepReadonly<GrogTypeCategoryModel>
      | DeepReadonly<GrogSubTypeModel>
      | undefined
  ) => void

  readonly curSubType: DeepReadonly<GrogSubTypeModel> | undefined
  readonly setCurSubType: (
    value: DeepReadonly<GrogSubTypeModel> | undefined
  ) => void

  readonly curBrand: DeepReadonly<GrogBrandModel> | undefined
  readonly setCurBrand: (
    value: DeepReadonly<GrogBrandModel> | undefined
  ) => void

  readonly curProduct: DeepReadonly<GrogProductModel> | undefined
  readonly setCurProduct: (
    value: DeepReadonly<GrogProductModel> | undefined
  ) => void

  readonly curContainer: DeepReadonly<GrogContainerModel> | undefined
  readonly setCurContainer: (
    value: DeepReadonly<GrogContainerModel> | undefined
  ) => void

  readonly curDrinkAmount: number | undefined
  readonly setCurDrinkAmount: (value: number | undefined) => void

  readonly curContainerAmount: number | readonly number[] | undefined
  readonly setCurContainerAmount: (
    value: number | readonly number[] | undefined
  ) => void

  readonly curIsGroup: boolean | undefined
  readonly setCurIsGroup: (value: boolean | undefined) => void

  readonly curShare: number | undefined
  readonly setCurShare: (value: number | undefined) => void

  readonly curMore: boolean | undefined
  readonly setCurMore: (value: boolean | undefined) => void
}

export type CurrentGrogDiarySelectionsState = DeepReadonly<{
  activeTypesCats: DeepReadonly<(GrogTypeCategoryModel | GrogSubTypeModel)[]>
  setActiveTypesCats: MockReactDeepReadonlySetState<
    (GrogTypeCategoryModel | GrogSubTypeModel)[]
  >

  activeSubTypes: DeepReadonly<GrogSubTypeModel[]>
  setActiveSubTypes: MockReactDeepReadonlySetState<GrogSubTypeModel[]>

  activeBrands: DeepReadonly<GrogBrandModel[]>
  setActiveBrands: MockReactDeepReadonlySetState<GrogBrandModel[]>
}>

export interface GrogConsumptionState {
  readonly current: {
    readonly typeCat: ReadonlyGrogConsumptionStateValue<
      GrogTypeCategoryModel | GrogSubTypeModel | undefined
    >
    readonly subType: ReadonlyGrogConsumptionStateValue<
      GrogSubTypeModel | undefined
    >
    readonly brand: ReadonlyGrogConsumptionStateValue<
      GrogBrandModel | undefined
    >
    readonly product: ReadonlyGrogConsumptionStateValue<
      GrogProductModel | undefined
    >
    readonly container: ReadonlyGrogConsumptionStateValue<
      GrogContainerModel | undefined
    >
    readonly drinkAmount: ReadonlyGrogConsumptionStateValue<number | undefined>
    readonly containerAmount: ReadonlyGrogConsumptionStateValue<
      number | number[] | undefined
    >
    readonly isGroup: ReadonlyGrogConsumptionStateValue<boolean | undefined>
    readonly share: ReadonlyGrogConsumptionStateValue<number | undefined>
    readonly more: ReadonlyGrogConsumptionStateValue<boolean | undefined>

    readonly selections: {
      readonly activeTypesCats: ReadonlyGrogConsumptionStateValue<
        (GrogTypeCategoryModel | GrogSubTypeModel)[]
      >
      readonly activeSubTypes: ReadonlyGrogConsumptionStateValue<
        GrogSubTypeModel[]
      >
      readonly activeBrands: ReadonlyGrogConsumptionStateValue<GrogBrandModel[]>
    }

    readonly audioFragmentsKeys: {
      readonly whatTypes: string | null
      readonly howMuch: string | null
    }
  }

  /**
   * Transition the Grog Diary to the next appropriate step, depending on the current
   * consumption state of the Grog Diary, and whether the user is navigating backwards or
   * forwards.
   *
   * @param direction - Whether the user is navigating backwards or forwards in the Grog
   * Diary.
   */
  readonly transition: (direction: GrogConsumptionTransitionStep) => void

  /**
   * Skips the current step, recording the current consumption state simultaneously for
   * this step.
   */
  readonly skipStep: () => void

  /**
   * Resets all consumption data for the current Grog Diary state for the current user.
   *
   * If `newDate` is set to `true`, the date and people within the user's drinking circle
   * will also be reset.
   *
   * @param [newDate=false] Whether to reset the data and people in the user's drinking
   * circle for the Grog Diary state of the current user. Defaults to `false`.
   */
  readonly resetState: (newDate?: boolean) => void

  /**
   * Reset any selections present for product/containers, container alcohol and fizzy
   * drink/juice amounts, individual consumption amounts, whether the user was drinking in
   * a group or not, and any group share consumption data.
   */
  readonly resetProductConsumptionState: () => void

  /**
   * Extract the consumption record that was most recently added to the Grog Diary survey
   * data for the current date, and restore information stored in this consumption to
   * state variables in the React component.
   *
   * Optionally, also removes ("pops") this most recent added consumption record from the
   * saved diary data.
   *
   * @param [removeStoredConsumption=false] Whether to remove the most recent consumption
   * record from the saved diary data. Defaults to `false`.
   *
   * @returns `true` if a consumption was successfully popped for the current date, and
   * `false` if there was no existing consumption for this date which was popped.
   */
  readonly popFromDiary: (removeStoredConsumption?: boolean) => boolean

  /**
   * Delete the most-recently added consumption from the user's Grog Diary data stored in
   * the Redux store from a specified date.
   *
   * If a specified date is not provided, or is `undefined`, the function will delete the
   * consumption from the most recently-added consumption data.
   *
   * @param fromDate - The date that the most recent consumption should be removed from.
   */
  readonly deleteConsumption: (fromDate?: Date) => void

  /**
   * Navigate back to a previous page which contextually has been a slider or selection
   * screen, ensuring the selections which will be made on this current page are cleared.
   */
  readonly goBackToPreviousConsumptionScreen: () => void

  /**
   * If a container is selected, return the amount of alcohol within that container,
   * otherwise (taking into account mixers if required), otherwise, return `undefined` (as
   * it is unset).
   *
   * @returns If a container is selected, the amount of alcohol within it, otherwise
   * `undefined`.
   */
  readonly getContainerAlcoholAmount: () => number | undefined

  /**
   * Get the percentage of alcohol for the currently selected consumption of alcohol, or
   * return `undefined` if a brand/subtype is not currently selected.
   *
   * @returns The percentage of alcohol for the currently selected brand/subtype, or
   * `undefined` if this is not yet selected.
   */
  readonly getAlcoholPercentage: () => number | undefined

  /**
   * Return the number of consumptions for the current date in the Grog Diary, or `null`
   * if the current date has not been set yet, or the `diary` passed to the
   * {@link useGrogConsumptionState} hook is `undefined`.
   *
   * @returns The number of consumptions for the current date in the Grog Diary, or `null`
   * if the current date is not set, or the `diary` data is `undefined`.
   */
  readonly numConsumptionsCurrentDate: () => number | null
}

export interface GrogConsumptionData {
  readonly date: Date
  readonly people: number | undefined

  readonly typeCat:
    | DeepReadonly<GrogTypeCategoryModel>
    | DeepReadonly<GrogSubTypeModel>
    | undefined
  readonly subType: DeepReadonly<GrogSubTypeModel> | undefined
  readonly brand: DeepReadonly<GrogBrandModel> | undefined
  readonly product: DeepReadonly<GrogProductModel> | undefined
  readonly container: DeepReadonly<GrogContainerModel> | undefined
  readonly drinkAmount: number | undefined
  readonly containerAmount: number | readonly number[] | undefined
  readonly isGroup: boolean | undefined
  readonly share: number | undefined
}

export interface GrogConsumptionTransitionState {
  step?: GrogDiaryStep

  selectedTypesCats?: DeepReadonly<(GrogTypeCategoryModel | GrogSubTypeModel)[]>
  selectedSubTypes?: DeepReadonly<GrogSubTypeModel[]>
  selectedBrands?: DeepReadonly<GrogBrandModel[]>

  itemWasAddedToDiary?: boolean
}
