import React, { useEffect, useState } from 'react'
import {
  CalendarDatePropsModel,
  MonthLength,
  Months
} from './CalendarDate.model'
import styles from './CalendarDate.module.scss'
import classnames from 'classnames'
import { MultipleChoice } from 'components/MultipleChoice/MultipleChoice.component'
import { MultipleChoiceModel } from 'components/MultipleChoice/MultipleChoice.model'
import { BorderType } from 'components/Border/Border.model'

export const CalendarDate: React.FC<CalendarDatePropsModel> = (props) => {
  const { className, style, title, subtitle, setValue, ...componentProps } =
    props

  const [month, setMonth] = useState<string>()
  const [dateLength, setDateLength] = useState<number>(31)
  const [date, setDate] = useState<string>()

  const generateOptions = (values: string[]) => {
    return values.map(
      (value) =>
        ({
          id: value,
          isActive: 1,
          label: value,
          value,
          selected: value === date || value === month
        } as MultipleChoiceModel)
    )
  }

  const handleMonthValue = (value: string[]) => {
    if (value.length > 0) {
      setMonth(value[0])
      const length = MonthLength[Months.findIndex((name) => name === value[0])]
      setDateLength(length)

      if (Number(date) > length) {
        setDate(undefined)
        setValue(value[0], undefined)
      } else {
        setValue(value[0], date)
      }
    }
  }

  const handleDateValue = (value: string[]) => {
    if (value.length > 0) {
      setDate(value[0])
      setValue(month, value[0])
    }
  }

  useEffect(() => {
    setDate(undefined)
    setValue(month, undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month])

  return (
    <div
      className={classnames(className, styles.calendardate)}
      {...componentProps}
    >
      {title && (
        <h1
          className={classnames(
            'screen-titles--title',
            styles['calendardate-title']
          )}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}
      {subtitle && (
        <p
          className={classnames(
            'screen-titles--subtitle',
            styles['calendardate-subtitle']
          )}
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      )}
      <div className={styles['calendardate-wrapper']}>
        <MultipleChoice
          className={styles['calendardate-month']}
          columnNum={2}
          leftAligned
          withTick={false}
          options={generateOptions(Months)}
          setValue={handleMonthValue}
          borderType={BorderType.Select1}
        />
        <MultipleChoice
          className={styles['calendardate-date']}
          columnNum={7}
          withTick={false}
          leftAligned
          optionsDisabled={month ? false : true}
          options={generateOptions(
            Array.from({ length: dateLength }, (_, i) => (i + 1).toString())
          )}
          setValue={handleDateValue}
          borderType={BorderType.Option2}
        />
      </div>
    </div>
  )
}
