import React from 'react'
import styles from './CachingProgressBar.module.scss'
import type { CachingProgressBarProps } from './CachingProgressBar.model'

export const CachingProgressBar: React.FC<CachingProgressBarProps> = (
  props
) => {
  const { fileType, currentAmount, maxAmount } = props

  return (
    <div className={styles['caching-progress-bar']}>
      <h3>
        Cached {currentAmount} out of {maxAmount} {fileType} files...
      </h3>
      <progress value={currentAmount} max={maxAmount} />
    </div>
  )
}
