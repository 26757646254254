import React, { HTMLAttributes } from 'react'

export const Months = [
  'January',
  'July',
  'February',
  'August',
  'March',
  'September',
  'April',
  'October',
  'May',
  'November',
  'June',
  'December'
]

export const MonthLength = [31, 31, 29, 31, 31, 30, 30, 31, 31, 30, 30, 31]

export interface CalendarDatePropsModel extends HTMLAttributes<HTMLElement> {
  /** Title on top of Calendar Date  */
  title?: string

  /** Subtitle on top of Calendar Date */
  subtitle?: string

  /** Set Value Function */
  setValue: (month?: string, date?: string) => void
}
