import React, { useEffect, useRef, useState } from 'react'
import { Fade } from '@mui/material'
import { Icon } from 'components/Icons/Icon.component'
import {
  AnimatedIconProps,
  CustomAnimatedIconProps,
  PromptAnimatedIconProps
} from './AnimatedIcon.model'
import { IconType } from 'components/Icons/Icon.model'
import classnames from 'classnames'
import styles from './AnimatedIcon.module.scss'

export const AnimatedIcon: React.FC<AnimatedIconProps> = (props) => {
  const {
    icon = IconType.HandPointer,
    variation,
    deltaX = 0,
    deltaY = 0
  } = props
  const [show, setShow] = useState(false)
  const [hasBeenShown, setHasBeenShown] = useState(false)
  const [animateClick, setAnimateClick] = useState(false)
  const iconRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (hasBeenShown) {
      return
    }

    if (variation === 'prompt') {
      // Delay before the icon starts to fade in
      const fadeInDelay = 500

      // Duration the icon stays visible after fading in
      const visibleDuration = 1400

      // Duration of the click animation
      const clickAnimationDuration = 400

      // Pause after click before starting to fade out
      const postClickPause = 1000

      const fadeInTimeout = setTimeout(() => {
        setShow(true)
        setHasBeenShown(true)
        const clickTimeout = setTimeout(() => {
          setAnimateClick(true)
          setTimeout(() => {
            setAnimateClick(false)
            setTimeout(() => setShow(false), postClickPause)
          }, clickAnimationDuration)
        }, visibleDuration)
      }, fadeInDelay)

      return () => {
        clearTimeout(fadeInTimeout)
      }
    } else if (variation === 'custom') {
      const {
        initialX,
        initialY,
        targetX,
        targetY,
        appearEffectDuration = 1000,
        timeBeforeAppear = 5000,
        timeBeforeMove = 0
      } = props as CustomAnimatedIconProps

      const appearTimeout = setTimeout(() => {
        setShow(true)
        setTimeout(() => {
          if (iconRef.current) {
            iconRef.current.style.transition = `transform ${timeBeforeMove}ms ease-in-out`
            iconRef.current.style.transform = `translate(${
              targetX - initialX + Number(deltaX)
            }%, ${targetY - initialY + Number(deltaY)}%)`
            setTimeout(() => {
              if (iconRef.current) {
                iconRef.current.style.transition = 'transform 300ms ease-in-out'
                iconRef.current.style.transform += ' scale(0.9)'
                setTimeout(() => {
                  if (iconRef.current) {
                    iconRef.current.style.transform = `translate(${
                      targetX - initialX + Number(deltaX)
                    }%, ${targetY - initialY + Number(deltaY)}%) scale(1)`
                    setTimeout(() => setShow(false), 500)
                  }
                }, 500)
              }
            }, timeBeforeMove)
          }
        }, appearEffectDuration)
      }, timeBeforeAppear)

      return () => clearTimeout(appearTimeout)
    }
  }, [variation, props, hasBeenShown, deltaX, deltaY])

  return (
    <div style={{ position: 'relative' }}>
      {variation === 'prompt' && (props as PromptAnimatedIconProps).children}
      <Fade in={show} timeout={{ enter: 500, exit: 500 }}>
        <div
          ref={iconRef}
          style={{
            position: 'absolute',
            left:
              variation === 'custom'
                ? `calc(${
                    (props as CustomAnimatedIconProps).initialX
                  }% + ${deltaX}%)`
                : '50%',
            top:
              variation === 'prompt'
                ? `calc(350% + ${deltaY}%)`
                : `calc(${
                    (props as CustomAnimatedIconProps).initialY
                  }% + ${deltaY}%)`,
            transform: `translate(-50%, ${hasBeenShown ? '-100%' : '0'})`,
            zIndex: 1000,
            pointerEvents: 'none'
          }}
        >
          <div
            style={{
              transform: animateClick ? 'scale(0.9)' : 'scale(1)',
              transition: 'transform 300ms ease-in-out'
            }}
          >
            <Icon icon={icon} />
          </div>
        </div>
      </Fade>
    </div>
  )
}
