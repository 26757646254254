/**
 * A custom error type used to indicate errors which occur in the application during the
 * process of caching assets.
 */
export class CachingError extends Error {
  /**
   * Construct a {@link CachingError} instance with a provided error message.
   *
   * @param message - The error message to associate with this error.
   */
  constructor(message: string) {
    super(message)
    this.name = this.constructor.name
  }
}

/**
 * A custom error type used to indicate invalid arguments being passed to functions.
 */
export class InvalidArgumentError extends Error {
  /**
   * Construct an {@link InvalidArgumentError} instance with a provided error message.
   *
   * @param message - The error message to associate with this error.
   */
  constructor(message: string) {
    super(message)
    this.name = this.constructor.name
  }
}

/**
 * A custom error type used to indicate that some value, such as a parameter, is nullish
 * (`null` or `undefined`), when it should not be.
 */
export class NonNullAssertionError extends Error {
  /**
   * If provided when the error was constructed, a label/name to attach to the
   * value that in question was nullish.
   */
  readonly valueName: string | null

  /**
   * Construct a {@link NonNullAssertionError} instance, with an error message that
   * describing a value (with an optional label) should not be nullish.
   *
   * @param valueName - If provided, a label/name to attach to the
   * value that in question was nullish.
   */
  constructor(valueName?: string) {
    super(`${valueName ?? 'value'} should not be nullish`)
    this.name = this.constructor.name
    this.valueName = valueName ?? null
  }
}
