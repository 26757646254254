import React, { Dispatch, useEffect, useState } from 'react'
import { Select } from 'components/Select/Select.component'
import { ISurveyMetaData, SurveyState } from 'store/type'
import { connect, useDispatch } from 'react-redux'
import { Button } from 'components/Button/Button.component'
import { useNavigate } from 'react-router-dom'
import {
  updateContinueLocalIdentifier,
  updateSurvey,
  updateSurveyMetadata
} from 'store/reducer'
import styles from './DashboardScreen.module.scss'
import Box from '@mui/material/Box'
import { DashboardScreenComponentProps } from './DashboardScreen.model'
import { generateOptions } from 'shared/utils/generateOptions/generateOptions'
import { SideMenu } from 'components/SideMenu/SideMenu.component'
import classnames from 'classnames'
import { IconType } from 'components/Icons/Icon.model'
import { colors } from 'shared/theme/theme'
import { RADashCalc } from 'shared/utils/raDashboardCalculation/raDashboardCalculation'
import { Card } from 'components/Card/Card.component'
import LinearProgress, {
  linearProgressClasses
} from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'
import { AnalysisModel, RADashboardMenu } from '../index.model'
import { Modal } from 'components/Modals/Modal/Modal.component'
import { Icon } from 'components/Icons/Icon.component'
import { DailyFeedback } from 'components/DailyFeedback/DailyFeedback.component'
import { BorderType } from 'components/Border/Border.model'
import { genericObject } from 'screens/survey/type'
import { surveyAPI } from 'api/client'
import { syncSurveysAPI } from 'store/actionCreators'
import { mapProjectSiteTargetCollection } from 'store/mapper'
import { RaTargetsType } from 'shared/constants/Constants.d'

const DashboardScreenComponent: React.FC<DashboardScreenComponentProps> = (
  props
) => {
  const navigate = useNavigate()
  const dispatch: Dispatch<any> = useDispatch()

  const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 8,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: 'rgba(51, 51, 51, .2)'
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: colors.capePalliser
    }
  }))

  const { generateProjectOptions, generateSiteOptions } = generateOptions()
  const { calcTotalResponses, generateTargetData } = RADashCalc()
  const { handleFeedbackSubmit } = surveyAPI()

  const {
    offline,
    user,
    serviceId,
    projects,
    projectSiteTargets,
    surveyId,
    isEnableSLK,
    projectId: defaultProjectId,
    siteId: defaultSiteId,
    surveyResponses,
    syncRounds,
    surveyMetaData
  } = props

  const [projectId, setProjectId] = useState<string>(defaultProjectId)
  const [siteId, setSiteId] = useState<string>(defaultSiteId)
  const [isSync, setIsSync] = useState<boolean>(false)
  const [newSurveyModalOpen, setNewSurveyModalOpen] = useState<boolean>(false)
  const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(false)
  const [feedbackState, setFeedbackState] = useState<genericObject>()
  const [feedbackDate, setFeedbackDate] = useState<string>()
  const [feedbackProject, setFeedbackProject] = useState<string>()
  const [feedbackSite, setFeedbackSite] = useState<string>()
  const [feedbackError, setFeedbackError] = useState<string>()
  const [feedbackSubmitting, setFeedbackSubmitting] = useState<boolean>(false)

  // Map a projectSiteTargets instance from project if the state.projectSiteTargets does not exist
  const projectSiteTargetCollection =
    projectSiteTargets.length > 0
      ? projectSiteTargets
      : mapProjectSiteTargetCollection(projects)

  const totalSurveys = calcTotalResponses(projectId, siteId, true, user?.id)
  const totalCompletedSurveys = totalSurveys.filter(
    (survey) =>
      !survey.metadata?.flaggedComingBack &&
      !survey.metadata?.flaggedDeleted &&
      !survey.metadata?.flaggedIncomplete &&
      survey.metadata?.status !== 'invalidSLK'
  )
  const unsyncedCompletedSurveys = totalCompletedSurveys.filter(
    (res) => res.responses && res.metadata && res.metadata.status !== 'synced'
  )

  const handleSurveyAction = (isContinue: boolean) => {
    // Update Survey if Project changes
    const project = projects.find((item) => item.id === projectId)
    const survey = project?.survey
    if (survey && (!surveyId || surveyId !== survey.id)) {
      dispatch(updateSurvey(survey))
    }

    const metadata: ISurveyMetaData = {
      ...surveyMetaData,
      serviceId,
      projectId,
      siteId,
      shopId:
        project?.shopId != null && project?.shopId !== 0
          ? project?.shopId
          : undefined,
      raId: user?.id,
      flaggedIncomplete: false,
      startTime: new Date(),
      status: 'incomplete',
      isEnableSLK: !!isEnableSLK
    }

    dispatch(updateSurveyMetadata(metadata))

    if (!isEnableSLK) {
      dispatch(updateContinueLocalIdentifier(undefined))

      navigate('/survey')
    } else if (isContinue) {
      navigate('/enter-participants-slk/continue')
    } else {
      navigate('/enter-participants-slk')
    }
  }

  const handleSyncAPI = () => {
    setIsSync(true)
    // Create a clean projectSiteTargetsRequest without the numbers of collections populated
    const projectSiteTargetsRequest = mapProjectSiteTargetCollection(projects)
    dispatch(
      syncSurveysAPI({
        serviceId,
        surveyResponses,
        projectSiteTargets: projectSiteTargetsRequest
      })
    )
  }

  const handleMenuChange = (menu: string) => {
    navigate(`/ra-dashboard/${menu}`)
  }

  const generateAnalysis = (
    title: string,
    values: AnalysisModel[],
    index: number
  ) => {
    return (
      <div key={index} className={styles['dashboardscreen-analysis-section']}>
        <p>{title}</p>
        <div className={styles['dashboardscreen-analysis-section-wrapper']}>
          {values.map((item, index) => (
            <Box key={index} sx={{ width: '100%' }}>
              <label
                id={`analysis${index}`}
                className={styles['dashboardscreen-analysis-section-labels']}
              >
                <span>{item.label}</span>
                <span>{`${item.quantity} / ${item.count}`}</span>
              </label>
            </Box>
          ))}
        </div>
      </div>
    )
  }

  const generateTargets = () => {
    const selectedProject = projectSiteTargetCollection.find(
      (p) => p.id === projectId
    )
    const site = selectedProject?.sites?.find((s) => s.id === siteId)
    const targets = site?.targets
    const results = generateTargetData(
      targets,
      RaTargetsType.Dashboard,
      unsyncedCompletedSurveys
    )

    return (
      results &&
      results.map((item, index) => (
        <Box key={index}>
          <p>{item.value}</p>
          <div className={styles['dashboardscreen-analysis']}>
            {item.data &&
              item.data.map((currentData, _currentDataIndex) =>
                generateAnalysis(
                  currentData.gender,
                  currentData.data,
                  _currentDataIndex
                )
              )}
          </div>
        </Box>
      ))
    )
  }
  const handleFeedbackFormSubmit = () => {
    if (!feedbackDate || !feedbackProject || !feedbackSite || !feedbackState) {
      setFeedbackError('Please fill in the required field')
      return
    }

    setFeedbackSubmitting(true)

    handleFeedbackSubmit({
      ...feedbackState,
      Date: feedbackDate,
      ProjectId: feedbackProject,
      SiteId: feedbackSite,
      RaId: user?.id,
      SerivceId: serviceId
    })
      .then((response) => {
        if (response) {
          setShowFeedbackModal(false)
        } else {
          setFeedbackError('Submit failed. Please try again')
        }
        setFeedbackSubmitting(false)
      })
      .catch((err) => {
        setFeedbackError(err)
        setFeedbackSubmitting(false)
      })
  }

  useEffect(() => {
    setIsSync(false)
  }, [syncRounds, surveyResponses, projectId, siteId])

  return (
    <div className={styles.dashboardscreen}>
      <SideMenu
        options={RADashboardMenu}
        value={RADashboardMenu[0].value}
        setValue={handleMenuChange}
      />
      <main className={styles['dashboardscreen-content']}>
        <h1>
          Hello, {user ? `${user.firstName} ${user.lastName}` : undefined}
        </h1>
        <div className={styles['dashboardscreen-row1']}>
          <Box component="form" className={styles['dashboardscreen-row1-form']}>
            <Select
              className={styles['dashboardscreen-select']}
              options={generateProjectOptions(projects)}
              placeholder="Select Project"
              selectProps={{
                defaultValue: defaultProjectId,
                inputProps: {
                  'aria-label': 'Select Project'
                }
              }}
              setValue={(value) => setProjectId(value)}
              borderType={BorderType.Select2}
              style={{
                marginRight: 20,
                backgroundColor: colors.white
              }}
            />
            <Select
              className={styles['dashboardscreen-select']}
              options={generateSiteOptions(projects, projectId)}
              placeholder="Select Site"
              selectProps={{
                defaultValue: defaultSiteId,
                inputProps: {
                  'aria-label': 'Select Site'
                }
              }}
              setValue={(value) => setSiteId(value)}
              borderType={BorderType.Select2}
              style={{
                marginRight: 20,
                backgroundColor: colors.white
              }}
            />
          </Box>
          {offline ? (
            <Button
              className={styles['dashboardscreen-row1-syncButton']}
              variation="primary"
              disabled
              icon={IconType.NotSync}
              style={{ backgroundColor: colors.white }}
            >
              Sync
            </Button>
          ) : (
            <Button
              className={classnames(styles['dashboardscreen-row1-syncButton'], {
                [styles['dashboardscreen-row1-syncButton--loading']]: isSync
              })}
              onClick={handleSyncAPI}
              icon={IconType.Sync}
              borderType={BorderType.Option1}
              aria-label="sync"
              style={{
                backgroundColor: isSync ? colors.greenSmoke : colors.white
              }}
            >
              {!isSync && 'Sync'}
            </Button>
          )}
        </div>
        <div className={styles['dashboardscreen-row2']}>
          <Button
            className={styles['dashboardscreen-row2-card']}
            icon={IconType.Add}
            iconPosition="top"
            dropShadow
            onClick={() => setNewSurveyModalOpen(true)}
            borderType={BorderType.Option1}
            style={{
              backgroundColor: colors.greenSmoke
            }}
          >
            Start a survey
          </Button>
          {isEnableSLK && (
            <Button
              className={styles['dashboardscreen-row2-card']}
              icon={IconType.Continue}
              iconPosition="top"
              dropShadow
              borderType={BorderType.Option1}
              onClick={() => handleSurveyAction(true)}
              style={{
                backgroundColor: colors.parisWhite
              }}
            >
              Continue a survey
            </Button>
          )}
          <Button
            className={styles['dashboardscreen-row2-card']}
            icon={IconType.Feedback}
            iconPosition="top"
            dropShadow
            borderType={BorderType.Option1}
            style={{
              backgroundColor: colors.botticelli
            }}
            onClick={() => setShowFeedbackModal(true)}
          >
            Daily feedback
          </Button>
        </div>
        <div className={styles['dashboardscreen-row3']}>
          <p>Surveys I have collected: {unsyncedCompletedSurveys.length}</p>
          <Card
            className={styles['dashboardscreen-card']}
            title="People I have collected today"
            titleColor={colors.breakerBay}
            titleHeight={57}
            centerAlign={false}
          >
            <div className={styles['dashboardscreen-analysisWrapper']}>
              {generateTargets()}
            </div>
          </Card>
        </div>
      </main>
      <Modal
        className={styles['dashboardscreen-newSurveyModal']}
        open={newSurveyModalOpen}
        customClose={() => setNewSurveyModalOpen(false)}
        title="New Survey"
        titleColor={colors.capePalliser}
        closeIcon={true}
        buttons={<></>}
        style={{ backgroundColor: colors.white }}
      >
        <div className={styles['dashboardscreen-newSurveyModal-content']}>
          <div
            className={styles['dashboardscreen-newSurveyModal-content-info']}
          >
            <Icon icon={IconType.Information} />
            <Icon icon={IconType.Person} />
            <p>This survey will collect data</p>
          </div>
          <p>Please choose a project and site below</p>
          <Box
            component="form"
            className={styles['dashboardscreen-newSurveyModal-content-form']}
          >
            <Select
              className={
                styles['dashboardscreen-newSurveyModal-content-form-select']
              }
              options={generateProjectOptions(projects)}
              placeholder="Select Project"
              selectProps={{
                value: projectId,
                inputProps: {
                  'aria-label': 'Select Project'
                }
              }}
              setValue={(value) => setProjectId(value)}
            />
            <Select
              className={
                styles['dashboardscreen-newSurveyModal-content-form-select']
              }
              options={generateSiteOptions(projects, projectId)}
              placeholder="Select Site"
              selectProps={{
                value: siteId,
                inputProps: {
                  'aria-label': 'Select Site'
                }
              }}
              setValue={(value) => setSiteId(value)}
            />
            <Button
              className={
                styles[
                  'dashboardscreen-newSurveyModal-content-form-startButton'
                ]
              }
              borderType={BorderType.Select1}
              onClick={() => handleSurveyAction(false)}
              style={{
                backgroundColor: colors.greenSmoke
              }}
            >
              Start survey
            </Button>
            <Button
              className={
                styles[
                  'dashboardscreen-newSurveyModal-content-form-cancelButton'
                ]
              }
              borderType={BorderType.Select1}
              onClick={() => navigate('/ra-dashboard/declined')}
              style={{
                backgroundColor: colors.chestnutRose
              }}
            >
              Declined to take part
            </Button>
          </Box>
        </div>
      </Modal>

      <Modal
        className="dashboardscreen-dailyFeedbackModal"
        open={showFeedbackModal}
        customClose={() => setShowFeedbackModal(false)}
        style={{ backgroundColor: colors.white }}
        closeIcon={true}
        hasActions={false}
        buttons={<> </>}
      >
        <DailyFeedback
          projects={projects}
          feedbackState={feedbackState}
          feedbackSubmitting={feedbackSubmitting}
          feedbackDate={feedbackDate}
          feedbackSite={feedbackSite}
          feedbackError={feedbackError}
          setFeedbackState={setFeedbackState}
          setFeedbackDate={setFeedbackDate}
          setFeedbackSite={setFeedbackSite}
          feedbackProject={feedbackProject}
          setFeedbackProject={setFeedbackProject}
          btnCallBack={handleFeedbackFormSubmit}
        />
      </Modal>
    </div>
  )
}

const mapStateToProps = (state: SurveyState) => ({
  offline: state.offline || false,
  user: state.user,
  serviceId: state.service?.id,
  isEnableSLK: state.survey?.isEnableSLK,
  projects: state.projects ?? [],
  projectSiteTargets: state.projectSiteTargets ?? [],
  surveyId: state.survey?.id,
  projectId: state.surveyMetaData?.projectId || '',
  siteId: state.surveyMetaData?.siteId || '',
  surveyResponses: state.surveyResponses,
  syncRounds: state.syncRounds,
  surveyMetaData: state.surveyMetaData
})

export const DashboardScreen = connect(mapStateToProps)(
  DashboardScreenComponent
)
