import React, { useContext, useEffect, useState } from 'react'
import classnames from 'classnames'
import styles from './CircleChoice.module.scss'
import { colors } from 'shared/theme/theme'
import { Carousel } from 'components/Carousel/Carousel.component'
import { BorderType } from 'components/Border/Border.model'
import { Border } from 'components/Border/Border.component'
import {
  CircleChoiceOptionModel,
  CircleChoicePropsModel
} from './CircleChoice.model'
import { AudioContext } from 'context/AudioContext'

export const CircleChoice: React.FC<CircleChoicePropsModel> = (props) => {
  const { title, subtitle, options, setValue, ...componentProps } = props
  const { playAudio } = useContext(AudioContext)
  const [selected, setSelected] = useState<string>()

  useEffect(() => {
    setValue(selected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  // Clear Option Text when options changed
  useEffect(() => {
    setSelected(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  const handleClick = (option: CircleChoiceOptionModel) => {
    if (selected !== option.label && option?.voices && option?.id) {
      playAudio?.(`${option?.id}-option`)
    }
    setSelected(option.label)
  }

  const generateOptionMarkup = () => {
    return options.map(
      (option) =>
        option && (
          <div
            className={classnames(styles['circlechoice-option'], {
              [styles['circlechoice-option--dimmer']]:
                selected && selected !== option.label
            })}
            key={option.id}
            onClick={() => handleClick(option)}
          >
            <Border
              type={BorderType.Circle2}
              fill={option.image ? 'none' : colors.white}
              image={option.image}
              {...option}
            />
          </div>
        )
    )
  }

  return (
    <div {...componentProps} className={styles.circlechoice}>
      {title && (
        <h1
          className={classnames(
            'screen-titles--title',
            styles['circlechoice-title']
          )}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}
      {subtitle && (
        <p
          className={classnames(
            'screen-titles--subtitle',
            styles['circlechoice-subtitle']
          )}
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      )}
      <div className={classnames(styles['circlechoice-content'])}>
        <h2
          className={styles['circlechoice-optionText']}
          aria-hidden={selected ? 'true' : 'false'}
        >
          {selected}
        </h2>
        <div
          className={classnames(styles['circlechoice-wrapper'], {
            'no-padding': options.length > 6
          })}
          style={{
            display: options.length < 7 ? 'flex' : 'block',
            width: 160 * options.length
          }}
        >
          {options.length > 6 ? (
            <Carousel slidesToShow={5}>{generateOptionMarkup()}</Carousel>
          ) : (
            generateOptionMarkup()
          )}
        </div>
      </div>
    </div>
  )
}
