export const breakpoints = {
  xl: 1536, // MUI default xl
  lg: 1200, // desktop (MUI default lg)
  md: 1024, // laptop
  sm: 640, // tablet
  xs: 0 // mobile (MUI default xs)
}

// Colors
export const colors = {
  white: '#FFFFFF',
  black: '#222222',
  botticelli: '#C2CFDF',
  breakerBay: '#55968C',
  butterflyBush: '#5C5182',
  capePalliser: '#A1664C',
  clamShell: '#D9C2B7',
  chestnutRose: '#D35555',
  contessa: '#C57861',
  crimson: '#DA1414',
  greenSmoke: '#8FA26F',
  potPourri: '#F2E6DE',
  parisWhite: '#CCE0DD',
  santaFe: '#B77548',
  tundora: '#404040',

  // Drink Color
  standardDrink: '#F28E1C',
  fizzyDrink: '#639BCF'
}
