import React, { useRef, useState } from 'react'
import { AutoCompletePropsModel } from './AutoComplete.model'
import styles from './AutoComplete.module.scss'
import classnames from 'classnames'
import { Border } from 'components/Border/Border.component'
import { BorderType } from 'components/Border/Border.model'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { v4 as uuidv4 } from 'uuid'

export const AutoComplete = <T = string,>({
  className,
  dataList,
  inputOption,
  setValue,
  validateValue,
  style
}: AutoCompletePropsModel<T>) => {
  const idRef = useRef<string>('')
  const [inputValue, setInputValue] = useState<string>('')

  const getId = (): string => {
    if (!idRef.current) {
      idRef.current = uuidv4()
    }
    return idRef.current
  }

  const defaultValidation = (value: string) => {
    if (inputOption.required && value === '') {
      return 'Input is required'
    }
    return undefined
  }

  const handleValueValidation = (value: string) => {
    const error = validateValue
      ? validateValue(value)
      : defaultValidation(value)
    setValue(value, error)
  }

  return (
    <div className={classnames(className, styles.input)}>
      <div style={{ position: 'relative' }}>
        <Border type={BorderType.Select2} fill={style?.backgroundColor} />
        <label id={'label' + getId()}>{inputOption.label}</label>
        <Autocomplete
          options={dataList}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue)
            handleValueValidation(newInputValue)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              placeholder={`${inputOption.label ? inputOption.label : ''}${
                inputOption.required ? ' (required)' : ''
              }`}
            />
          )}
        />
      </div>
    </div>
  )
}
