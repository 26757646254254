import React, { useContext, useState } from 'react'
import { PieChartPropsModel } from './PieChart.model'
import styles from './PieChart.module.scss'
import classnames from 'classnames'
import { Icon } from 'components/Icons/Icon.component'
import { IconType } from 'components/Icons/Icon.model'
import { AudioContext } from 'context/AudioContext'
import { useColourPicker } from 'context/ColourContext'

export const PieChart: React.FC<PieChartPropsModel> = (props) => {
  const { className, options, setValue } = props
  const { secondaryColour } = useColourPicker()
  const { playAudio } = useContext(AudioContext)
  const [complete, setComplete] = useState(
    Array.from({ length: options.length }, (_) => false)
  )

  const handlePieceClick = (index: number, value: string) => {
    if (
      options[index]?.voices &&
      options[index]?.id &&
      !options[index]?.followUp?.voices
    ) {
      playAudio?.(`${options[index]?.id}-option`)
    }
    const newComplete = [...complete]
    newComplete[index] = true
    setComplete(newComplete)
    setValue(value)
  }

  const getIcon = (index: number) => {
    if (options.length === 4) {
      return `FourPie${index + 1}` as keyof typeof IconType
    }

    return `ThreePie${index + 1}` as keyof typeof IconType
  }

  return (
    <div
      className={classnames(className, styles.piechart, {
        [styles['piechart--three']]: options.length === 3,
        [styles['piechart--four']]: options.length === 4
      })}
    >
      {options.map((option, index) => (
        <div
          className={classnames(styles['piechart__content'], {
            [styles['piechart__content--complete']]: complete[index]
          })}
          key={index}
          onClick={() => handlePieceClick(index, option.value)}
        >
          {complete[index] && (
            <div
              className={classnames(
                styles['piechart__content__tick'],
                styles[`piechart__content__tick--${index}`]
              )}
            >
              <Icon
                icon={IconType.Tick}
                size={options.length === 3 ? 48 : 28}
                fill={secondaryColour}
              />
            </div>
          )}
          <div
            className={classnames(
              styles['piechart__content__label'],
              styles[`piechart__content__label--${index}`],
              {
                [styles['piechart__content__label--complete']]: complete[index]
              }
            )}
          >
            {option.label}
          </div>
          <Icon
            className={classnames(
              styles['piechart__content__piece'],
              styles[`piechart__content__piece--${index}`],
              {
                [styles['piechart__content__piece--complete']]: complete[index]
              }
            )}
            icon={IconType[getIcon(index)]}
            fill={complete[index] ? 'none' : secondaryColour}
            image={option.image}
          />
        </div>
      ))}
    </div>
  )
}
