import React, { HTMLAttributes } from 'react'

export enum FeedbackIcon {
  'Big Frown' = 'BigFrown',
  'Small Frown' = 'SmallFrown',
  'Straight Face' = 'StraightFace',
  'Small Smile' = 'SmallSmile',
  'Big Smile' = 'BigSmile'
}

export interface FeedbackOption {
  icon: keyof typeof FeedbackIcon
  value: string
}

export interface FeedbackPropsModel extends HTMLAttributes<HTMLElement> {
  name: string
  options: FeedbackOption[]
  setValue: (value: string) => void
}
