import React from 'react'
import { Screen } from 'components/Screen/Screen.component'
import { ContactsScreenPropsModel } from './ContactsScreen.model'
import styles from './ContactsScreen.module.scss'
import classnames from 'classnames'
import { matchCondition } from 'shared/utils/matchCondition/matchCondition'
import { shortcodesRender } from 'shared/utils/shortcodesRender/shortcodesRender'
import { updateSurveyAnswer } from 'store/reducer'
import { ISurveyData } from 'store/type'
import { Dispatch } from 'redux'
import { useDispatch } from 'react-redux'
import { AlcoholCalc } from 'shared/utils/alcoholCalculation/alcoholCalculation'
import { RISK_TYPE } from 'shared/constants/SurveyFields.d'

export const ContactsScreen: React.FC<ContactsScreenPropsModel> = (props) => {
  const {
    currentScreenId,
    headerProps,
    footerProps,
    shortcodes,
    screenData: { title, subtitle, columnOne, columnTwo }
  } = props

  const dispatch: Dispatch<any> = useDispatch()

  const { calcAlcoholRiskLevel } = AlcoholCalc()

  const { getNextScreenId } = matchCondition()

  const handleFooterProps = () => {
    const updatedFooterProps = { ...footerProps }

    if (updatedFooterProps.nextScreenId) {
      updatedFooterProps.nextScreenId = getNextScreenId(
        undefined,
        footerProps.conditions,
        footerProps.nextScreenId
      )
    }

    // Log: Alcohol Risk Type (GitHub #431)
    updatedFooterProps.onForwardTransition = () => {
      // Pass a state update function to ensure the `risk-type` data integrity
      // when the Next button on an Outro Contacts screen is clicked
      const riskLevel = calcAlcoholRiskLevel()
      dispatch(
        updateSurveyAnswer({
          [RISK_TYPE]: riskLevel ? riskLevel : undefined
        } as ISurveyData)
      )
    }

    return updatedFooterProps
  }

  return (
    <div className={classnames('drug-app-screen', styles.contactsscreen)}>
      <Screen
        currentScreenId={currentScreenId}
        headerProps={headerProps}
        footerProps={handleFooterProps()}
      >
        <div className={styles['contactsscreen-titles']}>
          {title && (
            <h1
              className="screen-titles--title"
              dangerouslySetInnerHTML={{
                __html: shortcodesRender(shortcodes, title) || title
              }}
            />
          )}
          {subtitle && (
            <p
              className="screen-titles--subtitle"
              dangerouslySetInnerHTML={{ __html: subtitle }}
            />
          )}
        </div>
        <div className={styles['contactsscreen-container']}>
          <div
            className={classnames(
              styles['contactsscreen-container-column'],
              styles['contactsscreen-container-column--one']
            )}
          >
            {columnOne.image && (
              <img src={columnOne.image} alt={columnOne.detail || 'Logo'} />
            )}
            {columnOne.detail && <p>{columnOne.detail}</p>}
            <div className={styles['contactsscreen-container-column-contact']}>
              {columnOne.phoneLabel && <p>{columnOne.phoneLabel}</p>}
              {columnOne.phoneNumber && (
                <p>
                  <b>{columnOne.phoneNumber}</b>
                </p>
              )}
            </div>
          </div>
          {columnTwo && (
            <div
              className={classnames(
                styles['contactsscreen-container-column'],
                styles['contactsscreen-container-column--two']
              )}
            >
              {columnTwo.image && (
                <img src={columnTwo.image} alt="Default icon" />
              )}
              {columnTwo.detail && <p>{columnTwo.detail}</p>}
              <div
                className={styles['contactsscreen-container-column-contact']}
              >
                {columnTwo.phoneLabel && <p>{columnTwo.phoneLabel}</p>}
                {columnTwo.phoneNumber && (
                  <p>
                    <b>{columnTwo.phoneNumber}</b>
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      </Screen>
    </div>
  )
}
