import { createSlice } from '@reduxjs/toolkit'
import {
  fetchAllAppDataAPI,
  fetchSurveyAPI,
  loginAPI,
  syncSurveysAPI
} from './actionCreators'
import type {
  AssetUrls,
  SurveyGrogDiaryCurrentConsumption,
  SurveyGrogDiaryCurrentSelections,
  SurveyGrogDiaryCurrentState,
  SurveyResponse,
  SurveyState
} from './type'
import { v4 as uuidv4 } from 'uuid'
import { GrogDiaryStep } from 'screens/survey/GrogDiaryScreen/GrogDiaryScreen.model'
import { SyncSurveysAPIResultData } from './actionCreators.model'
import type { DeepMutable } from 'shared/types/general'
import { GROG_DIARY_CURRENT_STATE_DEFAULT } from 'shared/constants/Constants.d'
import { assertNonNull } from 'shared/types/guards'

const initialState: SurveyState = {
  offline: undefined,
  syncRounds: undefined,
  lastLoginStatus: undefined,
  loginAttempt: undefined,
  isDemoMode: false,
  user: undefined,
  service: undefined,
  demoService: undefined,
  geoLocations: undefined,
  demoGeoLocations: undefined,
  grogShops: undefined,
  demoGrogShops: undefined,
  projects: [],
  projectSiteTargets: [],
  survey: undefined,
  surveyData: undefined,
  surveyMetaData: undefined,
  surveyResponses: [],
  assetUrls: undefined,
  demoAssetUrls: undefined,
  continueLocalIdentifier: undefined,
  userJourney: [],
  grogDiaryUserJourney: [{ step: GrogDiaryStep.LASTDAY, skipped: false }],
  grogDiaryCurrentState: structuredClone(
    GROG_DIARY_CURRENT_STATE_DEFAULT
  ) as DeepMutable<SurveyGrogDiaryCurrentState>,
  drinkingSessions: undefined,
  analytics: undefined,
  lastQTime: undefined
}

const tryParseSpecificReturnedSurveyResponse = (
  data: SyncSurveysAPIResultData,
  responseType: keyof SyncSurveysAPIResultData,
  defaultData: SurveyResponse
): SurveyResponse => {
  const item = data[responseType].find(
    (cItem) => cItem.localIdentifier === defaultData.localIdentifier
  )

  return item ? { ...item, local: true } : defaultData
}

const slice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    updateUserJourney(state, action) {
      if (action.payload && action.payload.continue) {
        state.userJourney = action.payload.userJourney
      } else {
        if (
          state.userJourney &&
          state.userJourney?.length > 0 &&
          action.payload === -1
        ) {
          state.userJourney.pop()
        } else if (action.payload === undefined) {
          state.userJourney = []
        } else {
          if (!state.userJourney) {
            state.userJourney = []
          }
          state.userJourney?.push(action.payload)
        }
      }
    },
    updateGrogDiaryUserJourney(state, action) {
      switch (action.payload.actionType) {
        case 'continue':
          // Restore state
          state.grogDiaryUserJourney = action.payload.data
          break

        case 'push': {
          // Convert to string, then add to array
          state.grogDiaryUserJourney?.push(action.payload.data)
          break
        }

        case 'pop':
          state.grogDiaryUserJourney?.pop()
          break

        case 'mark-skipped': {
          // Marks the current consumption as "skipped"
          const mostRecentStep = state.grogDiaryUserJourney?.pop()
          if (!mostRecentStep) {
            // Do nothing if no step is recorded
            return
          }

          const skippedStepData = {
            step: mostRecentStep.step,
            skipped: true,
            skippedState: action.payload.data
          }

          state.grogDiaryUserJourney?.push(skippedStepData)
          break
        }

        case 'clear-skipped': {
          // Clears the most recent step being marked as being "skipped", or does nothing
          // if it was not marked as being skipped
          const mostRecentStep = state.grogDiaryUserJourney?.pop()
          if (!mostRecentStep || !mostRecentStep.skipped) {
            // Do nothing if no step is recorded OR if the most recent step was NOT marked
            // as skipped
            return
          }

          const regularStepData = {
            step: mostRecentStep.step,
            skipped: false
          } as const

          state.grogDiaryUserJourney?.push(regularStepData)
          break
        }

        case 'rewind-to-date-start': {
          // Loop which will pop ALL consumption steps until we reach either the "people"
          // slider, or the "last day" calendar selection
          // - Initialiser: most recent step of Grog Diary user journey
          // - Check: make sure step is not nullish (out-of-bounds for user journey), or
          //   is the "people" slider/"last day" calendar selection
          // - Increment: get the next last step in the diary
          for (
            let step = state.grogDiaryUserJourney?.at(-1)?.step;
            step != null &&
            step != GrogDiaryStep.PEOPLE &&
            step != GrogDiaryStep.LASTDAY;
            step = state.grogDiaryUserJourney?.at(-1)?.step
          ) {
            // Pop the step from the diary
            state.grogDiaryUserJourney?.pop()
          }
          break
        }

        case 'clear':
          state.grogDiaryUserJourney = [
            { step: GrogDiaryStep.LASTDAY, skipped: false }
          ]
          break
      }
    },
    updateGrogDiaryCurrentConsumption(state, action) {
      assertNonNull(state.grogDiaryCurrentState, 'grogDiaryCurrentState')

      switch (action.payload.actionType) {
        case 'delete':
          state.grogDiaryCurrentState.consumption = undefined
          break
        case 'setValue':
          // Otherwise, we are setting a value
          if (!state.grogDiaryCurrentState.consumption) {
            state.grogDiaryCurrentState.consumption = {}
          }

          // Set with the key directly to the required value
          state.grogDiaryCurrentState.consumption[
            action.payload.key as keyof SurveyGrogDiaryCurrentConsumption
          ] = action.payload.value
          break
        case 'continue':
          state.grogDiaryCurrentState.consumption = action.payload.data
          break
      }
    },
    updateGrogDiaryCurrentSelections(state, action) {
      assertNonNull(state.grogDiaryCurrentState, 'grogDiaryCurrentState')

      switch (action.payload.actionType) {
        case 'setValue': {
          // Use key to select which selection array we are updating
          const selectionKey = action.payload
            .key as keyof SurveyGrogDiaryCurrentSelections

          state.grogDiaryCurrentState.selections[selectionKey] =
            action.payload.value
          break
        }

        case 'clear':
          state.grogDiaryCurrentState.selections = structuredClone(
            GROG_DIARY_CURRENT_STATE_DEFAULT.selections
          ) as DeepMutable<SurveyGrogDiaryCurrentSelections>
          break

        case 'continue':
          state.grogDiaryCurrentState.selections = action.payload.data
          break
      }
    },
    updateOutroUserJourney(state, action) {
      switch (action.payload.actionType) {
        case 'continue':
          // Restore state
          state.outroUserJourney = action.payload.data
          break

        case 'moving-forward': {
          if (!state.outroUserJourney) {
            state.outroUserJourney = []
          }

          // Outro - intro section: the 'currOutroSection' at this stage is null
          if (!action.payload.data.currOutroSection) {
            state.outroUserJourney = [
              {
                outroSection: action.payload.data.nextOutroSection,
                completed: false
              }
            ]
            return
          }

          const arrLength = state.outroUserJourney.length
          for (let i = arrLength - 1; i >= 0; --i) {
            if (
              state.outroUserJourney[i].outroSection.valueOf() !==
              action.payload.data.currOutroSection.valueOf()
            ) {
              // If the lastOutroSectionItem <> the current OutroSection, drop the lastOutroSectionItem.
              // (This scenario would happen when moving backward and forward between Outro sections)
              state.outroUserJourney.pop()
            } else {
              // If the lastOutroSectionItem == the current OutroSection,
              // mark the current OutroSection as completed and push it back to the journey
              state.outroUserJourney.pop()
              state.outroUserJourney?.push({
                outroSection: action.payload.data.currOutroSection,
                completed: true
              })
              break
            }
          }
          // Push the next OutroSection with an incomplete status to the journey
          if (action.payload.data.nextOutroSection) {
            state.outroUserJourney?.push({
              outroSection: action.payload.data.nextOutroSection,
              completed: false
            })
          }
          break
        }

        case 'moving-backward': {
          if (!state.outroUserJourney || state.outroUserJourney.length === 0) {
            return
          }

          // Outro - intro section: the 'currOutroSection' at this stage is null
          // Outro - intro section is not included in the Outro journey.
          if (!action.payload.data.currOutroSection) {
            state.outroUserJourney = []
            return
          }

          const arrLength = state.outroUserJourney.length
          for (let i = arrLength - 1; i >= 0; --i) {
            if (
              state.outroUserJourney[i].outroSection.valueOf() !==
              action.payload.data.currOutroSection.valueOf()
            ) {
              // If the lastOutroSectionItem <> the current OutroSection, drop the lastOutroSectionItem
              // (This scenario would happen when moving backward and forward between Outro sections)
              state.outroUserJourney.pop()
            } else {
              // If the lastOutroSectionItem == the current OutroSection,
              // mark the current OutroSection as incomplete and push it back to the journey
              state.outroUserJourney.pop()
              state.outroUserJourney?.push({
                outroSection: action.payload.data.currOutroSection,
                completed: false
              })
              break
            }
          }
          break
        }

        case 'clear':
          state.outroUserJourney = undefined
          break
      }
    },
    updateService(state, action) {
      state.service = action.payload
    },
    updateUser(state, action) {
      state.user = action.payload
    },
    updateProjects(state, action) {
      state.projects = action.payload
    },
    updateSurvey(state, action) {
      state.survey = action.payload
    },
    updateDrinkingSession(state, action) {
      state.drinkingSessions = action.payload
    },
    logout(state) {
      state.offline = undefined
      state.lastLoginStatus = undefined
      state.loginAttempt = undefined
      state.isDemoMode = false
      state.user = undefined
      state.projects = []
      state.survey = undefined
      state.surveyData = undefined
      state.surveyMetaData = undefined
      state.userJourney = []
      state.grogDiaryUserJourney = [
        { step: GrogDiaryStep.LASTDAY, skipped: false }
      ]
      state.grogDiaryCurrentState = structuredClone(
        GROG_DIARY_CURRENT_STATE_DEFAULT
      ) as DeepMutable<SurveyGrogDiaryCurrentState>
      state.analytics = undefined
      state.lastQTime = undefined
      state.continueLocalIdentifier = undefined
    },
    updateSurveyMetadata(state, action) {
      state.surveyMetaData = {
        ...state.surveyMetaData,
        ...action.payload
      }
    },
    updateSurveyAnswer(state, action) {
      state.surveyData = {
        ...state.surveyData,
        ...action.payload
      }
    },
    updateContinueLocalIdentifier(state, action) {
      state.continueLocalIdentifier = action.payload
    },
    updateMetadataByLocalIdentifier(state, action) {
      const survey = state.surveyResponses?.find(
        (x) => x.localIdentifier == action.payload.localIdentifer
      )
      if (survey && action.payload.metadata) {
        survey.metadata = action.payload.metadata
      }
    },
    updateContinueSurveyResponse(state, action) {
      const lastedSurvey = state.surveyResponses?.filter(
        (x) =>
          state.continueLocalIdentifier &&
          x.localIdentifier == state.continueLocalIdentifier
      )

      if (lastedSurvey && lastedSurvey.length > 0) {
        const updateResponse = { ...lastedSurvey[0] }
        if (updateResponse && updateResponse.metadata) {
          updateResponse.metadata = {
            ...action.payload.metadata,
            slk: updateResponse.metadata.slk
          }
          updateResponse.responses = { ...action.payload.responses }
          updateResponse.analytics = action.payload.analytics
          updateResponse.userJourney = action.payload.userJourney
          updateResponse.outroUserJourney = action.payload.outroUserJourney
          updateResponse.grogDiaryUserJourney =
            action.payload.grogDiaryUserJourney
          updateResponse.grogDiaryCurrentState =
            action.payload.grogDiaryCurrentState
          updateResponse.drinkingSessions = action.payload.drinkingSessions
          updateResponse.local = true

          state.surveyResponses = [
            ...(state.surveyResponses || []).filter(
              (each) => each.localIdentifier != state.continueLocalIdentifier
            )
          ]

          state.surveyResponses.push(updateResponse)
        }
      }
      state.surveyData = undefined
      state.userJourney = []
      state.grogDiaryUserJourney = [
        { step: GrogDiaryStep.LASTDAY, skipped: false }
      ]
      state.analytics = undefined
      state.lastQTime = undefined
    },
    addCompletedSurvey(state, action) {
      if (action.payload.save) {
        state.surveyResponses = [
          ...(state.surveyResponses || []),
          {
            id: action.payload.id,
            localIdentifier: uuidv4(),
            local: true,
            metadata: action.payload.metadata,
            responses: action.payload.responses,
            userJourney: action.payload.userJourney,
            grogDiaryUserJourney: action.payload.grogDiaryUserJourney,
            grogDiaryCurrentState: action.payload.grogDiaryCurrentState,
            outroUserJourney: action.payload.outroUserJourney,
            analytics: action.payload.analytics,
            drinkingSessions: action.payload.drinkingSessions
          }
        ]
      }
      state.surveyMetaData = {
        ...state.surveyMetaData,
        startTime: undefined
      }
      state.surveyData = undefined
      state.userJourney = []
      state.grogDiaryUserJourney = [
        { step: GrogDiaryStep.LASTDAY, skipped: false }
      ]
      state.grogDiaryCurrentState = structuredClone(
        GROG_DIARY_CURRENT_STATE_DEFAULT
      ) as DeepMutable<SurveyGrogDiaryCurrentState>
      state.analytics = undefined
      state.lastQTime = undefined
    },
    updateValidatedSLK(state, action) {
      const responses = state.surveyResponses?.filter(
        (x) => x.localIdentifier == action.payload.localIdentifier
      )
      if (responses && responses.length > 0) {
        const updateResponse = { ...responses[0] }
        updateResponse.metadata!.slk = action.payload.slk
        updateResponse.metadata!.status = updateResponse.metadata!
          .flaggedDeleted
          ? 'deletion'
          : updateResponse.metadata!.flaggedIncomplete
          ? 'incomplete'
          : 'complete'
        state.surveyResponses = [
          ...(state.surveyResponses || []).filter(
            (each) => each.localIdentifier != action.payload.localIdentifier
          )
        ]
        state.surveyResponses?.push(updateResponse)
      }
    },
    updateLastQTime(state, action) {
      state.lastQTime = action.payload
    },
    updateAnalytics(state, action) {
      if (state.analytics) {
        const existingStateIndex = state.analytics.findIndex(
          (item) => item.question === action.payload.question
        )

        // Check whether question already exists in analytics state
        if (existingStateIndex !== undefined && existingStateIndex > -1) {
          state.analytics[existingStateIndex] = action.payload
        } else {
          Array.isArray(action.payload)
            ? (state.analytics = [...action.payload])
            : state.analytics.push(action.payload)
        }
      } else {
        if (Array.isArray(action.payload)) {
          state.analytics = [...action.payload]
        } else {
          state.analytics = [action.payload]
        }
      }
    },
    updateLastLoginStatus(state, action) {
      state.lastLoginStatus = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loginAPI.fulfilled, (state, action) => {
      state.offline = false
      state.lastLoginStatus = {
        loggedIn: true,
        serviceId: action.payload.userInfo.serviceId
      }
      state.loginAttempt = undefined
      state.user = action.payload.userInfo
      state.isDemoMode = action.payload.demoMode ?? false
    })
    builder.addCase(loginAPI.rejected, (state, action) => {
      state.lastLoginStatus = { loggedIn: false }

      if (action.payload) {
        state.offline = false
        state.lastLoginStatus.errorMessage = action.payload as string
      } else {
        // An unexpected error has occurred
        state.offline = true
      }

      state.loginAttempt =
        (state.loginAttempt != null ? state.loginAttempt : 0) + 1
    })
    builder.addCase(fetchAllAppDataAPI.fulfilled, (state, action) => {
      const draftAssetsInfo = action.payload
        .assetsInfo as DeepMutable<AssetUrls>

      if (state.isDemoMode) {
        state.demoService = action.payload.data
        state.demoGeoLocations = action.payload.locations
        state.demoGrogShops = action.payload.grogshops
        state.demoAssetUrls = draftAssetsInfo
      } else {
        state.service = action.payload.data
        state.geoLocations = action.payload.locations
        state.grogShops = action.payload.grogshops
        state.slkList = action.payload.slks
        state.assetUrls = draftAssetsInfo
      }
    })
    builder.addCase(fetchAllAppDataAPI.rejected, (state) => {
      state.offline = true
      state.loginAttempt = undefined
    })
    builder.addCase(fetchSurveyAPI.fulfilled, (state, action) => {
      state.service = action.payload.data
    })
    builder.addCase(syncSurveysAPI.fulfilled, (state, action) => {
      if (!action.payload?.data) return
      const completedIds = new Set(
        action.payload.data.completedSubmissions.map(
          (item) => item.localIdentifier
        )
      )
      const incompletedIds = new Set(
        action.payload.data.toBeContinueSubmissions.map(
          (item) => item.localIdentifier
        )
      )

      const updatedLocalResponses = (state.surveyResponses || []).map(
        (each) => {
          if (completedIds.has(each.localIdentifier) && each.local) {
            return tryParseSpecificReturnedSurveyResponse(
              action.payload.data,
              'completedSubmissions',
              each
            )
          }

          if (incompletedIds.has(each.localIdentifier) && each.local) {
            return tryParseSpecificReturnedSurveyResponse(
              action.payload.data,
              'toBeContinueSubmissions',
              each
            )
          }
          return each
        }
      )

      const localIds = new Set(
        updatedLocalResponses.map((item) => item.localIdentifier)
      )

      state.surveyResponses = [
        ...updatedLocalResponses,
        ...action.payload.data.toBeContinueSubmissions.filter(
          (item) => !localIds.has(item.localIdentifier)
        )
      ]

      if (action.payload.projectSiteTargets) {
        state.projectSiteTargets = action.payload.projectSiteTargets
      }

      state.syncRounds = (state.syncRounds != null ? state.syncRounds : 0) + 1
    })
    builder.addCase(syncSurveysAPI.rejected, (state) => {
      state.syncRounds = (state.syncRounds != null ? state.syncRounds : 0) + 1
    })
  }
})

export const {
  updateUserJourney,
  updateGrogDiaryUserJourney,
  updateGrogDiaryCurrentConsumption,
  updateGrogDiaryCurrentSelections,
  updateOutroUserJourney,
  updateService,
  updateUser,
  updateProjects,
  updateSurvey,
  updateDrinkingSession,
  logout,
  updateSurveyAnswer,
  updateSurveyMetadata,
  updateContinueLocalIdentifier,
  addCompletedSurvey,
  updateContinueSurveyResponse,
  updateMetadataByLocalIdentifier,
  updateValidatedSLK,
  updateLastQTime,
  updateAnalytics,
  updateLastLoginStatus
} = slice.actions
export default slice.reducer
