import { SurveyResponse } from './type'

export enum ServiceWorkerPreloadType {
  AUDIO = 'AUDIO',
  IMAGES = 'IMAGES'
}

export interface SyncSurveysAPIResultData {
  completedSubmissions: SurveyResponse[]
  toBeContinueSubmissions: SurveyResponse[]
}

export class AppAPIError extends Error {
  constructor(message: string) {
    super(message)
    this.name = this.constructor.name
  }
}
