import React from 'react'
import { IconPropsModel } from '../../Icon.model'

export const IconSmallFrown: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="88"
    height="87"
    viewBox="0 0 88 87"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.194 76.7961C83.504 67.3253 84.371 44.8234 84.371 44.8234C86.07 26.2761 78.4499 16.7203 68.7829 9.803C59.1125 2.88187 43.5658 1.48312 29.582 5.9259C16.8866 10.0212 9.6761 19.1437 9.6761 19.1437C9.6761 19.1437 -3.4519 34.4931 8.4621 57.5088C8.5201 57.7252 26.7636 101.781 67.194 76.7961Z"
      fill={props.fill || '#C57861'}
      stroke="#222222"
      strokeWidth="5.04542"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M62.3575 54.5085C60.5834 53.8329 56.8161 52.0507 53.5036 51.6757C50.8319 51.0621 48.561 51.0621 43.8857 51.0621C39.8782 51.6208 35.51 52.7227 33.5999 53.8329C30.5275 55.6188 28.2566 58.283 28.2566 58.283"
      stroke="#222222"
      strokeWidth="5.04542"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.9355 34.5235C27.3974 36.2303 29.414 36.8548 29.414 36.8548C31.0524 37.4587 32.1026 37.0005 32.9636 36.293C33.825 35.5852 34.3289 34.2113 34.2658 32.8372C34.203 31.5882 33.5519 30.7139 33.5519 30.7139C33.5519 30.7139 32.4805 29.1526 30.1072 29.673C30.0862 29.673 25.6541 30.2561 26.9355 34.5235Z"
      fill="#222222"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.3929 34.1105C58.8933 35.675 61.078 36.2475 61.078 36.2475C62.8528 36.8011 63.9906 36.3811 64.9233 35.7325C65.8564 35.0837 66.4023 33.8243 66.3341 32.5647C66.266 31.4197 65.5606 30.6183 65.5606 30.6183C65.5606 30.6183 64.3999 29.1871 61.8289 29.6642C61.8061 29.6642 57.0046 30.1987 58.3929 34.1105Z"
      fill="#222222"
    />
  </svg>
)
