import { Screen } from 'components/Screen/Screen.component'
import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { updateSurveyAnswer } from 'store/reducer'
import { ISurveyData, SurveyState } from 'store/type'
import { CommunityLocationsScreenPropsModel } from './CommunityLocationsScreen.model'
import styles from './CommunityLocationsScreen.module.scss'
import { AutoCompleteLocation } from 'components/AutoCompleteLocation/AutoCompleteLocation.component'
import { matchCondition } from 'shared/utils/matchCondition/matchCondition'
import { GeoLocation } from 'api/client.model'
import classnames from 'classnames'
import { shortcodesRender } from 'shared/utils/shortcodesRender/shortcodesRender'

export const CommunityLocationsScreenComponent: React.FC<
  CommunityLocationsScreenPropsModel
> = (props) => {
  const {
    currentScreenId,
    name,
    headerProps,
    screenData: { title, subtitle },
    footerProps,
    shortcodes,
    geoLocations
  } = props

  const dispatch: Dispatch<any> = useDispatch()
  const { getNextScreenId } = matchCondition()

  const [communityLocation, setCommunityLocation] =
    useState<GeoLocation | null>(null)

  const handleSetValue = (location: GeoLocation | null) => {
    if (!location) {
      setCommunityLocation(null)
      return
    }

    dispatch(
      updateSurveyAnswer({
        [name]: location
      } as ISurveyData)
    )
    setCommunityLocation(location)
  }

  const handleFooterProps = () => {
    const updatedFooterProps = { ...footerProps }
    if (!communityLocation) {
      updatedFooterProps.invalid = true
    } else {
      updatedFooterProps.invalid = false
    }

    const value = communityLocation
    // Next Screen Condition Logic
    updatedFooterProps.nextScreenId = getNextScreenId(
      value,
      footerProps.conditions,
      footerProps.nextScreenId
    )

    return updatedFooterProps
  }

  return (
    <Screen
      currentScreenId={currentScreenId}
      headerProps={headerProps}
      footerProps={handleFooterProps()}
    >
      <div className={styles.communitylocationsscreen}>
        <div className={styles['communitylocationsscreen-titles']}>
          {title && (
            <h1
              className={classnames(
                'screen-titles--title',
                styles['communitylocationsscreen-titles--title']
              )}
              dangerouslySetInnerHTML={{
                __html: shortcodesRender(shortcodes, title) || title
              }}
            />
          )}
          {subtitle && (
            <p
              className={classnames(
                'screen-titles--subtitle',
                styles['communitylocationsscreen-titles--subtitle']
              )}
              dangerouslySetInnerHTML={{
                __html: shortcodesRender(shortcodes, subtitle) || subtitle
              }}
            />
          )}
        </div>
        {geoLocations && (
          <div className={styles['communitylocationsscreen-inputWrapper']}>
            <AutoCompleteLocation
              dataList={geoLocations}
              value={communityLocation}
              setValue={handleSetValue}
            />
          </div>
        )}
      </div>
    </Screen>
  )
}

const mapStateToProps = (state: SurveyState) => ({
  geoLocations:
    state.user?.id === 'demo' ? state.demoGeoLocations : state.geoLocations
})

export const CommunityLocationsScreen = connect(mapStateToProps)(
  CommunityLocationsScreenComponent
)
