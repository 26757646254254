import type {
  EventDataModel,
  GrogSubTypeModel,
  GrogTypeCategoryModel,
  SurveyVoiceModel
} from 'api/client.model'
import type { FooterPropsModel } from 'components/Footer/Footer.model'
import type { HeaderPropsModel } from 'components/Header/Header.model'
import type { HTMLAttributes } from 'react'
import type { DeepReadonly } from 'shared/types/general'
import type {
  ISurveyData,
  GrogDiaryUserJourneyData,
  SurveyGrogDiaryCurrentState
} from 'store/type'
import type { genericObject } from '../type'

export interface GrogDiaryScreenAudioData {
  lastDay?: genericObject
  howManyPeople?: genericObject
  lastDayType?: genericObject
  whatDidYouDrinkOutOf?: genericObject
  howMuchPutInIt?: genericObject
  meOrGroup?: genericObject
  share?: genericObject
  anythingMoreToDrink?: genericObject
  allYouHadToDrink?: genericObject
  whatElseDidYouDrinkThatDay?: genericObject
  whenWasTheTimeBefore?: genericObject
  firstNonDetailedDate?: genericObject
  timeBeforeThat?: genericObject
}

export type GrogDiaryScreenLastDayAudioKey =
  | 'lastDay'
  | 'whenWasTheTimeBefore'
  | 'firstNonDetailedDate'
  | 'timeBeforeThat'

export type GrogDiaryScreenTypesCategoriesAudioKey =
  | 'lastDayType'
  | 'whatElseDidYouDrinkThatDay'

export interface GrogDiaryScreenData extends GrogDiaryScreenAudioData {
  requireDiary: number
  otherDayDiary: number
  groupStdDrinksThreshold?: number
  peopleImages: string[]
  justMeImage: string
  groupImage: string
}

export interface GrogDiaryScreenPropsModel extends HTMLAttributes<HTMLElement> {
  currentScreenId: string
  name: string
  screenData: GrogDiaryScreenData
  headerProps: HeaderPropsModel
  footerProps: FooterPropsModel
}

export interface GrogDiaryScreenStatePropsModel {
  events: EventDataModel[]
  birthday?: string
  surveyData?: ISurveyData
  voiceOver: SurveyVoiceModel[] | undefined
  typesCategories: DeepReadonly<(GrogTypeCategoryModel | GrogSubTypeModel)[]>
  grogDiaryUserJourney: readonly GrogDiaryUserJourneyData[]
  grogDiaryCurrentState: DeepReadonly<SurveyGrogDiaryCurrentState>
}

export enum GrogDiaryStep {
  LASTDAY,
  PEOPLE,
  TYPESCATEGORIES,
  SUBTYPE,
  BRAND,
  PRODUCT,
  PRODUCT_CONTAINER,
  CUSTOM_CONTAINER,
  IS_GROUP,
  SHARE,
  MORE,
  OTHERDATE,
  DIARY
}
