import React, { useState } from 'react'
import { ISurveyMetaData, SurveyState } from 'store/type'
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styles from './EnterParticipantsSLKScreen.module.scss'
import { EnterParticipantsSLKScreenComponentProps } from './EnterParticipantsSLKScreen.model'
import { colors } from 'shared/theme/theme'
import { AutoComplete } from 'components/AutoComplete/AutoComplete.component'
import { Button } from 'components/Button/Button.component'
import { Dispatch } from '@reduxjs/toolkit'
import {
  updateSurveyAnswer,
  updateSurveyMetadata,
  updateContinueLocalIdentifier,
  updateAnalytics,
  updateUserJourney,
  updateGrogDiaryUserJourney,
  updateGrogDiaryCurrentConsumption,
  updateOutroUserJourney,
  updateGrogDiaryCurrentSelections
} from 'store/reducer'
import { InputErrorMessages, InputFormat } from 'components/Input/Input.model'
import { formatDate } from 'shared/utils/formatTime/formatTime'
import { validateSLK } from 'shared/utils/validateValue/validateValue'
import {
  filterSlkByProjectId,
  getContinuingSlks
} from 'shared/utils/slkUtils/slkUtils'

const EnterParticipantsSLKScreenComponent: React.FC<
  EnterParticipantsSLKScreenComponentProps
> = (props) => {
  const navigate = useNavigate()
  const dispatch: Dispatch<any> = useDispatch()
  const {
    option,
    slkList,
    projectId,
    surveyMetaData,
    surveyResponses,
    surveyScreens,
    isContinueSurvey
  } = props

  const [SLKValue, setSLKValue] = useState<string>()
  const [errorText, setErrorText] = useState<string>()
  const [isValid, setValidStatus] = useState<boolean>(false)
  const [isSurveyFoundState, setSurveyFoundState] = useState<boolean>(false)
  const [nextScreenId, setNextScreenId] = useState<string>()
  const [lastCompleteDate, setlastCompleteDate] = useState<string>()

  let filteredSlkList: string[] | undefined

  // If continuing a survey, only include SLKs that have a survey stored
  if (isContinueSurvey) {
    filteredSlkList = getContinuingSlks(surveyResponses!, projectId)
  } else {
    filteredSlkList = filterSlkByProjectId(slkList, projectId)
  }

  const handleInputValue = (value: string, error?: string) => {
    if (error) {
      setErrorText(error)
      setValidStatus(false)
    } else if (!filteredSlkList?.includes(value)) {
      setErrorText('SLK not found.')
      setValidStatus(false)
    } else {
      setErrorText(undefined)
      setSLKValue(value)
      setValidStatus(true)
    }
  }

  const handleBackToDashboard = () => {
    navigate('/ra-dashboard/dashboard')
  }

  const validateInput = (value: string) => {
    if (!value) {
      return InputErrorMessages.empty
    }

    if (!validateSLK(value)) {
      return InputErrorMessages.slkFormat
    }

    return undefined
  }

  const handleSubmitSLK = () => {
    if (!isValid) return
    const latestScreenMatchSLK = surveyResponses?.find(
      (x) =>
        x.metadata?.slk?.trim() === SLKValue?.trim() &&
        x.metadata?.flaggedIncomplete &&
        !x.metadata?.flaggedDeleted &&
        x.metadata?.siteId == surveyMetaData?.siteId &&
        x.metadata?.projectId == surveyMetaData?.projectId
    )

    if (!isContinueSurvey) {
      if (latestScreenMatchSLK) {
        setErrorText('SLK already exists, please continue your survey!')
        return
      }

      const metadata: ISurveyMetaData = {
        ...surveyMetaData,
        slk: SLKValue
      }
      dispatch(updateSurveyMetadata(metadata))
      dispatch(updateContinueLocalIdentifier(undefined))
      navigate('/survey')
    } else {
      if (!latestScreenMatchSLK) {
        setErrorText('Not found')
        return
      }
      setSurveyFoundState(true)
      const enDate = formatDate(latestScreenMatchSLK.metadata!.endTime)
      setlastCompleteDate(enDate)
      dispatch(updateSurveyAnswer(latestScreenMatchSLK.responses))
      dispatch(
        updateUserJourney({
          userJourney: latestScreenMatchSLK.userJourney,
          continue: true
        })
      )
      dispatch(
        updateGrogDiaryUserJourney({
          actionType: 'continue',
          data: latestScreenMatchSLK.grogDiaryUserJourney
        })
      )
      dispatch(
        updateGrogDiaryCurrentConsumption({
          actionType: 'continue',
          data: latestScreenMatchSLK.grogDiaryCurrentState!.consumption
        })
      )
      dispatch(
        updateGrogDiaryCurrentSelections({
          actionType: 'continue',
          data: latestScreenMatchSLK.grogDiaryCurrentState!.selections
        })
      )
      dispatch(
        updateOutroUserJourney({
          actionType: 'continue',
          data: latestScreenMatchSLK.outroUserJourney
        })
      )
      dispatch(updateAnalytics(latestScreenMatchSLK.analytics))
      dispatch(
        updateContinueLocalIdentifier(latestScreenMatchSLK.localIdentifier)
      )
      dispatch(
        updateSurveyMetadata({
          ...latestScreenMatchSLK.metadata,
          resumeTime: new Date()
        })
      )
      if (latestScreenMatchSLK.metadata!.breakPoint) {
        setNextScreenId(latestScreenMatchSLK.metadata!.breakPoint)
      } else {
        const lastedField = Object.keys(latestScreenMatchSLK.responses!).at(-1)
        const lastScreen = surveyScreens?.find(
          (item) => item.surveyField === lastedField
        )
        setNextScreenId(lastScreen ? lastScreen.id : '')
      }
    }
  }

  return isSurveyFoundState && isContinueSurvey ? (
    <main className={styles.enterparticipantsslkscreen}>
      <h1
        style={{
          visibility: 'hidden',
          position: 'absolute',
          pointerEvents: 'none'
        }}
      >
        Enter Participant SLK
      </h1>
      <div className={styles['enterparticipantsslkscreen-content']}>
        <div
          className={
            styles['enterparticipantsslkscreen-content-foundedtextcontainer']
          }
        >
          <h2
            className={
              styles[
                'enterparticipantsslkscreen-content-foundedtextcontainer-title'
              ]
            }
          >
            You’ve successfully found an existing participant.
          </h2>
          <p>Participant last completed a survey on {lastCompleteDate}</p>
        </div>
        <div className={styles['enterparticipantsslkscreen-content-buttons']}>
          <Button
            variation="secondary"
            width="l"
            onClick={handleBackToDashboard}
          >
            Back
          </Button>
          <Button
            variation="primary"
            width="l"
            onClick={() => navigate(`/survey/${nextScreenId}`)}
          >
            Next
          </Button>
        </div>
      </div>
    </main>
  ) : (
    <div className={styles.enterparticipantsslkscreen}>
      <div className={styles['enterparticipantsslkscreen-content']}>
        <h2>Please enter the participant&apos;s code</h2>
        <div
          className={
            styles['enterparticipantsslkscreen-content-inputcontainer']
          }
        >
          <AutoComplete
            className={
              styles['enterparticipantsslkscreen-content-inputcontainer-input']
            }
            dataList={filteredSlkList || []}
            inputOption={option}
            setValue={(value, error) => handleInputValue(value, error)}
            validateValue={validateInput}
            style={{ backgroundColor: colors.white }}
          />
          <p
            className={
              styles[
                'enterparticipantsslkscreen-content-inputcontainer-errortext'
              ]
            }
          >
            {errorText}
          </p>
        </div>
        <div className={styles['enterparticipantsslkscreen-content-buttons']}>
          <Button
            variation="secondary"
            width="l"
            onClick={handleBackToDashboard}
          >
            Back
          </Button>
          <Button
            variation="primary"
            width="l"
            onClick={() => handleSubmitSLK()}
            disabled={!isValid}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: SurveyState) => ({
  slkList: state.slkList,
  projectId: state.surveyMetaData?.projectId || '',
  surveyData: state.surveyData,
  surveyMetaData: state.surveyMetaData,
  surveyResponses: state.surveyResponses,
  surveyScreens: state.survey?.screens,
  option: {
    label: 'Participants SLK',
    value: null,
    required: false,
    allowMultipleLines: false,
    inputFormat: InputFormat.slk
  }
})

export const EnterParticipantsSLKScreen = connect(mapStateToProps)(
  EnterParticipantsSLKScreenComponent
)
