import React, { useState } from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import DrinksCalcTable from './DrinksCalcTable'
import {
  AlcoholCalc,
  calcAlcoholRatio,
  mapDiaryDetailToCalcParams
} from 'shared/utils/alcoholCalculation/alcoholCalculation'
import type { AlcoholCalcModel } from 'shared/utils/alcoholCalculation/alcoholCalculation.model'

type ContextMenuRAProps = {
  children: React.ReactNode
  className: string | undefined
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center'
}

const transformData = (alcoholCalcModel: AlcoholCalcModel) => {
  const data = alcoholCalcModel.getConsumptions()
  return data.map((elem) => {
    const drinkAmount =
      (elem.drinkAmounts.individualAmount ?? 0) /
      (elem.subContainer?.amount || 1)
    const grogRatio = calcAlcoholRatio(elem)

    const product = [
      elem.brand,
      elem?.product?.name,
      elem?.container?.name,
      elem.typecategory?.name !== 'Other drinks' && elem.typecategory?.name,
      elem.subtype?.name !== 'Poured By You or Someone Else' &&
        elem.subtype?.name
    ]
      .filter(Boolean)
      .join(', ')

    const elemCalcData = mapDiaryDetailToCalcParams(elem)
    const stdDrinks = alcoholCalcModel.calcStandardDrinks(elemCalcData)
    const totalVolume = alcoholCalcModel.capacityOfDrink(elemCalcData)

    return {
      product,
      numberConsumed: drinkAmount,
      numberPercentFull: grogRatio * 100,
      volumeMl: totalVolume,
      alcPercentVol: elem.alcoholPercentage || 100,
      alcMl: totalVolume * ((elem.alcoholPercentage ?? 0) / 100),
      standardDrinks: stdDrinks,
      gramsAlcohol: alcoholCalcModel.calcGramsOfDrink(elemCalcData)
    }
  })
}

const ContextMenuRA = ({ children, className }: ContextMenuRAProps) => {
  const [modalOpen, setModalOpen] = useState(false)
  const alcoholCalcModel = AlcoholCalc()

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  const handleRightClick = (event: React.MouseEvent) => {
    event.preventDefault()
    toggleModal()
  }

  const handleTouchStart = (event: React.TouchEvent) => {
    if (event.touches.length === 3) {
      toggleModal()
    }
  }

  const dataRows = transformData(alcoholCalcModel)

  return (
    <Box
      className={className}
      onContextMenu={handleRightClick}
      onTouchStart={handleTouchStart}
    >
      {children}
      <Modal
        open={modalOpen}
        onClose={toggleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Box sx={{ mt: 2, width: '100%', maxHeight: '80%' }}>
            <DrinksCalcTable rows={dataRows} />
          </Box>

          <button
            onClick={() => toggleModal()}
            style={{
              padding: '20px 40px',
              border: 'none',
              color: 'white',
              background: 'black',
              borderRadius: 10
            }}
          >
            Ok
          </button>
        </Box>
      </Modal>
    </Box>
  )
}

export default ContextMenuRA
