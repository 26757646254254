import type { EmblaCarouselType } from 'embla-carousel'
import { useCallback, useEffect, useState } from 'react'
import type { PrevNextButtonsHookType } from './hooks.model'

/**
 * Hook to allow for "previous" and "next" buttons to be used with a carousel managed by
 * Embla Carousel, as based on Embla Carousel's code example
 * (https://codesandbox.io/p/sandbox/7596p3).
 *
 * @param emblaApi - The API object for Embla Carousel.
 *
 * @returns An interface for creating buttons to interact with a carousel managed by Embla
 * Carousel.
 */
export const usePrevNextButtons = (
  emblaApi: EmblaCarouselType | undefined
): PrevNextButtonsHookType => {
  const [prevButtonDisabled, setPrevButtonDisabled] = useState<boolean>(true)
  const [nextButtonDisabled, setNextButtonDisabled] = useState<boolean>(true)

  const onPrevButtonClick = useCallback(() => {
    emblaApi?.scrollPrev()
  }, [emblaApi])

  const onNextButtonClick = useCallback(() => {
    emblaApi?.scrollNext()
  }, [emblaApi])

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevButtonDisabled(!emblaApi.canScrollPrev())
    setNextButtonDisabled(!emblaApi.canScrollNext())
  }, [])

  useEffect(() => {
    if (emblaApi == null) {
      return
    }

    onSelect(emblaApi)
    emblaApi.on('reInit', onSelect).on('select', onSelect)
  }, [emblaApi, onSelect])

  return {
    prevButtonDisabled,
    nextButtonDisabled,
    onPrevButtonClick,
    onNextButtonClick
  }
}
