import type { GrogSubTypeModel } from 'api/client.model'
import type { CalendarEvent } from 'components/GrogConsumptions/CalendarMultiSelect/CalendarMultiSelect.model'
import type { DeepReadonly } from 'shared/types/general'
import { ordinal_suffix_of } from '../ordinalSuffix/ordinalSuffix'
import type { GrogDiary } from './grogConsumptionState.model'
import * as iterUtils from 'shared/utils/iterUtils/iterUtils'

/**
 * Extract all dates from a user's Grog Diary record into a list of ISO 8601 date strings.
 *
 * @param diary - A user's Grog Diary record.
 *
 * @returns A list of ISO 8601 date strings extract from the keys of the provided Grog
 * Diary record object from `diary`.
 */
export const getDateStringsFromDiary = (
  diary: DeepReadonly<GrogDiary> | undefined
): string[] => {
  return diary ? Object.keys(diary) : []
}

/**
 * Map a list of ISO 8601 date strings to a list of {@link CalendarEvent} instances.
 *
 * The `name` of each {@link CalendarEvent} instance will be the *n*th last day of some
 * user's Grog Diary consumption records, depending on the order of the date string within
 * the provided array.
 *
 * @param dateStrings - A list of ISO 8601 date strings.
 *
 * @returns A list of {@link CalendarEvent} instances created from the ISO 8601 date
 * strings provided in `dateStrings`.
 */
const mapDateStringsToCalendarEvents = (
  dateStrings: string[]
): CalendarEvent[] => {
  if (dateStrings.length === 0) {
    // Default: empty array
    return []
  }

  return dateStrings.map((dateString, index) => ({
    name: index === 0 ? 'Last day' : `${ordinal_suffix_of(index + 1)} last day`,
    date: new Date(dateString),
    selectedDate: true
  }))
}

/**
 * Map dates recorded in a user's Grog Diary to a list of {@link CalendarEvent} instances.
 *
 * @param diary - A user's Grog Diary data.
 *
 * @returns A list of all dates within the provided Grog Diary converted into
 * {@link CalendarEvent} instances.
 */
export const getCalendarEventsFromDiary = (
  diary: DeepReadonly<GrogDiary> | undefined
): CalendarEvent[] => {
  const dateStrings = getDateStringsFromDiary(diary)
  return mapDateStringsToCalendarEvents(dateStrings)
}

/**
 * Check whether the current subtype of alcohol has any brands listed within it.
 *
 * @param type - The subtype of alcohol which will be checked.
 *
 * @returns `true` if the subtype has brands, `false` otherwise.
 */
export const grogTypeHasBrands = (
  type: DeepReadonly<GrogSubTypeModel>
): boolean => {
  return type.hasBrand || type.brands != null
}

/**
 * Get the data model of some Grog Shop data type who's `name` property matches a provided
 * name, or otherwise, return the first data model instances in an array of all choices.
 *
 * @param choices - All data model instance of a specific Grog Shop data type.
 * @param name - The name of some matching Grog Shop data model instance we aim to find.
 *
 * @returns The data model instance who's `name` property matches the provided `name`,
 * otherwise, the first data model instance in `choices`.
 */
const getChoiceMatchingName = <T extends { name: string }>(
  choices: readonly T[],
  name: string
): T => {
  if (choices.length === 0) {
    throw new Error("'choices' array must not be an empty")
  }

  return iterUtils.findLastOrElse(
    choices,
    (value) => value.name === name,
    choices[0]
  )
}

/**
 * Map the names of Grog Shop model choices (such as a type/category, subtype, or brand)
 * to the actual data sourced from the Grog Shop manifest JSON.
 *
 * @param choiceNames - The names of all choices relating to a specific type of Grog Shop
 * data model.
 * @param allChoices - All possible choices for the target Grog Shop data model.
 *
 * @returns An array of all Grog Shop data models who's `name` property matched the
 * correspondingly provided name in the `choiceNames` array. The items in this array will
 * be returned in the same order of their matched names from `choiceNames`.
 */
export const mapChoiceNamesToChoices = <T extends { name: string }>(
  choiceNames: string[],
  allChoices: readonly T[]
): T[] => {
  return choiceNames.map((choiceName) => {
    return getChoiceMatchingName(allChoices, choiceName)
  })
}

/**
 * Set a state variable for the app (in the Redux state) if it is not `undefined`.
 *
 * @param value - The value which may be stored in the app's state if it is not
 * `undefined`.
 * @param setStateCallback - The callback to set `value` in the app's state.
 */
export const setStateIfDefined = <T>(
  value: T | undefined,
  setStateCallback: (value: T) => void
) => {
  if (value !== undefined) {
    setStateCallback(value)
  }
}
