import React, { useContext, useEffect, useState } from 'react'
import {
  OptionalButton,
  OptionalButtonsPropsModel
} from './OptionalButtons.model'
import styles from './OptionalButtons.module.scss'
import classnames from 'classnames'
import { Button } from 'components/Button/Button.component'
import { Box } from '@mui/material'
import { colors } from 'shared/theme/theme'
import { AudioContext } from 'context/AudioContext'
import { useColourPicker } from 'context/ColourContext'

export const OptionalButtons = <T = string,>(
  props: OptionalButtonsPropsModel<T>
): JSX.Element => {
  const {
    className,
    wide,
    reset,
    optionalButtons,
    onButtonSelect,
    ...componentProps
  } = props
  const { secondaryColour } = useColourPicker()
  const { playAudio } = useContext(AudioContext)
  const [selectedIndex, setSelectedIndex] = useState<number>()

  const handleClick = (index: number, item: OptionalButton<T>) => {
    onButtonSelect(item.value)
    setSelectedIndex(index)

    // Play button audio
    if (item?.voices && item?.id) playAudio?.(`${item?.id}-button`)
  }

  const calcColumn = (index: number) => {
    let end0 = '4/6'
    let end1 = '3/5'
    let end2 = '5/7'

    if (wide) {
      end0 = '3/7'
      end1 = '2/5'
      end2 = '5/8'
    }

    if (optionalButtons.length > 1) {
      return index === 0 ? end1 : end2
    } else {
      return end0
    }
  }

  useEffect(() => {
    setSelectedIndex(undefined)
  }, [reset, optionalButtons])

  return (
    <div
      className={classnames(className, styles.optionalbuttons)}
      {...componentProps}
    >
      <Box display="grid" gridTemplateColumns="repeat(8, 1fr)" gap="20px">
        {optionalButtons.map((item, index) => (
          <Box key={index} gridColumn={calcColumn(index)}>
            <Button
              variation="secondary"
              onClick={() => handleClick(index, item)}
              style={{
                padding: '20px 0',
                color:
                  selectedIndex === index
                    ? `${colors.white} !important`
                    : undefined,
                backgroundColor:
                  selectedIndex === index
                    ? `${secondaryColour || colors.tundora}`
                    : undefined
              }}
            >
              {item.label}
            </Button>
          </Box>
        ))}
      </Box>
    </div>
  )
}
