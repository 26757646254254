import { Screen } from 'components/Screen/Screen.component'
import React, { useEffect, useState } from 'react'
import { TextScreenPropsModel } from './TextScreen.model'
import styles from './TextScreen.module.scss'
import classnames from 'classnames'
import { Icon } from 'components/Icons/Icon.component'
import { IconType } from 'components/Icons/Icon.model'
import { matchCondition } from 'shared/utils/matchCondition/matchCondition'
import {
  outroGlobalShortcodesRender,
  shortcodesRender
} from 'shared/utils/shortcodesRender/shortcodesRender'
import { GLOBAL_SHORTCODES } from 'shared/constants/shortcodes/Shortcodes.d'
import {
  CANNABIS_TYPE,
  GROG_DIARY,
  OPIOIDS_TYPE,
  TOBACCO_TYPE,
  BENZOS_TYPE,
  METH_TYPE,
  HEROIN_TYPE,
  OTHER_TYPE
} from 'shared/constants/SurveyFields.d'
import { SurveyState } from 'store/type'
import { connect } from 'react-redux'
import { useColourPicker } from 'context/ColourContext'

export const TextScreenComponent: React.FC<TextScreenPropsModel> = (props) => {
  const {
    currentScreenId,
    headerProps,
    footerProps,
    shortcodes,
    screenData: {
      title,
      subtitle,
      image,
      centerAlign,
      withTick,
      altText,
      sideLayout
    },
    states
  } = props

  const { secondaryColour } = useColourPicker()

  const { getNextScreenId } = matchCondition()

  const [types, setTypes] = useState<string[]>([])

  const handleFooterProps = () => {
    const updatedFooterProps = { ...footerProps }

    if (updatedFooterProps.nextScreenId) {
      updatedFooterProps.nextScreenId = getNextScreenId(
        undefined,
        footerProps.conditions,
        footerProps.nextScreenId
      )
    }

    return updatedFooterProps
  }

  useEffect(() => {
    const includeTypes = []

    if (states && title.includes(GLOBAL_SHORTCODES.OUTRO_USING_TYPES)) {
      // has grog
      if (states[GROG_DIARY]) {
        includeTypes.push('grog')
      }
      // has tobacco
      if (states[TOBACCO_TYPE]) {
        includeTypes.push('tobacco')
      }
      // has cannabis
      if (states[CANNABIS_TYPE]) {
        includeTypes.push('cannabis')
      }
      // has meth
      if (states[METH_TYPE]) {
        includeTypes.push('meth')
      }
      // has heroin
      if (states[HEROIN_TYPE]) {
        includeTypes.push('heroin')
      }
      // has opioid
      if (states[OPIOIDS_TYPE[0]] || states[OPIOIDS_TYPE[1]]) {
        includeTypes.push('opioid')
      }
      // has benzos
      if (states[BENZOS_TYPE]) {
        includeTypes.push('benzos')
      }
      // has other
      if (states[OTHER_TYPE]) {
        includeTypes.push('other')
      }
      setTypes(includeTypes)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classnames('drug-app-screen', styles.textscreen)}>
      <Screen
        currentScreenId={currentScreenId}
        headerProps={headerProps}
        footerProps={handleFooterProps()}
      >
        <div
          className={classnames(styles['textscreen-wrapper'], {
            [styles['textscreen-wrapper--center']]: centerAlign,
            [styles['textscreen-wrapper--side']]: sideLayout
          })}
        >
          <div>
            {title && (
              <h1
                className="screen-titles--title"
                dangerouslySetInnerHTML={{
                  __html:
                    shortcodesRender(
                      shortcodes,
                      outroGlobalShortcodesRender(
                        GLOBAL_SHORTCODES.OUTRO_USING_TYPES,
                        title,
                        types
                      )
                    ) ||
                    outroGlobalShortcodesRender(
                      GLOBAL_SHORTCODES.OUTRO_USING_TYPES,
                      title,
                      types
                    )
                }}
              />
            )}
            {subtitle && (
              <p
                className="screen-titles--subtitle"
                dangerouslySetInnerHTML={{ __html: subtitle }}
              />
            )}
          </div>
          {withTick ? (
            <div className={styles['textscreen-icon']}>
              <Icon icon={IconType.Tick} size={136} fill={secondaryColour} />
            </div>
          ) : (
            <>
              {image && (
                <div className={styles['textscreen-image']}>
                  <img
                    src={image}
                    alt={altText || ''}
                    role={!altText ? 'presentation' : undefined}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </Screen>
    </div>
  )
}

const mapStateToProps = (state: SurveyState) => ({
  states: state.surveyData
})

export const TextScreen = connect(mapStateToProps)(TextScreenComponent)
