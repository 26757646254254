import React, { useRef } from 'react'
import { Box } from '@mui/material'
import { Icon } from 'components/Icons/Icon.component'
import { IconType } from 'components/Icons/Icon.model'
import { GrogDiaryDataDetail } from 'store/type'
import { Container } from 'components/GrogConsumptions/Container/Container.component'
import { Product } from 'components/GrogConsumptions/Product/Product.component'
import { Button } from 'components/Button/Button.component'
import { SummaryProps } from './Summary.model'
import classnames from 'classnames'
import styles from './Summary.module.scss'
import * as iterUtils from 'shared/utils/iterUtils/iterUtils'

// To wrap Container in React.memo to prevent unnecessary re-renders
// (I am not sure why the same implementation does not work for Product)
const ContainerMemo = React.memo(Container)

export const Summary: React.FC<SummaryProps> = ({
  updatedConsumptionList,
  onDiaryIncrease,
  onDiaryDecrease,
  isOutro
}) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const scrollOffset = 100

  const handleScrollUp = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        top: -scrollOffset,
        left: 0,
        behavior: 'smooth'
      })
    }
  }

  const handleScrollDown = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        top: scrollOffset,
        left: 0,
        behavior: 'smooth'
      })
    }
  }

  const renderDrink = (item: GrogDiaryDataDetail) => {
    const isContainer = item.container.name ? true : false
    let productsContainersDisplayCount

    if (isContainer) {
      productsContainersDisplayCount = item.drinkAmounts.individualAmount ?? 0
    } else {
      const isPack = item.subContainer?.amount === 1 ? false : true
      const drinkAmount = Number(item.drinkAmounts.individualAmount)
      const step = Number(
        isPack ? item.subContainer?.amount : item.subContainer?.stepsInEach
      )
      productsContainersDisplayCount = Math.ceil(drinkAmount / step)
    }

    if (isContainer) {
      return (
        <div className={styles['summary__list-item-grid']}>
          {iterUtils
            .range(item.drinkAmounts.individualAmount ?? 0)
            .map((num) => (
              <div
                key={`container-${item.uuid}-${num}`}
                className={classnames(styles['summary__list-item-container'])}
              >
                <ContainerMemo
                  image={item.container.image}
                  fillMaskImage={item.container.fillMaskImage}
                  topBorder={item.container.topBorder}
                  bottomBorder={item.container.bottomBorder}
                  min={0}
                  max={100}
                  maxHeight={100}
                  drinkValue={item.container.grog}
                  fizzyDrinkValue={
                    (item.container.grog != null ? item.container.grog : 0) +
                    (item.container.fizzy != null ? item.container.fizzy : 0)
                  }
                  drinkColor={item.container.colour}
                />
              </div>
            ))}
        </div>
      )
    } else {
      const isPack = item.subContainer?.amount === 1 ? false : true
      const drinkAmount = Number(item.drinkAmounts.individualAmount)
      const step = Number(
        isPack ? item.subContainer?.amount : item.subContainer?.stepsInEach
      )
      const max = Math.ceil(drinkAmount / Number(step))

      return (
        <div className={styles['summary__list-item-grid']}>
          {iterUtils.range(max).map((num) => {
            const clipPercentage =
              100 -
              (drinkAmount - (num + 1) * step > 0
                ? 1
                : (drinkAmount - num * step) / step) *
                100
            return (
              <div
                key={`product-${item.uuid}-${num}`}
                className={classnames(styles['summary__list-item-container'])}
              >
                <Product
                  isPack={isPack}
                  clipPercentage={clipPercentage}
                  image={item.product?.image || ''}
                  style={{ width: '7vw' }}
                />
              </div>
            )
          })}
        </div>
      )
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <Button
        aria-label="ChevronUp"
        onClick={handleScrollUp}
        noBorder={true}
        className={classnames(
          styles.summary__button,
          styles.summary__button__up
        )}
      >
        <Icon icon={IconType.ChevronUp} size={10} className="icon" />
      </Button>

      <Box sx={{ width: '100%', overflow: 'hidden' }}>
        <div
          className={classnames(styles['summary__container'])}
          style={{
            maxHeight: window.outerHeight < 800 && isOutro ? 285 : undefined
          }}
          ref={scrollRef}
        >
          {updatedConsumptionList &&
            Object.keys(updatedConsumptionList).map((key) => (
              <div
                key={`section-${key}`}
                className={classnames(styles['summary__list'])}
              >
                <h4 className={classnames(styles['summary__type-title'])}>
                  {key}
                </h4>
                <div style={{ height: '100%', overflowY: 'auto' }}>
                  {updatedConsumptionList[key].map(
                    (item: GrogDiaryDataDetail) => (
                      <div
                        key={`section-${key}-${item.uuid}`}
                        className={classnames(styles['summary__list-item'])}
                      >
                        <div
                          className={classnames(
                            styles['summary__list-item-info']
                          )}
                        >
                          <h4>{item.brand || item.subtype?.name}</h4>
                          <h5>{item.container.name || item.product?.name}</h5>
                        </div>
                        <div
                          className={classnames(
                            styles['summary__list-item-wrapper']
                          )}
                        >
                          {!isOutro ? (
                            <Button
                              aria-label="Decrease"
                              onClick={() => onDiaryDecrease(item.uuid)}
                              noBorder={true}
                              disabled={
                                item.drinkAmounts.individualAmount != null &&
                                item.drinkAmounts.individualAmount <= 1
                              }
                            >
                              <Icon
                                className={classnames(
                                  styles['summary__decrease-icon']
                                )}
                                icon={IconType.Decrease}
                                size={36}
                              />
                            </Button>
                          ) : null}
                          <div
                            className={classnames(
                              styles['summary__list-item-drink']
                            )}
                          >
                            {renderDrink(item)}
                          </div>
                          {!isOutro ? (
                            <Button
                              aria-label="Increase"
                              onClick={() => onDiaryIncrease(item.uuid)}
                              noBorder={true}
                            >
                              <Icon icon={IconType.Increase} size={36} />
                            </Button>
                          ) : null}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            ))}
        </div>
      </Box>

      <Button
        aria-label="ChevronDown"
        onClick={handleScrollDown}
        noBorder={true}
        className={classnames(
          styles.summary__button,
          styles.summary__button__down
        )}
      >
        <Icon icon={IconType.ChevronDown} size={10} className="icon" />
      </Button>
    </div>
  )
}
