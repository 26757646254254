import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconChevronUp: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    width="57"
    height="56"
    viewBox="0 0 57 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2739 10.1534C6.19931 14.2905 3.57703 20.8209 4.056 30.4001C4.78317 44.9436 16.3178 50.8222 22.2156 51.9938C27.5838 51.8026 35.4412 50.3865 41.8219 46.8282C48.2121 43.2647 52.9195 37.7059 52.5602 29.0833C52.1851 20.0796 48.3327 13.8644 43.3041 9.86534C38.2199 5.82202 31.8693 4 26.5585 4C20.1828 4 14.3697 5.99491 10.2739 10.1534ZM7.42407 7.34658C12.4387 2.25509 19.3755 0 26.5585 0C32.689 0 39.9384 2.07798 45.7939 6.73466C51.705 11.4356 56.1319 18.7204 56.5568 28.9167C56.9975 39.4941 51.0382 46.2687 43.7701 50.3218C36.5788 54.332 27.913 55.8261 22.1182 55.9991L21.9055 56.0055L21.6962 55.9669C14.8604 54.7077 0.925282 47.8858 0.0609864 30.5999C-0.460051 20.1791 2.38835 12.4595 7.42407 7.34658Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3642 31.651C12.7286 30.7477 12.9455 29.5001 13.8489 28.8644L27.3489 19.3644C28.05 18.871 28.9877 18.8794 29.6799 19.3852L42.6799 28.8852C43.5717 29.5369 43.7663 30.7882 43.1146 31.68C42.4629 32.5719 41.2116 32.7665 40.3198 32.1148L28.4779 23.461L16.1508 32.1356C15.2475 32.7713 13.9999 32.5543 13.3642 31.651Z"
      fill="black"
    />
  </svg>
)
