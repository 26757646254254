import { ProjectDataModel, SLKResponseModel } from 'api/client.model'
import { IconType } from 'components/Icons/Icon.model'
import { SurveyResponse } from 'store/type'

export const SurveyStatusIcon = {
  deletion: IconType.Delete,
  complete: IconType.Complete,
  incomplete: IconType.Incomplete,
  technical: IconType.Technical,
  synced: IconType.Complete,
  forsync: IconType.Syncing,
  // existingSLK: IconType.ExistingSLK,
  invalidSLK: IconType.InvalidSLK
}
export interface SurveyRowModel {
  id: number
  slk?: string
  gender?: string
  age?: string
  time?: Date
  ra?: string
  status?: keyof typeof SurveyStatusIcon
}

export interface SurveysScreenComponentProps {
  offline: boolean
  serviceId?: string
  slkList?: SLKResponseModel
  syncRounds?: number
  projects: ProjectDataModel[]
  projectId: string
  siteId: string
  surveyResponses?: SurveyResponse[]
}
