import React from 'react'
import { GrogDiaryPropsModel } from './GrogDiary.model'
import { Grid } from '@mui/material'
import { Icon } from 'components/Icons/Icon.component'
import { IconType } from 'components/Icons/Icon.model'
import { GrogDiaryDataDetail } from 'store/type'
import { Button } from 'components/Button/Button.component'
import { colors } from 'shared/theme/theme'
import { Summary } from 'components/GrogConsumptions/Summary/Summary.component'
import styles from './GrogDiary.module.scss'
import classnames from 'classnames'
import ContextMenuRA from 'components/ContextMenuRA/ContextMenuRA'
import { useColourPicker } from 'context/ColourContext'

export const GrogDiary: React.FC<GrogDiaryPropsModel> = (props) => {
  const {
    title,
    date,
    consumptions,
    isOutro = false,
    onDiaryIncrease,
    onDiaryDecrease,
    onYesClick,
    onNoClick
  } = props

  const { primaryColour, secondaryColour } = useColourPicker()

  // filter the item whose drinkAmount is 0
  const updatedConsumptionList = consumptions
    ?.filter((item) => Boolean(item?.drinkAmounts.individualAmount))
    ?.reduce((acc, cv) => {
      const key = cv.typecategory?.name || cv.subtype?.name
      if (!key) {
        return acc
      }
      if (!acc[key]) {
        acc[key] = []
      }

      acc[key].push(cv)

      return acc
    }, {} as { [key: string]: GrogDiaryDataDetail[] })

  return (
    <ContextMenuRA className={classnames(styles.grogdiary)}>
      <h2 className={classnames(styles['grogdiary__title'])}>{title}</h2>

      <h3 className={classnames(styles['grogdiary__date'])}>
        <Icon
          className={classnames(styles['grogdiary__icon'])}
          icon={IconType.Calendar}
          size={36}
          style={{ marginRight: '5px' }}
          stroke={secondaryColour}
          fill={primaryColour}
        />
        {new Date(date).toLocaleString('en-AU', {
          month: 'long',
          day: 'numeric'
        })}
      </h3>

      <Summary
        updatedConsumptionList={updatedConsumptionList}
        onDiaryIncrease={onDiaryIncrease}
        onDiaryDecrease={onDiaryDecrease}
        isOutro={isOutro}
      />

      {!isOutro ? (
        <Grid
          container={true}
          spacing={2}
          justifyContent="center"
          alignItems="center"
          rowSpacing={0}
          className={styles['grogdiary__action']}
        >
          <Grid item={true} xs={4}>
            <Button
              variation="secondary"
              width="full"
              dropShadow={true}
              style={{
                backgroundColor: colors.white
              }}
              onClick={onNoClick}
            >
              No, I had other grog too
            </Button>
          </Grid>
          <Grid item={true} xs={4}>
            <Button
              variation="secondary"
              width="full"
              dropShadow={true}
              disabled={Object.keys(updatedConsumptionList).length === 0}
              style={{
                backgroundColor: colors.white
              }}
              onClick={onYesClick}
            >
              Yes, this is all I had to drink
            </Button>
          </Grid>
        </Grid>
      ) : null}
    </ContextMenuRA>
  )
}
