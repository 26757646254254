import React, { useCallback, useState } from 'react'
import styles from './Container.module.scss'
import classnames from 'classnames'
import { ContainerPropsModel } from './Container.model'
import { colors } from 'shared/theme/theme'
import { GROG_SHOP_CONTAINER_IMAGE_HEIGHT } from 'shared/constants/Constants.d'

export const Container: React.FC<ContainerPropsModel> = (props) => {
  const {
    image,
    fillMaskImage,
    max = 100,
    topBorder = 0,
    bottomBorder = 0,
    drinkValue = 0,
    fizzyDrinkValue = 0,
    drinkColor,
    maxHeight,
    height
  } = props

  const [loaded, setLoaded] = useState(false)
  const [maskStyles, setMaskStyles] = useState({
    fizzyDrinkClipPath: '',
    drinkClipPath: ''
  })

  const handleImageRef = useCallback(
    (node: HTMLImageElement) => {
      const handleImageLoad = (imageElement: HTMLImageElement) => {
        // The default height of the grog container images / fill mask images
        // has been set to 760 in pixels (in Umbraco)
        const naturalHeight =
          imageElement.naturalHeight || GROG_SHOP_CONTAINER_IMAGE_HEIGHT
        const currentHeight = imageElement.height || height || maxHeight || 0

        const currentMaskTopBorder = isNaN(naturalHeight)
          ? 0
          : (currentHeight * topBorder) / naturalHeight
        const currentMaskBottomBorder = isNaN(naturalHeight)
          ? 0
          : (currentHeight * bottomBorder) / naturalHeight

        const maskHeight =
          currentHeight - currentMaskTopBorder - currentMaskBottomBorder
        const maskTopBorder = currentMaskTopBorder
        const maskBottomBorder = currentMaskBottomBorder

        setMaskStyles({
          fizzyDrinkClipPath: `inset(calc(${maskTopBorder}px + ${
            maskHeight * ((max - fizzyDrinkValue) / max)
          }px) 0% ${maskBottomBorder}px 0%)`,
          drinkClipPath: `inset(calc(${maskTopBorder}px + ${
            maskHeight * ((max - drinkValue) / max)
          }px) 0% ${maskBottomBorder}px 0%)`
        })

        setLoaded(true)
      }
      if (node !== null) {
        handleImageLoad(node)
      }
    },
    [
      topBorder,
      bottomBorder,
      fizzyDrinkValue,
      drinkValue,
      max,
      height,
      maxHeight
    ]
  )

  return (
    <div className={classnames(styles['container__wrapper'])}>
      <img
        ref={handleImageRef}
        className={classnames(styles['container__image'])}
        style={{
          height,
          maxHeight
        }}
        src={image}
        role="presentation"
        alt=""
      />
      {loaded && (
        <>
          <div
            className={classnames(styles['container__mask'])}
            style={{
              WebkitMaskImage: `url(${fillMaskImage})`,
              backgroundColor: fizzyDrinkValue ? colors.fizzyDrink : undefined,
              clipPath: maskStyles.fizzyDrinkClipPath
            }}
          />
          <div
            className={classnames(styles['container__mask'])}
            style={{
              WebkitMaskImage: `url(${fillMaskImage})`,
              backgroundColor:
                drinkValue !== 0 ? drinkColor || colors.black : undefined,
              clipPath: maskStyles.drinkClipPath
            }}
          />
        </>
      )}
    </div>
  )
}
