import React from 'react'
import { Footer } from 'components/Footer/Footer.component'
import { Header } from 'components/Header/Header.component'
import { ScreenPropsModel } from './Screen.model'
import styles from './Screen.module.scss'

export const Screen: React.FC<ScreenPropsModel> = (props) => {
  const { currentScreenId, children, headerProps, footerProps } = props

  return (
    <div id={currentScreenId} className={styles.screen}>
      <Header {...headerProps} />
      <main className={styles.container}>{children}</main>
      <Footer {...footerProps} />
    </div>
  )
}
