import type { DeepMutable } from 'shared/types/general'
import type { AssetUrls } from 'store/type'

/**
 * Perform a regex check to see whether the file extension of a URL matches a conventional
 * image type.
 *
 * @param url - A URL string to check.
 *
 * @returns `true` if the URL has a file extension which matches a conventional image
 * type, `false` otherwise.
 */
const isImage = (url: string): boolean => {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url)
}

/**
 * Extract URLs for image and audio assets from a JSON object or array, and store these in
 * the passed `cacheUrls` object.
 *
 * @param cacheUrls - The object storing audio and image URLs for assets to be cached.
 * This object will be mutated in-place by this function.
 * @param data - The object for which image and audio URLs will be extracted from.
 */
const extractAssetUrlsFromObject = (
  cacheUrls: DeepMutable<AssetUrls>,
  data: object
) => {
  const { audio, images } = cacheUrls

  JSON.stringify(data, (_key, val) => {
    if (typeof val === 'string') {
      if (isImage(val) && images.indexOf(val) < 0) {
        images.push(val)
      } else if (val.endsWith('.mp3') && audio.indexOf(val) < 0) {
        audio.push(val)
      }
    }
    return val
  })

  // Mutate object in place, don't return
}

/**
 * Extract URLs for image and audio assets which are described in a object sourced from
 * JSON data (which may be a JSON object or array).
 *
 * @param dataObjects - All objects where image and audio assets will be extracted from,
 * passed to the function as multiple arguments.
 *
 * @returns An {@link AssetUrls} object which contains all audio and image URLs sourced
 * from the provided data objects.
 */
export const extractAssetUrls = (...dataObjects: object[]): AssetUrls => {
  const assetUrls = {
    audio: [],
    images: []
  }

  for (const dataObject of dataObjects) {
    extractAssetUrlsFromObject(assetUrls, dataObject)
  }

  return assetUrls
}
