import { AlcoholRiskLevel } from './alcoholCalculation'

const ONE_DAY_MS = 1000 * 60 * 60 * 24
const ONE_WEEK_MS = 1000 * 60 * 60 * 24 * 7

export type DrinksData = {
  drinkingDate: Date[]
  totalStandardDrinks: number[]
  surveyDate: Date
}
export type RiskLevelData = {
  grog12MonthsSpecialEvent: string
  totalStandardDrinks: number[]
  surveyDate: Date
  gender: 'male' | 'female'
  auditC: {
    grog12Months: number
    typicalNumberDrinks: number
    howOftenSingleDay: number
  }
  grogGiveProblems: boolean
  pregnantHowManyWeeks?: number
  age: number
  grogIsBoss: number
  grogShakes: number
  spendTimeDrinking: number
  drinks: DrinksData
  requestedOccasions?: number
}

export const calcShortTermRisk = (drinkingData: DrinksData): boolean => {
  const maxDrinksPerOccaision = Math.max(...drinkingData.totalStandardDrinks)

  return maxDrinksPerOccaision > 4
}
/**
 * Calculates the number of days between two dates.
 *
 * @param date1 - The later date.
 * @param date2 - The earlier date.
 * @returns The number of days between the two dates.
 */
const calculateDaysBetween = (date1: Date, date2: Date): number => {
  return Math.ceil((date1.getTime() - date2.getTime()) / ONE_DAY_MS)
}

function calculateDuration(
  drinkingData: DrinksData,
  requestedOccasions = 4
): number {
  if (drinkingData.drinkingDate.length < requestedOccasions) {
    // less than requestedOccasions occasions
    const cutoffDate = new Date()
    cutoffDate.setFullYear(cutoffDate.getFullYear() - 1)
    const duration = calculateDaysBetween(drinkingData.surveyDate, cutoffDate)
    return duration
  }
  // more than or equal to requestedOccasions occasions
  const duration = calculateDaysBetween(
    drinkingData.surveyDate,
    drinkingData.drinkingDate[0]
  )

  let totalDurationBetweenOccasions = 0
  for (let i = 1; i < drinkingData.drinkingDate.length; i++) {
    totalDurationBetweenOccasions += calculateDaysBetween(
      drinkingData.drinkingDate[i - 1],
      drinkingData.drinkingDate[i]
    )
  }
  const meanGap =
    totalDurationBetweenOccasions / (drinkingData.drinkingDate.length - 1)
  const adjustedDuration = duration + meanGap * 0.5
  return Math.min(adjustedDuration, 365)
}

function calcLongTermRisk(
  drinkingData: DrinksData,
  requestedOccasions = 4
): boolean {
  const duration = calculateDuration(drinkingData, requestedOccasions)
  const sumSD = drinkingData.totalStandardDrinks.reduce((a, b) => a + b, 0)
  const SDPerOccasion = sumSD / drinkingData.totalStandardDrinks.length
  const occasionsPerDay = drinkingData.drinkingDate.length / duration
  const SDPerDay = SDPerOccasion * occasionsPerDay
  return SDPerDay > 10 / 7
}

function calculateAuditCScore(
  grog12Months: number,
  typicalNumberDrinks: number,
  howOftenSingleDay: number
): number {
  return grog12Months + typicalNumberDrinks + howOftenSingleDay
}
function calculateAuditRisk(
  auditCScore: number,
  gender: 'male' | 'female'
): boolean {
  const femaleAuditCRisk = 3
  const maleAuditCRisk = 4
  if (gender === 'female') return auditCScore >= femaleAuditCRisk

  // if not female then participant is male
  return auditCScore >= maleAuditCRisk
}

function isNonDrinker(grog12MonthsSpecialEvent: string): boolean {
  return grog12MonthsSpecialEvent === 'no'
}

function checkPregnancyRisk(
  pregnantHowManyWeeks: number | undefined,
  drinkingDates: Date[],
  surveyDate: Date
): boolean {
  if (pregnantHowManyWeeks === undefined) {
    return false
  }

  const recentOccasion = new Date(
    Math.max(...drinkingDates.map((date) => date.getTime()))
  )

  const weeksSinceRecentDrink = Math.floor(
    (surveyDate.getTime() - recentOccasion.getTime()) / ONE_WEEK_MS
  )

  return pregnantHowManyWeeks >= weeksSinceRecentDrink
}

function checkUnderageDrinkingRisk(age: number, nonDrinker: boolean): boolean {
  return age < 18 && !nonDrinker
}

function checkDependenceSymptoms(
  grogIsBoss: number,
  grogShakes: number,
  spendTimeDrinking: number
): boolean {
  const dependGrogBoss = grogIsBoss > 3
  const dependGrogShakes = grogShakes > 3
  const dependSpendTime = spendTimeDrinking > 3
  // at least 2 are true
  return (
    [dependGrogBoss, dependGrogShakes, dependSpendTime].filter(Boolean)
      .length >= 2
  )
}

export function determineRiskCategory(
  surveyData: RiskLevelData
): AlcoholRiskLevel {
  const nonDrinker = isNonDrinker(surveyData.grog12MonthsSpecialEvent)
  const shortTermRisk = calcShortTermRisk(surveyData.drinks)
  const longTermRisk = calcLongTermRisk(
    surveyData.drinks,
    surveyData.requestedOccasions
  )
  const auditCScore = calculateAuditCScore(
    surveyData.auditC.grog12Months,
    surveyData.auditC.typicalNumberDrinks,
    surveyData.auditC.howOftenSingleDay
  )
  const auditRisk = calculateAuditRisk(auditCScore, surveyData.gender)
  const alcHarms = surveyData.grogGiveProblems
  const pregnantRisk = checkPregnancyRisk(
    surveyData.pregnantHowManyWeeks,
    surveyData.drinks.drinkingDate,
    surveyData.surveyDate
  )
  const underAgeDrinkingRisk = checkUnderageDrinkingRisk(
    surveyData.age,
    nonDrinker
  )
  const dependent = checkDependenceSymptoms(
    surveyData.grogIsBoss,
    surveyData.grogShakes,
    surveyData.spendTimeDrinking
  )

  // FINAL VALUE
  if (
    !shortTermRisk &&
    !longTermRisk &&
    !auditRisk &&
    !alcHarms &&
    !pregnantRisk &&
    !underAgeDrinkingRisk &&
    !dependent
  ) {
    if (nonDrinker) {
      return 'not drinking'
    } else {
      return 'low risk'
    }
  } else if (dependent) {
    // IMPLICITYLY at least: shortTermRisk, longTermRisk, auditRisk, alcHarms, pregnantRisk, underAgeDrinkingRisk
    return 'high risk'
  } else {
    return 'risky'
  }
}
