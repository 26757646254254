import { SurveyState } from 'store/type'

/**
 * Enum to track the state of fetching the survey manifest from Umbraco in the
 * `LoginScreen` component.
 */
export enum FetchingManifestState {
  NOT_STARTED,
  STARTED,
  FINISHED
}

/**
 * Enum to track the state of narrowing down projects by RA in the `LoginScreen`
 * component.
 */
export enum NarrowingProjectsState {
  NOT_STARTED,
  STARTED,
  FINISHED
}

export type LoginScreenComponentProps = Partial<SurveyState>
