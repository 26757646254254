import React from 'react'
import { AlertPropsModel } from './Alert.model'
import MuiAlert from '@mui/material/Alert'
import styles from './Alert.module.scss'
import { Icon } from 'components/Icons/Icon.component'
import classnames from 'classnames'
import { colors } from 'shared/theme/theme'
import { IconType } from 'components/Icons/Icon.model'
import { Border } from 'components/Border/Border.component'
import { BorderType } from 'components/Border/Border.model'

export const Alert: React.FC<AlertPropsModel> = (props) => {
  const {
    className,
    text,
    icon,
    fullWidth,
    alertProps,
    backgroundColor,
    variation
  } = props

  return (
    <MuiAlert
      {...alertProps}
      variant="filled"
      className={classnames(className, styles.alert, 'u-border-style-sketch', {
        [styles['alert-inline']]: !fullWidth,
        [styles['alert-popup']]: variation === 'popup'
      })}
      icon={
        <Icon
          icon={icon || IconType.Information}
          size={20}
          fill={colors.white}
          border={true}
        />
      }
      sx={{
        backgroundColor: 'transparent',
        ...(variation === 'popup' && {
          position: 'absolute',
          left: props.xOffset,
          top: props.yOffset
        })
      }}
    >
      <Border
        type={BorderType.Button1}
        fill={backgroundColor ? backgroundColor : colors.contessa}
        dropShadow={true}
      />
      {text}
    </MuiAlert>
  )
}
