import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Divider } from '@mui/material'

// export interface GrogDiaryDataDetail
// GrogDiaryDataDetail.product

function createData(
  product: string,
  numberConsumed: number,
  numberPercentFull: number,
  volumeMl: number,
  alcPercentVol: number,
  alcMl: number,
  standardDrinks: number,
  gramsAlcohol: number
) {
  return {
    product,
    numberConsumed,
    numberPercentFull,
    volumeMl,
    alcPercentVol,
    alcMl,
    standardDrinks,
    gramsAlcohol
  }
}

const dummyRows = [
  createData('Whisky', 1, 100, 750, 40, 300, 1, 0.25),
  createData('Vodka', 2, 50, 500, 35, 175, 1, 0.25),
  createData('Rum', 2, 75, 1000, 45, 450, 1, 0.25),
  createData('Gin', 5, 20, 350, 47, 164.5, 1, 0.25),
  createData('Tequila', 2, 80, 700, 38, 266, 1, 0.25)
]

export default function BasicTable({ rows = dummyRows }) {
  const totalItems = rows.length
  const totalAlcohol = rows.reduce((acc, row) => acc + row.alcMl, 0)

  const alcoholGrams = rows.reduce((acc, row) => acc + row.gramsAlcohol, 0)
  const standardDrinks = rows.reduce((acc, row) => acc + row.standardDrinks, 0)
  return (
    <TableContainer component={Paper} sx={{ width: '100%', height: '100%' }}>
      <TableContainer sx={{ maxHeight: 1000 }}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Product</TableCell>
              <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                Number
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                %full
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                Vol(mL)
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                %Alc/vol
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                Alc(mL)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.product}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.product}
                </TableCell>
                <TableCell align="right">
                  {row.numberConsumed.toFixed(1)}
                </TableCell>
                <TableCell align="right">
                  {row.numberPercentFull.toFixed(0)}
                </TableCell>
                <TableCell align="right">{row.volumeMl.toFixed(2)}</TableCell>
                <TableCell align="right">
                  {row.alcPercentVol.toFixed(1)}
                </TableCell>
                <TableCell align="right">{row.alcMl.toFixed(1)}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={3} />
              <TableCell
                colSpan={3}
                align="center"
                sx={{ background: '#f5f5f5' }}
              ></TableCell>
            </TableRow>
            {/* Total items row */}
            <TableRow sx={{ borderTop: '2px' }}>
              <TableCell colSpan={3} />
              <TableCell colSpan={2} align="left">
                Total Number of Items ({totalItems})
              </TableCell>
              <TableCell>{`${totalAlcohol.toFixed(2)} mL`}</TableCell>
            </TableRow>
            {/* Standard drinks row */}
            <TableRow>
              <TableCell colSpan={3} />
              <TableCell colSpan={2} align="right">
                x 0.789 g/mL =
              </TableCell>
              <TableCell>{`${alcoholGrams.toFixed(2)} g`}</TableCell>
            </TableRow>
            {/* Amount of alcohol drunk row */}
            <TableRow>
              <TableCell colSpan={3} />
              <TableCell colSpan={2} align="right">
                Standard drinks
              </TableCell>
              <TableCell>{standardDrinks.toFixed(2)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </TableContainer>
  )
}
