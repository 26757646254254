import React, { useContext, useEffect, useState } from 'react'
import {
  SectionItemProps,
  SectionOption,
  SectionProgressPropsModel
} from './SectionProgress.model'
import styles from './SectionProgress.module.scss'
import classnames from 'classnames'
import { Border } from 'components/Border/Border.component'
import { BorderType } from 'components/Border/Border.model'
import { colors } from 'shared/theme/theme'
import Paper from '@mui/material/Paper'
import { Icon } from 'components/Icons/Icon.component'
import { IconType } from 'components/Icons/Icon.model'
import { Grid } from '@mui/material'
import { AudioContext } from 'context/AudioContext'
import { useColourPicker } from 'context/ColourContext'

export const SectionProgress: React.FC<SectionProgressPropsModel> = (props) => {
  const {
    className,
    title,
    subtitle,
    options,
    click,
    setOptionScreenId,
    setOptionValue
  } = props
  const { secondaryColour } = useColourPicker()
  const { playAudio } = useContext(AudioContext)
  const [clicked, setClicked] = useState<string>()

  /**
   * Highlighted options cater to the drugs a user consumed to be at the top of the Outro Tips page. (GitHub #211)
   * However, if the user consumed all the types of drugs listed in the survey, the highlighted options will still
   * appear under the title and the subtitle to get better in UX.
   */
  const highlightedOptions = options.filter((o) => o.highlighted)
  const normalOptions = options.filter((o) => !o.highlighted)

  const calcGridColumnEnd = (index: number) => {
    if (options.length % 4 !== 0) {
      const ophanIndex = options.length % 4
      for (let i = 0; i < ophanIndex; i++) {
        if (options.length - i === index + 1) {
          return -(4 * 2 - (4 - ophanIndex + 1) - (index % 4) * 2)
        }
      }
    }
    return 'auto'
  }

  const handleItemClick = (option: SectionOption) => {
    if (click) {
      if (option.id && option.voices) {
        playAudio?.(`${option.id}-option`)
      }
      setClicked(option.value)
      setOptionScreenId(option.screenId)
      setOptionValue?.(option.value)
    }
  }

  useEffect(() => {
    if (click !== true) {
      const value = options.find(
        (option) => !option.disabled && !option.completed
      )
      setOptionScreenId(value?.screenId)
      setOptionValue?.(value?.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function SectionItem({
    option,
    noWrap
  }: SectionItemProps): React.JSX.Element {
    return (
      <Grid
        item
        key={`option-${option.value}`}
        lg={3}
        md={3}
        sm={4}
        xs={6}
        display="flex"
        flexDirection="column"
      >
        <div
          className={classnames(styles['sectionprogress-option'], {
            [styles['sectionprogress-option--completed']]:
              option.completed || option.disabled,
            [styles['sectionprogress-option--dimmed']]:
              click && clicked !== undefined && option.value !== clicked,
            [styles['sectionprogress-option--nobg']]: click && !option.icon,
            [styles['sectionprogress-option--disableClick']]: option.disabled
          })}
          //style={{ gridColumnEnd: calcGridColumnEnd(index) }}
          style={{ gridColumnEnd: 'auto' }}
          onClick={() => handleItemClick(option)}
        >
          <Border
            type={BorderType.Option1}
            fill={option.color || colors.white}
            dropShadow
          />
          <Paper className={styles['sectionprogress-option-card']}>
            {option.completed ? (
              <Icon
                className={styles['sectionprogress-option-tickIcon']}
                icon={IconType.Tick}
                size={32}
                stroke={colors.white}
                fill={secondaryColour}
                border={false}
              />
            ) : (
              <></>
            )}
            <div
              className={
                noWrap
                  ? styles['sectionprogress-option-content-nowrap']
                  : styles['sectionprogress-option-content']
              }
            >
              {option.icon && (
                <div className={styles['sectionprogress-option-icon']}>
                  <img
                    src={option.icon}
                    alt={`Image of ${option.label}`}
                    style={{ width: options.length > 4 ? '60%' : '100%' }}
                  />
                </div>
              )}
              <div className={styles['sectionprogress-option-label']}>
                {option.label}
              </div>
            </div>
          </Paper>
        </div>
      </Grid>
    )
  }

  return (
    <div className={classnames(className, styles.sectionprogress)}>
      {highlightedOptions.length > 0 && normalOptions.length > 0 && (
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ lg: 3, md: 3, sm: 1, xs: 1 }}
          alignItems="stretch"
          direction="row"
          justifyContent="center"
        >
          {highlightedOptions.map((option) => (
            <SectionItem
              option={option}
              noWrap={highlightedOptions.length > 4}
              key={`option-section-${option.value}`}
            />
          ))}
        </Grid>
      )}
      <div className={styles['sectionprogress-titles']}>
        {title && (
          <h1
            className="screen-titles--title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
        {subtitle && (
          <p
            className="screen-titles--subtitle"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        )}
      </div>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ lg: 3, md: 3, sm: 1, xs: 1 }}
        alignItems="stretch"
        direction="row"
        justifyContent="center"
      >
        {highlightedOptions.length > 0 &&
          normalOptions.length === 0 &&
          highlightedOptions.map((option) => (
            <SectionItem
              option={option}
              key={`option-section-${option.value}`}
            />
          ))}
        {normalOptions.map((option) => (
          <SectionItem option={option} key={`option-section-${option.value}`} />
        ))}
      </Grid>
    </div>
  )
}
