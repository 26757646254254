/**
 * The type of media assets that are cached, also corresponding to the name of the asset
 * in the browser's {@link CacheStorage}.
 */
export enum CacheType {
  AUDIO = 'audio',
  IMAGES = 'images'
}

/**
 * The interface for the data that will be sent as part of a `'message'` event to the
 * service worker for when the service worker should skip waiting.
 */
interface SkipWaitingMessageData {
  /**
   * A discriminator type indicating that `'message'` event should cause the service
   * worker to skip waiting.
   */
  readonly type: 'SKIP_WAITING'
}

/**
 * The type for the `data` field of a `'message'` event sent to the service worker.
 */
type ServiceWorkerMessageData = SkipWaitingMessageData

/**
 * A type representing the event sent on a `'message'` event to the service worker,
 * indicating the types for the `data` field of the event.
 */
export type ServiceWorkerMessage = Omit<ExtendableMessageEvent, 'data'> & {
  /** The data sent along with the message. */
  readonly data: ServiceWorkerMessageData
}
