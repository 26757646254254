import type { FollowUpQuestionModel, ShortCodeModel } from 'api/client.model'
import { CircleChoice } from 'components/CircleChoice/CircleChoice.component'
import { FollowUpBody } from 'components/FollowUpBody/FollowUpBody.component'
import { Form } from 'components/Form/Form.component'
import { MultipleChoice } from 'components/MultipleChoice/MultipleChoice.component'
import { Slider } from 'components/Slider/Slider.component'
import type { genericObject } from '../type'
import type { Dispatch, SetStateAction } from 'react'

export enum ComponentType {
  'multipleChoice' = 'multipleChoice',
  slider = 'slider',
  input = 'input',

  // Outro
  symptomsCircle = 'symptomsCircle'
}

export const ModalComponent = {
  'multipleChoice': MultipleChoice,
  slider: Slider,
  input: Form,

  // Outro
  symptomsCircle: CircleChoice,
  followUpBody: FollowUpBody
}

export interface ModalScreenPropsModel {
  screenId?: string
  modalData: FollowUpQuestionModel
  open: boolean
  onModalScreenClose: (hasValue: boolean) => void
  shortcodes?: ShortCodeModel[]
  optionFollowUp?: string
  value?: FollowUpOptionValue
  setValue?: Dispatch<SetStateAction<string[]>>

  /**
   * Controls the modal's closing mechanism:
   * - (default): Displays 'Close'/'Confirm' buttons at the bottom.
   * - info: Enables closing via a top-right cross icon or clicking outside the modal.
   *
   * (More variations have been designed for other pages, to be discussed with the team)
   */
  variation?: 'info'
}

export type FollowUpOptionValue =
  | number
  | string
  | string[]
  | number[]
  | genericObject
  | undefined

export interface FollowUpOptionValues {
  [key: string]: FollowUpOptionValue
}
