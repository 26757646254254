import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { Card } from 'components/Card/Card.component'
import CardContent from '@mui/material/CardContent'
import { Text } from 'components/Text/Text.component'
import styles from './ForgotPinScreen.module.scss'
import { validateEmail } from 'shared/utils/validateValue/validateValue'
import { surveyAPI } from 'api/client'
import { useNavigate } from 'react-router-dom'
import { Alert } from 'components/Alert/Alert.component'
import { colors } from 'shared/theme/theme'

const { handleForgotPinSubmit } = surveyAPI()

export const ForgotPinScreen = () => {
  const [email, setEmail] = useState('')
  const [alertText, setAlertText] = useState<string>()
  const [alertStatus, setAlertStatus] = useState<boolean>(false)
  const navigate = useNavigate()

  const handleSendClick = async () => {
    if (!validateEmail(email)) {
      setAlertText('Please enter a valid email address')
      setAlertStatus(false)
      return false
    }
    const resp = await handleForgotPinSubmit(email)
    if (resp?.success) {
      setAlertText('Your Pin has been reset, please check your email')
      setAlertStatus(true)
      setTimeout(() => {
        navigate(-1)
      }, 1500)
    } else {
      setAlertText(resp.message)
      setAlertStatus(false)
    }
  }

  return (
    <main className={styles['forgotpin-container']}>
      <h1
        style={{
          visibility: 'hidden',
          position: 'absolute',
          pointerEvents: 'none'
        }}
      >
        Forgot Pin
      </h1>
      <div>
        <Card
          title="Forgot your PIN?"
          buttonText="Send"
          onButtonClick={handleSendClick}
        >
          {alertText && (
            <Alert
              className={styles['pinscreen-alert']}
              fullWidth={true}
              text={alertText}
              backgroundColor={alertStatus ? colors.breakerBay : colors.crimson}
            />
          )}
          <Box>
            <CardContent>
              <p className={styles['forgotpin-text']}>
                Please enter your email address to receive a new PIN via SMS and
                email
              </p>
            </CardContent>
            <Text
              label="Enter email"
              textFieldProps={{
                required: true,
                fullWidth: true,
                type: 'email'
              }}
              setValue={(value) => setEmail(value)}
            />
          </Box>
        </Card>
      </div>
    </main>
  )
}
