import React from 'react'
import { ImageContainerProps } from './ImageContainer.model'
import styles from './ImageContainer.module.scss'

const ImageChild = ({
  size = '0%',
  srcImg = '',
  style = {},
  clipPercentage = 100
}) => (
  <div
    style={{
      boxSizing: 'border-box',
      flex: `0 0 ${size}`,
      maxWidth: `${size}`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      ...style
    }}
  >
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
        // Clip the right side by (1 - clipPercentage)%
        clipPath: `inset(0 ${100 - clipPercentage}% 0 0)`
      }}
    >
      <img
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain'
        }}
        src={srcImg}
      />
    </div>
  </div>
)

export const ImageContainer: React.FC<ImageContainerProps> = ({
  count,
  srcImg = '',
  minimumValue = 1
}) => {
  const numChildren = parseFloat(count) || minimumValue
  const maxPerRow = 15
  const sizePercentage = 100 / Math.ceil(Math.min(numChildren, maxPerRow))

  // Calculate the decimal part of numChildren
  const partialImageProportion = numChildren % 1
  const clipPercentage = partialImageProportion * 100

  const containerStyle =
    numChildren < maxPerRow ? { justifyContent: 'center', height: '100%' } : {}

  const childStyle = numChildren < maxPerRow ? { height: '100%' } : {}

  // Calculate the number of full images
  const fullImageCount = Math.floor(numChildren)

  const squares = Array.from({ length: fullImageCount }, (_, i) => (
    <ImageChild
      key={i}
      size={`${sizePercentage}%`}
      srcImg={srcImg}
      style={childStyle}
    />
  ))

  // Add the partially visible last image if required
  if (partialImageProportion > 0) {
    squares.push(
      <ImageChild
        key={fullImageCount}
        size={`${sizePercentage}%`}
        srcImg={srcImg}
        style={childStyle}
        clipPercentage={clipPercentage}
      />
    )
  }

  return (
    <div className={styles.imageContainer} style={{ ...containerStyle }}>
      {squares}
    </div>
  )
}
