import React from 'react'
import { IconPropsModel } from '../../Icon.model'

export const IconBigFrown: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="87"
    height="87"
    viewBox="0 0 87 87"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.95683 55.95C10.8836 74.1555 32.3943 80.8168 32.3943 80.8168C49.87 87.2584 61.0723 82.3711 70.256 74.8238C79.4441 67.2743 84.819 52.6193 84.1469 37.9621C83.477 24.6394 76.5315 15.3135 76.5315 15.3135C76.5315 15.3135 65.1029 -1.33988 39.7879 4.21125C39.5638 4.21125 -7.71226 10.4307 5.95683 55.95Z"
      fill={props.fill || '#C57861'}
      stroke="#222222"
      strokeWidth="5.04542"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M63.1865 58.482C61.7806 56.5405 58.9651 51.6056 55.9064 50.0508C53.5917 48.0699 51.3691 47.6044 46.793 46.6459C42.5974 47.1291 37.7828 48.8071 35.3702 51.0086C31.4894 54.5497 27.9633 60.3067 27.9633 60.3067"
      stroke="#222222"
      strokeWidth="5.04542"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.4665 34.5235C25.9284 36.2303 27.9451 36.8548 27.9451 36.8548C29.5834 37.4587 30.6336 37.0005 31.4946 36.293C32.356 35.5852 32.8599 34.2113 32.7969 32.8372C32.734 31.5882 32.0829 30.7139 32.0829 30.7139C32.0829 30.7139 31.0115 29.1526 28.6382 29.673C28.6172 29.673 24.1851 30.2561 25.4665 34.5235Z"
      fill="#222222"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.9259 34.1105C57.4262 35.675 59.6109 36.2475 59.6109 36.2475C61.3858 36.8011 62.5235 36.3811 63.4562 35.7325C64.3894 35.0837 64.9353 33.8243 64.867 32.5647C64.799 31.4197 64.0936 30.6183 64.0936 30.6183C64.0936 30.6183 62.9329 29.1871 60.3618 29.6642C60.3391 29.6642 55.5376 30.1987 56.9259 34.1105Z"
      fill="#222222"
    />
  </svg>
)
