export const imageFallback = [
  // TODO: remove when Umbraco can serve these pictures
  'https://uat-cms-drug-app-tool.sydney.edu.au/media/opxcznzq/male_1_drink-circle.png',
  'https://uat-cms-drug-app-tool.sydney.edu.au/media/z0udzgai/male_2_drink-circle.png',
  'https://uat-cms-drug-app-tool.sydney.edu.au/media/wopneife/male_3_drink-circle.png',
  'https://uat-cms-drug-app-tool.sydney.edu.au/media/okxjvrfv/male_4_drink-circle.png',
  'https://uat-cms-drug-app-tool.sydney.edu.au/media/r44johyc/male_5_drink-circle.png',
  'https://uat-cms-drug-app-tool.sydney.edu.au/media/tolpmcxt/male_6_drink-circle.png',
  'https://uat-cms-drug-app-tool.sydney.edu.au/media/lvia4cse/male_7_drink-circle.png',
  'https://uat-cms-drug-app-tool.sydney.edu.au/media/tl0fxhx4/male_8_drink-circle.png',
  'https://uat-cms-drug-app-tool.sydney.edu.au/media/ilajbung/male_9_drink-circle.png',
  'https://uat-cms-drug-app-tool.sydney.edu.au/media/h2xdagdw/male_10_drink-circle.png'
]
