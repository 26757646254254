import React, { useEffect, useRef, useState } from 'react'
import type {
  AppDispatch,
  ISurveyMetaData,
  SuccessfulLoginStatus,
  SurveyState
} from 'store/type'
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styles from './LoginScreen.module.scss'
import Box from '@mui/material/Box'
import type { LoginScreenComponentProps } from './LoginScreen.model'
import {
  FetchingManifestState,
  NarrowingProjectsState
} from './LoginScreen.model'
import { Pin } from 'components/Pin/Pin.component'
import { Text } from 'components/Text/Text.component'
import { fetchAllAppDataAPI, loginAPI } from 'store/actionCreators'
import { colors } from 'shared/theme/theme'
import { Alert } from 'components/Alert/Alert.component'
import {
  FIRST_TIME_DEMO_WARNING,
  FIRST_TIME_LOGIN_WARNING,
  OFFLINE_PIN_WARNING,
  OFFLINE_USER_WARNING
} from 'shared/constants/messages/Messages.d'
import {
  updateLastLoginStatus,
  updateProjects,
  updateSurvey,
  updateSurveyMetadata,
  updateUser
} from 'store/reducer'
import type {
  ProjectDataModel,
  ProjectSiteModel,
  ResearchAssistantDataModel
} from 'api/client.model'
import { validateEmail } from 'shared/utils/validateValue/validateValue'
import { Button } from 'components/Button/Button.component'
import classnames from 'classnames'
import { InitialisationScreen } from 'components/InitialisationScreen/InitialisationScreen.component'
import { Checkbox, FormControlLabel } from '@mui/material'
import { useSelector } from 'react-redux'
import { storeSupportsRaModeOfflineSelector } from 'store/utils'
import { assertNonNull } from 'shared/types/guards'

const LoginScreenComponent: React.FC<LoginScreenComponentProps> = (props) => {
  const {
    service,
    demoService,
    survey,
    offline,
    lastLoginStatus,
    loginAttempt
  } = props

  const navigate = useNavigate()
  const dispatch: AppDispatch = useDispatch()

  const shouldCacheAssets = useRef<boolean>(false)

  const [fetchingManifest, setFetchingManifest] =
    useState<FetchingManifestState>(FetchingManifestState.NOT_STARTED)
  const [narrowingProjects, setNarrowingProjects] =
    useState<NarrowingProjectsState>(NarrowingProjectsState.NOT_STARTED)
  const [redownloadCache, setRedownloadCache] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [demoModeLoading, setDemoModeLoading] = useState<boolean>(false)
  const [email, setEmail] = useState<string>()
  const [pin, setPin] = useState<string>()
  const [alertText, setAlertText] = useState<string>()
  const [demoAlertText, setDemoAlertText] = useState<string>()

  // Check if the Redux store has required data for RA logged-in input
  const storeSupportsOfflineRaUse = useSelector(
    storeSupportsRaModeOfflineSelector
  )

  const shouldSkipUmbracoDownloads = () =>
    storeSupportsOfflineRaUse && !redownloadCache

  const handleFormValidation = () => {
    // Lack of either email or pin
    if (!email || !pin || (pin && pin.length !== 4)) {
      setAlertText('Please fill in both email and pin to continue')
      return
    }

    // Email is not in the correct format
    if (!validateEmail(email)) {
      setAlertText('Please fill in a valid email address')
      return
    }

    setLoading(true)

    dispatch(loginAPI({ Username: email, Pin: pin }))

    if (offline) {
      validateLogin()
    }
  }

  /**
   * Check whether the provided Research Assistant data model's email matches the email
   * provided by the user in the "Email" input of the login screen.
   *
   * @param ra - A Research Assistant data model object.
   *
   * @returns `true` if the login screen email input matches the email in the RA data
   * model object (case-insensitive), `false` otherwise.
   */
  const raEmailMatchesProvidedEmail = (ra: ResearchAssistantDataModel) =>
    ra.email.toLowerCase() === email?.toLowerCase()

  const handleOfflineLogin = (): boolean => {
    if (!service) {
      setAlertText(FIRST_TIME_LOGIN_WARNING)
      return false
    }

    let valid = false
    let message = OFFLINE_USER_WARNING
    let userInfo = undefined

    raProjectSearchLoop: for (const project of service.projects) {
      if (project.sites == null) {
        // No sites: continue
        continue
      }

      for (const site of project.sites) {
        const matchedEmailRa = site.researchAssistants.find(
          raEmailMatchesProvidedEmail
        )

        if (matchedEmailRa == null) {
          // RA matching email not found in this site
          continue
        }

        // Matched RA - check PIN
        if (matchedEmailRa.pin === pin) {
          userInfo = matchedEmailRa
          valid = true
        } else {
          message = OFFLINE_PIN_WARNING
        }

        // Break outer loop using labelled break
        break raProjectSearchLoop
      }
    }

    if (!valid) {
      setAlertText(message)
    }

    if (valid && userInfo) {
      // Register the user as logged-in from the offline data
      dispatch(updateLastLoginStatus({ loggedIn: true, serviceId: service.id }))
      dispatch(updateUser(userInfo))
    }

    return valid
  }

  const narrowDownProjects = () => {
    // Service should not be null here
    assertNonNull(service, 'service')

    setNarrowingProjects(NarrowingProjectsState.STARTED)

    const projects: ProjectDataModel[] = []

    for (const project of service.projects) {
      const raSpecificProject = { ...project, sites: [] as ProjectSiteModel[] }

      if (project.sites == null) {
        // No sites for the current project - ignore
        continue
      }

      for (const site of project.sites) {
        const raMatchingCredentials = site.researchAssistants.find(
          (ra) => raEmailMatchesProvidedEmail(ra) && ra.pin === pin
        )

        if (raMatchingCredentials == null) {
          // RA matching credentials not found at this site
          continue
        }

        // Otherwise, RA's credentials matches those provided in the login screen
        raSpecificProject.sites.push(site)
      }

      if (raSpecificProject.sites.length > 0) {
        // Add project as selectable
        projects.push(raSpecificProject)
      }
    }

    dispatch(updateProjects(projects))

    setNarrowingProjects(NarrowingProjectsState.FINISHED)
  }

  const validateLogin = () => {
    if (offline === undefined) {
      return
    }

    let valid = false

    if (lastLoginStatus?.loggedIn) {
      valid = true
    } else {
      if (offline) {
        console.log('App is offline - logging in from locally downloaded data')
        valid = handleOfflineLogin()
      } else {
        setAlertText(lastLoginStatus?.errorMessage)
      }
    }

    if (valid) {
      // The login must be successful
      const successfulLoginStatus = lastLoginStatus as SuccessfulLoginStatus

      // Begin dispatching the survey ONLY IF
      // - they are online AND
      // - it is their first-time login OR
      // - the user explicitly wanted to re-download the Umbraco manifest/assets
      if (!offline && !shouldSkipUmbracoDownloads()) {
        setFetchingManifest(FetchingManifestState.STARTED)

        // Only fetch data from API if app is online
        dispatch(fetchAllAppDataAPI(successfulLoginStatus.serviceId)).then(() =>
          setFetchingManifest(FetchingManifestState.FINISHED)
        )

        // Set the ref for tracking whether to cache assets to `true`
        shouldCacheAssets.current = true
      } else {
        // Manifest has technically "finished" caching
        setFetchingManifest(FetchingManifestState.FINISHED)
      }
    } else {
      // Not logging in, so disable loading
      setLoading(false)
    }
  }

  const handleForgotPin = () => {
    navigate('/ra-dashboard/forgot-pin')
  }

  const onDemoModeClick = () => {
    setDemoModeLoading(true)
    setDemoAlertText(undefined)
    dispatch(loginAPI({ Username: 'demo', Pin: 'demo', demoMode: true }))
  }

  // Demo mode
  useEffect(() => {
    if (demoModeLoading) {
      if (!demoService) {
        setDemoAlertText(FIRST_TIME_DEMO_WARNING)
        setDemoModeLoading(false)
      } else {
        const project = demoService?.projects[0]
        if (project) {
          dispatch(updateSurvey(project.survey))
          const metadata: ISurveyMetaData = {
            surveyId: project.survey?.id,
            serviceId: demoService.id,
            projectId: project.id,
            siteId:
              project.sites && project.sites.length > 0
                ? project.sites[0].id
                : '',
            shopId:
              project.shopId != null && project.shopId !== 0
                ? project.shopId
                : undefined
          }
          dispatch(updateSurveyMetadata(metadata))
        }
        if (survey) {
          navigate('/survey/demo')
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service, survey, offline])

  useEffect(() => {
    if (demoModeLoading) {
      // Don't handle this here - the other `useEffect` is for this
      return
    }

    if (
      service == null ||
      lastLoginStatus == null ||
      !lastLoginStatus.loggedIn
    ) {
      // We need these props to be defined (service has been downloaded, and a login has
      // been attempted), and if a login was attempted, it was valid
      return
    }

    if (fetchingManifest != FetchingManifestState.FINISHED) {
      // Still fetching the manifest, don't do anything yet
      return
    }

    if (narrowingProjects == NarrowingProjectsState.NOT_STARTED) {
      // Fetching the manifest has finished OR it already existed. Begin narrowing down
      // projects.
      setAlertText(undefined)
      narrowDownProjects()

      return
    }

    if (narrowingProjects == NarrowingProjectsState.FINISHED) {
      const navigateRoute = shouldCacheAssets.current
        ? '/ra-dashboard/loading-assets'
        : '/ra-dashboard/select-project'

      navigate(navigateRoute)
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    demoModeLoading,
    narrowDownProjects,
    service,
    lastLoginStatus,
    fetchingManifest,
    narrowingProjects
  ])

  useEffect(() => {
    if (offline !== undefined && !demoModeLoading) {
      validateLogin()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offline, lastLoginStatus, loginAttempt])

  useEffect(() => {
    setAlertText(undefined)
  }, [email, pin])

  return (
    <InitialisationScreen
      mainWrapper={{
        title: 'Login',
        additionalStylingClasses: styles['pinscreen']
      }}
      contentDivClasses={styles['pinscreen-wrapper']}
      card={{
        title: 'Research Assistant Login',
        button: {
          disabledCondition: loading,
          text: loading ? 'Logging in ...' : 'Login',
          onClick: handleFormValidation
        },
        extraAction: {
          title: 'forgot your PIN?',
          onActionClick: handleForgotPin
        },
        additionalStylingClasses: styles['pinscreen-card']
      }}
      {...(alertText && { alert: { text: alertText } })}
      belowCardChildren={
        <div className={styles['pinscreen-demo-button-wrapper']}>
          {demoAlertText && (
            <Alert
              className={styles['pinscreen-alert']}
              fullWidth={true}
              text={demoAlertText}
              backgroundColor={colors.crimson}
            />
          )}
          <Button
            className={classnames(
              'align-center',
              styles['pinscreen-demo-button']
            )}
            style={{
              backgroundColor: colors.capePalliser,
              color: `${colors.white} !important`
            }}
            onClick={onDemoModeClick}
            disabled={demoModeLoading}
          >
            {demoModeLoading ? 'Entering demo mode' : 'Or start demo mode'}
          </Button>
        </div>
      }
    >
      <Box className={styles['pinscreen-info']} component="form">
        <Text
          className={styles['pinscreen-text']}
          label="Enter email"
          textFieldProps={{
            required: true,
            fullWidth: true,
            type: 'email',
            autoFocus: true
          }}
          setValue={(value) => setEmail(value)}
        />
        <Pin
          title="4-digit PIN code"
          setValue={(value) => setPin(value)}
          isPassword={true}
        />

        <FormControlLabel
          className={styles['pinscreen-re-cache-checkbox']}
          control={
            <Checkbox
              checked={redownloadCache}
              disabled={!storeSupportsOfflineRaUse}
              onChange={() => setRedownloadCache((value) => !value)}
              size="large"
            />
          }
          label="Re-cache manifest and assets from Umbraco? (only available after
                 first-time login)"
        />
      </Box>
    </InitialisationScreen>
  )
}

const mapStateToProps = (state: SurveyState) => ({
  service: state.service,
  demoService: state.demoService,
  survey: state.survey,
  offline: state.offline,
  lastLoginStatus: state.lastLoginStatus,
  loginAttempt: state.loginAttempt
})

export const LoginScreen = connect(mapStateToProps)(LoginScreenComponent)
