import { createSelector } from '@reduxjs/toolkit'
import { REDUX_STORE_OFFLINE_FIELDS } from 'shared/constants/redux'
import type { SurveyState } from './type'

/**
 * Array of selector functions, which, altogether, return all fields required to be
 * defined to use the app offline in RA mode.
 */
const RA_MODE_OFFLINE_SELECTORS = REDUX_STORE_OFFLINE_FIELDS.map(
  (field) => (state: SurveyState) => state[field]
)

/**
 * A Redux selector function whether the Redux store in use by the app has all properties
 * required for offline use defined (when logging in as an RA). This selector will use
 * memoisation under the hood by checking whether any of the required-to-be-defined Redux
 * state fields have changed in value (by doing a reference equality check).
 *
 * @param state - The current Redux survey state.
 *
 * @returns `true` if all Redux properties required for offline app use are defined,
 * `false` otherwise.
 */
export const storeSupportsRaModeOfflineSelector = createSelector(
  RA_MODE_OFFLINE_SELECTORS,
  (...args) => args.every((arg) => arg != null)
)
